import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { qrCode } from '@common/api/services/QrCode/QrCode';
import { paymentPlan } from '@common/api/services/PaymentPlan/PaymentPlan';
import { paymentPlanPdf } from '@common/api/services/PaymentPlan/PaymentPlanPdf';

export const debtDetailsAsyncActions = {
  getQr: createAsyncThunk(
    `${Feature.DEBT_DETAILS}/get-qr-code`,
    async (id: number) => {
      try {
        const { data } = await qrCode.fetch(id);

        return `data:image/png;base64,${data.toString()}`;
      } catch (error) {
        console.log(error);
      }
    },
  ),

  getPaymentPlan: createAsyncThunk(
    `${Feature.DEBT_DETAILS}/get-payment-plan`,
    async (id: number) => {
      try {
        const response = await paymentPlan.fetch(id);

        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  ),

  getPaymentPlanPdf: createAsyncThunk(
    `${Feature.DEBT_DETAILS}/get-payment-plan-pdf`,
    async (id: number) => {
      try {
        const { data } = await paymentPlanPdf.fetch(id);

        return data;
      } catch {
        return false;
      }
    },
  ),
};
