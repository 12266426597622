import { Location } from 'history';
import { connect, ConnectedProps } from 'react-redux';

import { IDispatch } from '@common/redux/types/IDispatch';
import { asyncActions } from '@app/PageWrapper/duck/asyncActions';

const mapDispatchToProps = (dispatch: IDispatch) =>
  ({
    onInit: (location: Location) => dispatch(asyncActions.initPage(location)),
  } as const);

export const withPageState = connect(null, mapDispatchToProps);

export type IWithPageState = ConnectedProps<typeof withPageState>;
