import styled from 'styled-components';
import { FC } from 'react';

import { Button } from '@components/ui/Button/Button';
import { IconName } from '@components/ui/Icon/enums/IconName';

interface ISupportWrapperProps {
  close(): void;
  title: string;
}

export const SupportWrapper: FC<ISupportWrapperProps> = ({
  children,
  close,
  title,
}) => (
  <SWrapper>
    <SCloseBtn>
      <Button
        buttonType='clickable-icon'
        icon={{ name: IconName.CLOSE }}
        onClick={() => close && close()}
      />
    </SCloseBtn>
    <STitleWrapper>{title}</STitleWrapper>
    {children}
  </SWrapper>
);

const STitleWrapper = styled.div`
  margin-bottom: 55px;
  ${(p) => p.theme.font.header.h1};
  color: ${(p) => p.theme.colors.text.BODY};
  text-align: center;
`;
const SCloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
const SWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 51px 79px 44px;
  width: 496px;
  background: ${(p) => p.theme.colors.base.WHITE};
  ${(p) => p.theme.shadows.common};
  border-radius: 8px;
`;
