import { FC } from 'react';
import styled, { css } from 'styled-components';

import { IconName } from '@components/ui/Icon/enums/IconName';
import { Icon } from '@components/ui/Icon/Icon';
import { ISelectProps } from '@components/ui/Select/types/ISelectProps';
import { ErrorWrapper } from '@components/ui/Select/components/ErrorWrapper';
import { Label } from '@components/ui/Label/Label';

export const Select: FC<ISelectProps> = ({
  id,
  value,
  label,
  onClick,
  active = false,
  disabled,
  placeholder,
  error,
}) => (
  <SContainer>
    {label && (
      <SLabelWrapper>
        <Label forId={id}>{label}</Label>
      </SLabelWrapper>
    )}
    <SWrapper
      onClick={disabled ? undefined : onClick}
      error={!!error}
      disabled={!!disabled}
    >
      {value ? (
        <SText>{value}</SText>
      ) : (
        <SPlaceholder>{placeholder}</SPlaceholder>
      )}
      <SIconWrapper>
        {error && <ErrorWrapper error={error} id={id} />}
        <SIcon
          turned={active}
          name={IconName.ARROW_DOWN}
          size='sm'
          error={!!error}
        />
      </SIconWrapper>
    </SWrapper>
  </SContainer>
);

const SPlaceholder = styled.div`
  color: #757575;
  font-weight: 500;
`;
const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SLabelWrapper = styled.div`
  margin-bottom: 4px;
`;
const SIcon = styled(Icon)<{ turned: boolean; error: boolean }>`
  color: inherit;
  height: 16px;
  width: 16px;
  transform: rotate(${(p) => (p.turned ? '180deg' : '0')});

  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.base.ERROR};
    `}
`;
const SIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.text.GRAY};
`;
const SText = styled.div`
  ${(p) => p.theme.colors.text.BODY};
  ${(p) => p.theme.font.header.h4}
`;
const SWrapper = styled.div<{ error: boolean; disabled: boolean }>`
  display: flex;
  height: 52px;
  width: 100%;
  padding: 13px 11px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  transition-property: border, color, background;
  transition-duration: 0.3s;
  background-color: ${(p) => p.theme.colors.base.LIGHT};
  ${({ disabled, error, theme }) => {
    if (error) {
      return css`
        border: 1px solid ${theme.colors.base.ERROR};
        color: ${theme.colors.base.ERROR};
      `;
    }
    if (disabled) {
      return css`
        cursor: default;

        ${SIcon} {
          color: ${theme.colors.base.LIGHT_GRAY};
        }
      `;
    }

    return css`
      &:hover ${SIconWrapper} {
        color: ${theme.colors.base.DARK};
      }
    `;
  }}
`;
