import { forwardRef, InputHTMLAttributes, ReactText, useState } from 'react';
import styled, { css } from 'styled-components';

import { Label } from '@components/ui/Label/Label';
import { Right } from '@components/ui/Input/components/Right';
import { Loading } from '@components/ui/Input/components/Loading';

export interface IInputInterface
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  id?: string;
  icon?: any;
  iconRight?: any;
  inputSize?: 'lg' | 'md';
  placeholder?: string;
  error?: boolean | string;
  onChange?(value?: string): void;
  disabled?: boolean;
  variant?: 'text' | 'password' | 'number' | 'date';
  value?: string | number;
  defaultValue?: ReactText;
  label?: string;
  inProgress?: boolean;
}

export const Input = forwardRef<HTMLInputElement, IInputInterface>(
  (
    {
      id,
      icon,
      inputSize = 'md',
      iconRight,
      placeholder,
      error = false,
      onChange,
      disabled = false,
      variant = 'text',
      value,
      defaultValue,
      label,
      inProgress = false,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <>
        {label && (
          <SLabelWrapper>
            <Label forId={id}>{label}</Label>
          </SLabelWrapper>
        )}
        <SInputWrapper>
          <SInput
            id={id}
            ref={ref}
            inputSize={inputSize}
            type={showPassword ? 'text' : variant}
            placeholder={inProgress ? 'Loading' : placeholder}
            value={value}
            onChange={
              onChange &&
              ((e) => {
                onChange(e.target.value);
              })
            }
            disabled={disabled}
            error={!!error}
            defaultValue={defaultValue}
            {...props}
          />
          {inProgress && <Loading />}
          {(error || iconRight || variant === 'password') && (
            <Right
              iconRight={iconRight}
              error={error}
              id={id}
              showPassword={showPassword}
              onShowPassword={
                variant === 'password' && value ? setShowPassword : undefined
              }
            />
          )}
        </SInputWrapper>
      </>
    );
  },
);

const SLabelWrapper = styled.div`
  margin-bottom: 4px;
`;

const SInput = styled.input<{
  error: boolean;
  disabled: boolean;
  inputSize: 'lg' | 'md';
}>`
  transition-property: border, color, background;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  padding: 12px 11px;
  z-index: 10;

  ${({ inputSize }) => {
    if (inputSize === 'lg') {
      return css`
        height: 52px;
      `;
    }

    return css`
      height: 48px;
    `;
  }}

  &[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  &::placeholder {
    transition-property: color;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ theme, error }) => css`
    ${theme.font.body.body};
    transition-duration: ${theme.transition};
    color: ${theme.colors.text.BODY};

    ${error &&
    css`
      border: 1px solid ${theme.colors.base.ERROR};
      color: ${theme.colors.base.ERROR};
    `};

    background: transparent;

    ${!error &&
    css`
      &:hover:not(:disabled):not(:read-only) {
        border: 1px solid ${theme.colors.base.GRAY};
      }

      &:focus:not(:disabled):not(:read-only) {
        border: 1px solid ${theme.colors.base.DARK};
      }
    `}
    &::placeholder {
      transition-duration: ${theme.transition};
    }

    &:disabled {
      color: ${theme.colors.text.MUTED};
    }
  `}
`;

const SInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${(p) => p.theme.colors.base.LIGHT};
  border-radius: 4px;
`;
