import styled from 'styled-components';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/ui/Button/Button';
import { Icon } from '@components/ui/Icon/Icon';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { TPages } from '@common/enums/Page';
import { Footer } from '@components/main/Footer';

interface IProps {
  to: TPages;
}

export const Page404: FC<IProps> = ({ to }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SIcon name={IconName.ALERT} />
      <STitle>{t('error.notFound')}</STitle>
      <Button onClick={() => navigate(`/${to}`)} size='lg'>
        {t('buttons.backToMain')}
      </Button>
      <Footer />
    </SWrapper>
  );
};

const SIcon = styled(Icon)`
  width: 64px;
  height: 64px;
`;
const SWrapper = styled.div`
  height: 100vh;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const STitle = styled.div`
  ${(props) => props.theme.font.header.h1}
`;
