import { createAsyncThunk } from '@reduxjs/toolkit';
import { Location } from 'history';

import { Feature } from '@common/enums/Feature';
import { getCurrentPageName } from '@app/PageWrapper/utils/getCurrentPageName';
import { getResponse } from '@app/PageWrapper/utils/getResponse';
import { initPageFeatures } from '@app/PageWrapper/utils/initPageFeatures';

const sleep = (ms = 500): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const asyncActions = {
  initPage: createAsyncThunk(
    `${Feature.PAGE}/initPage`,
    async (location: Location, thunkAPI) => {
      const pageRouteInfo = getCurrentPageName(location);

      try {
        const response = await getResponse(pageRouteInfo);

        initPageFeatures({
          pageResponseDto: response,
          dispatch: thunkAPI.dispatch,
          pageName: pageRouteInfo.name,
        });
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  ),
};
