import { FC } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@components/ui/Icon/Icon';
import { getInfoStripeParams } from '@components/main/DebtCases/components/InfoStripe/utils/getInfoStripeParams';
import { getInfoStripeIcon } from '@components/main/DebtCases/components/InfoStripe/utils/getInfoStripeIcon';
import { useInfoStripeText } from '@components/main/DebtCases/components/InfoStripe/hooks/useInfoStripeText';
import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';

interface IInfoStripe {
  variant?: CaseStatus;
}

export const InfoStripe: FC<IInfoStripe> = ({ variant = CaseStatus.ALL }) => {
  const stripeText = useInfoStripeText(variant);

  if (stripeText) {
    return (
      <SWrapper variant={variant}>
        <Icon name={getInfoStripeIcon(variant)} size='sm' />
        {stripeText}
      </SWrapper>
    );
  }

  return null;
};

const SWrapper = styled.div<{ variant: CaseStatus }>`
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  ${(p) => p.theme.font.special.label};
  ${(p) => p.theme.shadows.common};

  ${({ theme, variant }) => {
    const params = getInfoStripeParams(variant, theme);

    return css`
      color: ${params.textColor};
      background-color: ${params.backgroundColor};
    `;
  }}
`;
