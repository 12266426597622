import { FC } from 'react';
import styled from 'styled-components';

import { Loader } from '@components/ui/Loader/Loader';
import { TLoaderSize } from '@components/ui/Loader/types/TLoaderSize';

interface IProps {
  size?: TLoaderSize;
}

export const Loading: FC<IProps> = ({ size = 'sm' }) => (
  <SRight>
    <Loader size={size} />
  </SRight>
);

const SRight = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 1px;
  padding-right: 7px;
`;
