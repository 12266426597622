import { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  onClick?(): void;
}

export const DropdownItem: FC<IProps> = ({ children, onClick }) => (
  <SDropdown onClick={onClick}>{children}</SDropdown>
);

const SDropdown = styled.div`
  ${(p) => p.theme.font.body.lead};
  padding: 5%;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.colors.base.DARK_WHITE};
  }
`;
