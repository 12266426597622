export enum Feature {
  DEBTS = 'debts',
  PAGE = 'page',
  DEBT_DETAILS = 'debt-details',
  PROFILE = 'profile',
  SUPPORT = 'support',
  HEADER = 'header',
  CREATE_PAYMENT = 'create-payment',
  LOST_PASSWORD = 'lost-password',
}
