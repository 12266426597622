import { FC } from 'react';
import styled from 'styled-components';

export const Label: FC<{ forId?: string }> = ({ children, forId }) => (
  <SContent htmlFor={forId}>{children}</SContent>
);

const SContent = styled.label`
  ${(p) => p.theme.font.body.body};
  color: ${(p) => p.theme.colors.text.GRAY};
`;
