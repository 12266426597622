import { FC, InputHTMLAttributes, ReactNode, ReactText } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconOn } from '@components/ui/Checkbox/img/on.svg';

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactText | ReactNode;
  checked?: boolean;
  disabled?: boolean;
}

export const Checkbox: FC<ICheckboxProps> = ({
  label,
  disabled = false,
  ...props
}) => (
  <SLabel>
    <SInput
      onClick={(e) => {
        e.stopPropagation();
      }}
      disabled={disabled}
      type='checkbox'
      {...props}
    />
    <SControl>
      <SIconOn />
      {label && <span data-title={label}>{label}</span>}
    </SControl>
  </SLabel>
);

const SInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const SIconOn = styled(IconOn)``;

const SLabel = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
`;

const SControl = styled.div`
  position: relative;
  cursor: pointer;
  outline: none;
  line-height: 0;
  ${(p) => p.theme.font.body.body};
  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.colors.text.BODY};
  user-select: none;

  ${SIconOn} {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    color: ${(p) => p.theme.colors.base.WHITE};
  }

  span {
    margin-left: 7px;

    &::before {
      ${(p) => p.theme.font.body.body};
      display: block;
      content: attr(data-title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &:before {
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid ${(p) => p.theme.colors.base.LIGHT_GRAY};
    border-radius: 2px;
    box-sizing: border-box;
    transition-property: border-color, background-color, box-shadow;
    transition-duration: ${(p) => p.theme.transition};
    flex-shrink: 0;
  }

  ${SInput}:not(:checked) + & svg {
    opacity: 0;
  }

  ${SInput}:disabled + & {
    color: ${(p) => p.theme.colors.text};
    cursor: default;

    ${SIconOn} {
      color: ${(p) => p.theme.colors.base.DISABLED};
    }
  }

  ${SInput}:disabled + &:before {
    background-color: ${(p) => p.theme.colors.base.LIGHT};
    border: 1px solid ${(p) => p.theme.colors.base.GRAY};
  }

  ${SInput}:focus:not(:disabled) + &, ${SInput}:not(:disabled) + &:hover {
    ${(p) => p.theme.font.body.body};
  }

  ${SInput}:not(:disabled) + &:hover:before {
    border-color: ${(p) => p.theme.colors.base.INFO};
  }

  ${SInput}:checked:not(:disabled) + & {
    &:before {
      background-color: ${(p) => p.theme.colors.base.INFO};
      border-color: ${(p) => p.theme.colors.base.INFO};
    }

    &:hover:before {
      background-color: ${(p) => p.theme.colors.base.PRIMARY};
      border-color: ${(p) => p.theme.colors.base.PRIMARY};
    }
  }

  ${SInput}:focus + &:before {
    ${(p) => p.theme.font.body.body};
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.base.LIGHT};
  }
`;
