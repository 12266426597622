// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, Matches, MinLength } from 'class-validator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Match } from '@components/main/AuthForm/dto/decorator/MatchDecorator';

export class AuthCreateAccount {
  @Matches(/((?=.*\d)).*$/, {
    message: 'errorMessage.oneNumberRequired',
  })
  @Matches(/(?=.*[a-z])/, {
    message: 'errorMessage.oneLowerCaseRequired',
  })
  @Matches(/(?=.*[A-Z])/, {
    message: 'errorMessage.oneUpperCaseRequired',
  })
  @MinLength(6, {
    message: 'errorMessage.passwordMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isDefinedPassword' })
  public password!: string;

  @Match('password', { message: 'errorMessage.passwordNotSame' })
  @IsDefined({ message: 'errorMessage.isDefinedPassword' })
  public passwordConfirmation!: string;
}
