import { FC } from 'react';
import styled from 'styled-components';

import { QnAWrapper } from '@pages/FAQ/components/QnAWrapper';
import {
  IWithFaqPageState,
  withFaqPageState,
} from '@pages/FAQ/hocs/withFaqPageState';
import { Footer } from '@components/main/Footer';

export const FAQComponent: FC<IWithFaqPageState> = ({ onSupport }) => (
  <SWrapper data-testid='faq-page'>
    <STitle> </STitle>
    <QnAWrapper onSupport={() => onSupport('contact-us')} />
    <Footer />
  </SWrapper>
);

const STitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 3%;
  color: ${(p) => p.theme.colors.text.BODY};
  ${(p) => p.theme.font.header.h1}
`;

const SWrapper = styled.div`
  height: calc(100% - 10%);
  overflow-y: scroll;

  @media (max-width: 450px) {
    height: calc(100% - 25%);
  }
`;

export const FAQ = withFaqPageState(FAQComponent);
