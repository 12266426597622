import { FieldValidator } from 'final-form';

/**
 * Объединяет массив валидаторов поля в один валидатор.
 */
export const composeValidators =
  <Value,>(
    ...validators: Array<FieldValidator<Value>>
  ): FieldValidator<Value> =>
  (...params) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const validator of validators) {
      const error: string | undefined = validator(...params);

      if (error) {
        return error;
      }
    }
  };
