import { FC, useState } from 'react';
import { Field } from 'react-final-form';

import { IPropsForField } from '@app/FinalForm/types/TPropsForField';
import { Select } from '@components/ui/Select/Select';
import { DropdownView } from '@app/DropdownView/DropdownView';
import { Dropdown } from '@components/ui/Dropdown/Dropdown';
import { DropdownItem } from '@components/ui/Dropdown/components/DropdownItem';
import { ISelectProps } from '@components/ui/Select/types/ISelectProps';
import { useWithTranslation } from '@app/FinalForm/hooks/useWithTranslation';

type IProps = IPropsForField<ISelectProps> & {
  options?: string[];
};

export const FinalFormSelect: FC<IProps> = ({ name, options, ...props }) => {
  const [errorCustom, setErrorCustom] = useState(null);
  const newError = useWithTranslation(errorCustom);

  return (
    <Field name={name}>
      {({ input, meta: { error, touched, submitError, submitting } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCustom(error);

        return (
          <DropdownView
            isEqualWidth={true}
            w100={true}
            switcher={({ toggle, isOpen }) => (
              <Select
                id={`final-form-${name}`}
                onClick={toggle}
                value={input.value}
                active={isOpen}
                error={touched ? newError || submitError : undefined}
                disabled={submitting}
                {...props}
              />
            )}
          >
            {({ close }) => (
              <Dropdown>
                {options?.map((option) => (
                  <DropdownItem
                    key={option}
                    onClick={() => {
                      input.onChange(option);
                      close();
                    }}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </DropdownView>
        );
      }}
    </Field>
  );
};
