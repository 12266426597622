import { FC } from 'react';
import styled from 'styled-components';

import { Button } from '@components/ui/Button/Button';

interface IProps {
  error?: boolean;
  disabled?: boolean;
  isLoading: boolean;
}

export const SubmitBtn: FC<IProps> = ({
  error,
  disabled,
  isLoading,
  children,
}) => (
  <SButtonWrapper>
    <Button
      w100={true}
      size='lg'
      type='submit'
      color={error ? 'error' : 'primary'}
      disabled={disabled}
      isLoading={isLoading}
    >
      {children}
    </Button>
  </SButtonWrapper>
);

const SButtonWrapper = styled.div`
  margin-bottom: 18px;
  width: 100%;
`;
