import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/ui/Button/Button';

interface IQnAWrapperProps {
  onSupport(): void;
}

export const Footer: FC<IQnAWrapperProps> = ({ onSupport }) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <STitle>{t('support.noAnswer')}</STitle>
      <SSubtitle>{t('support.subtitle.faq')}</SSubtitle>
      <SButtonWrapper>
        <Button size='lg' w100={true} onClick={onSupport}>
          {t('buttons.contactUs')}
        </Button>
      </SButtonWrapper>
    </SWrapper>
  );
};

const SButtonWrapper = styled.div`
  width: 45%;
`;
const SSubtitle = styled.div`
  color: ${(p) => p.theme.colors.text.GRAY};
  ${(p) => p.theme.font.body.body};
  margin-bottom: 21px;
  text-align: center;
`;
const STitle = styled.div`
  width: 100%;
  color: ${(p) => p.theme.colors.text.BODY};
  ${(p) => p.theme.font.header.h3};
  text-align: center;
  margin-bottom: 4px;
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
`;
