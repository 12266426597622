import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/ui/Button/Button';
import { Page } from '@common/enums/Page';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { SubmitBtn } from '@components/main/AuthForm/components/CardVariants/components/SubmitBtn';
import { noop } from '@common/utils/functions/noop';

export interface ILoginActionProps {
  isLoading: boolean;
  onLostPassword?(): void;
  error?: boolean;
  disabled?: boolean;
}

export const Actions: FC<ILoginActionProps> = ({
  error = false,
  disabled = false,
  onLostPassword = noop,
  isLoading,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SubmitBtn error={error} disabled={disabled} isLoading={isLoading}>
        {children}
      </SubmitBtn>
      <SAdditionButtons>
        <Button
          onClick={() => onLostPassword()}
          size='sm'
          buttonType='text'
          underline={true}
        >
          {t('buttons.lostPassword')}
        </Button>
        <LinkComponent size='sm' to={Page.REGISTRATION} underline={true}>
          {t('buttons.creatAcc')}
        </LinkComponent>
      </SAdditionButtons>
    </>
  );
};

const SAdditionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding-top: 12px;
  text-align: center;
`;
