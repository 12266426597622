import { IconName } from '@components/ui/Icon/enums/IconName';
import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';

export const getInfoStripeIcon = (variant: CaseStatus): IconName => {
  switch (variant) {
    case CaseStatus.PTP_ACTIVE:
      return IconName.PROMISE;
    case CaseStatus.PP_ACTIVE:
      return IconName.CALENDAR;
    case CaseStatus.ALL:
    case CaseStatus.REPAID:
      return IconName.CHECKMARK;
    default:
    case CaseStatus.OPEN:
    case CaseStatus.PP_LATE:
    case CaseStatus.PTP_AWAITING:
    case CaseStatus.PTP_PARTLY_REPAID:
    case CaseStatus.CLOSED:
    case CaseStatus.PENDING:
      return IconName.ALERT;
  }
};
