import styled from 'styled-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/ui/Icon/Icon';
import { IconName } from '@components/ui/Icon/enums/IconName';

export const DebtsEmpty: FC = () => {
  const { t } = useTranslation();

  return (
    <SContainer>
      <SInfoWrapper>
        <SIcon name={IconName.PROBLEM_SOLVING} />
        {t('info.successNoDebts')}
      </SInfoWrapper>
    </SContainer>
  );
};

const SIcon = styled(Icon)`
  width: 38px;
  height: 38px;
  margin-bottom: 10px;
`;
const SInfoWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  ${(p) => p.theme.font.header.h3}
`;
const SContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 5%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
