import { FC } from 'react';
import styled from 'styled-components';

import { TDebtCaseHelmetTypes } from '@components/main/DebtCases/DebtCase/types/TDebtCaseHelmetTypes';

interface IHelmetProps {
  symbol: string;
  companyName: string;
  companyLogo: string | null;
  size?: TDebtCaseHelmetTypes;
}

export const Helmet: FC<IHelmetProps> = ({
  symbol,
  companyName,
  companyLogo,
  size = 'md',
}) => (
  <SHelmetWrapper>
    <SInfoWrapper>
      <SLabel>{symbol}</SLabel>
      <SCompanyTitle>{companyName}</SCompanyTitle>
    </SInfoWrapper>
    <SCompanyLogo>
      {companyLogo ? (
        <SImg src={companyLogo} alt='img' size={size} />
      ) : (
        companyName
      )}
    </SCompanyLogo>
  </SHelmetWrapper>
);

const SImg = styled.img<{ size: string }>`
  width: ${(p) => (p.size === 'md' ? '7rem' : '10rem')};

  @media (max-width: 350px) {
    width: 100%;
  }
`;
const SLabel = styled.div`
  ${(p) => p.theme.font.body.small};
  color: ${(p) => p.theme.colors.text.GRAY};
  margin-bottom: 2px;
`;
const SInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    order: 2;
  }
`;

const SCompanyLogo = styled.div`
  min-height: 31px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.font.header.h3};

  @media (max-width: 768px) {
    order: 1;
  }
`;
const SCompanyTitle = styled.div`
  word-break: break-word;
  ${(p) => p.theme.font.header.h3}
`;
const SHelmetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    row-gap: 30px;
  }

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
