import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TabComponent } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/components/TabComponent';
import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';

interface IProps {
  activeSection: AnchorTabs;
  showHistory: boolean;
  hasPaySchedule: boolean;
}

export const TabAnchors: FC<IProps> = ({
  activeSection,
  showHistory,
  hasPaySchedule,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <TabComponent
        id={AnchorTabs.OVERVIEW}
        active={activeSection === AnchorTabs.OVERVIEW}
      >
        {t('details.tabs.overview')}
      </TabComponent>
      {hasPaySchedule && (
        <TabComponent
          id={AnchorTabs.PAY_SCHEDULE}
          active={activeSection === AnchorTabs.PAY_SCHEDULE}
        >
          {t('details.tabs.paySchedule')}
        </TabComponent>
      )}
      <TabComponent
        id={AnchorTabs.DEBT_INFO}
        active={activeSection === AnchorTabs.DEBT_INFO}
      >
        {t('details.tabs.debtInfo')}
      </TabComponent>
      {showHistory && (
        <TabComponent
          id={AnchorTabs.PAY_HISTORY}
          active={activeSection === AnchorTabs.PAY_HISTORY}
        >
          {t('details.tabs.payHistory')}
        </TabComponent>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(p) => p.theme.colors.base.LIGHT};
  justify-content: start;
`;
