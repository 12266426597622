import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/ui/Icon/Icon';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { Button } from '@components/ui/Button/Button';
import {
  IWithContactUsWidgetState,
  withSupportWidgetState,
} from '@components/main/SupportWidget/hocs/withSupportWidgetState';

interface IContactUsWidgetProps {
  background?: boolean;
}

type TContactUsWidget = IContactUsWidgetProps & IWithContactUsWidgetState;

export const ContactUsWidgetComponent: FC<TContactUsWidget> = ({
  onOpen,
  background = false,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper background={background}>
      <SIconWrapper>
        <SIconHelp />
      </SIconWrapper>
      <STitle>{t('support.title')}</STitle>
      <SSubtitle>{t('supportWidget.text')}</SSubtitle>
      <SButtonWrapper>
        <SButton
          data-testid='support-contact-us-button'
          buttonType='text'
          size='sm'
          underline={true}
          onClick={() => onOpen && onOpen('contact-us')}
        >
          {t('buttons.contactUs')}
        </SButton>
        {t('support.or')}
        <SButton
          data-testid='support-call-us-button'
          buttonType='text'
          size='sm'
          underline={true}
          onClick={() => onOpen && onOpen('call-back')}
        >
          {t('buttons.callBack')}
        </SButton>
      </SButtonWrapper>
    </SWrapper>
  );
};

const SButton = styled(Button)`
  & span {
    font-weight: 600;
    color: #979aa7;
  }
`;
const SButtonWrapper = styled.div``;
const SSubtitle = styled.div`
  ${(p) => p.theme.font.body.small};
  margin-bottom: 27px;
`;
const STitle = styled.div`
  margin-bottom: 8px;
  ${(p) => p.theme.font.header.h5};
`;
const SIconHelp = styled(Icon).attrs({
  name: IconName.HELP,
  size: 'lg',
})`
  color: ${(p) => p.theme.colors.text.GRAY};
`;
const SIconWrapper = styled.div`
  margin-bottom: 16px;
`;
const SWrapper = styled.div<{ background: boolean }>`
  display: flex;
  padding: 20px 16px;
  z-index: 10;
  width: 30%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: ${(p) => p.theme.colors.text.GRAY};

  box-sizing: content-box;

  ${({ background }) =>
    background &&
    css`
      height: 300px;
      width: 255px;
      background-color: ${(p) => p.theme.colors.base.WHITE};
      justify-content: center;
      border-radius: 6px;
    `}
`;

export const SupportWidget = withSupportWidgetState(ContactUsWidgetComponent);
