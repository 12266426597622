import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

import { AuthCreateAccount } from '@components/main/AuthForm/dto/AuthCreateAccount';

export const Match =
  (property: string, validationOptions?: ValidationOptions) =>
  (object: AuthCreateAccount, propertyName: string): void => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [property],
      validator: MatchConstraint,
    });
  };

@ValidatorConstraint({ name: 'Match' })
export class MatchConstraint implements ValidatorConstraintInterface {
  validate(value: string, args: ValidationArguments): boolean {
    const [relatedPropertyName] = args.constraints;
    const relatedValue = (args.object as AuthCreateAccount)[
      relatedPropertyName as string
    ];

    return value === relatedValue;
  }
}
