import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { lostPassword } from '@common/api/services/LostPassword/LostPassword';
import { ILostPasswordVerifyDto } from '@common/api/dto/features/LostPassword/request/ILostPasswordVerifyDto';
import { ILostPasswordChangePasswordDto } from '@common/api/dto/features/LostPassword/request/ILostPasswordChangePasswordDto';
import { ILostPasswordRequestDto } from '@common/api/dto/features/LostPassword/request/ILostPasswordRequestDto';

export const lostPasswordAsyncActions = {
  requestCode: createAsyncThunk(
    `${Feature.LOST_PASSWORD}/request_code`,
    async (dto: ILostPasswordRequestDto, { rejectWithValue }) => {
      try {
        await lostPassword.request(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),

  verifyCode: createAsyncThunk(
    `${Feature.SUPPORT}/verify_code`,
    async (dto: ILostPasswordVerifyDto, { rejectWithValue }) => {
      try {
        await lostPassword.verify(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),

  changePassword: createAsyncThunk(
    `${Feature.SUPPORT}/change_password`,
    async (dto: ILostPasswordChangePasswordDto, { rejectWithValue }) => {
      try {
        await lostPassword.change(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
};
