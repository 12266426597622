import { DefaultTheme } from 'styled-components';

interface IButtonParams {
  color: string;
  hoverColor?: string;
  font: string;
}

export const getClickableIconParamsByColor = (
  size: string,
  disabled: boolean | undefined,
  theme: DefaultTheme,
): IButtonParams => {
  const { colors, font } = theme;

  if (disabled) {
    return {
      color: colors.text.MUTED,
      font: font.special.regular,
    };
  }
  switch (size) {
    default:
    case 'md':
      return {
        color: colors.text.GRAY,
        hoverColor: colors.text.MUTED,
        font: font.special.regular,
      };
    case 'sm':
      return {
        color: colors.text.GRAY,
        hoverColor: colors.text.MUTED,
        font: font.special.small,
      };
  }
};
