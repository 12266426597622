import { FC } from 'react';
import {
  createGlobalStyle,
  ThemeProvider as BaseThemeProvider,
} from 'styled-components';
import { Normalize } from 'styled-normalize';

import { CssFontFamily } from '@common/styled/CssFontFamily';
import { CssFontFaces } from '@common/styled/CssFontFaces';
import { BaseColor } from '@common/enums/ui/BaseColor';
import { TextColor } from '@common/enums/ui/TextColor';

const defaultTheme = {
  font: {
    header: {
      h1: 'font: 500 34px "Exo", sans-serif; line-height: 45.9px',
      h2: 'font: 500 28px "Exo", sans-serif; line-height: 35px',
      h3: 'font: 500 22px "Exo", sans-serif; line-height: 29.7px',
      h4: 'font: 500 18px "Exo", sans-serif; line-height: 24.3px',
      h5: 'font: 500 16px "Exo", sans-serif; line-height: 24px',
      h6: 'font: 500 14px "Exo", sans-serif; line-height: 16.25px',
    },
    body: {
      lead: 'font: 400 18px "Exo", sans-serif; line-height: 24.3px',
      body: 'font: 500 16px "Exo", sans-serif; line-height: 24px',
      small: 'font: 500 14px "Exo", sans-serif; line-height: 23.1px',
    },
    special: {
      label: 'font: 500 14px "Exo", sans-serif; line-height: 21px',
      small: 'font: 500 14px "Exo", sans-serif; line-height: 17.5px',
      regular: 'font: 500 14px "Exo", sans-serif; line-height: 18.9px',
      large: 'font: 500 16px "Exo", sans-serif; line-height: 20px',
    },
  },
  transition: '20ms',
  colors: {
    base: BaseColor,
    text: TextColor,
  },
  shadows: {
    common: 'box-shadow: 0px 16px 15px -13px rgba(0, 41, 121, 0.08)',
  },
};

export type ITheme = typeof defaultTheme;

const GlobalStyle = createGlobalStyle`
  ${CssFontFaces}
  
  * {
    box-sizing: border-box;
  }
  html {
    ${CssFontFamily};
    height: 100%;
  }
  body {
    height: 100%
  }
  #root {
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    -webkit-box-shadow: none;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }
`;

export const ThemeProvider: FC = ({ children }) => (
  <BaseThemeProvider theme={defaultTheme}>
    <GlobalStyle />
    <Normalize />
    {children}
  </BaseThemeProvider>
);
