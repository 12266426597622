import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { IDebtCase } from '@components/main/DebtCases/types/IDebtCase';
import { IDebtCaseResponseDto } from '@common/api/dto/features/DebtsCase/response/IDebtCaseResponse';

interface IState {
  debtItems: IDebtCase[] | null;
}

export const debtsSlice = createSlice({
  name: Feature.DEBTS,
  initialState: {
    debtItems: null,
  } as IState,
  reducers: {
    setState: (draft, { payload }: PayloadAction<IDebtCaseResponseDto[]>) => {
      draft.debtItems =
        payload.map((item) => ({
          caseStatus: item.case_status,
          caseData: {
            id: item.case_data.id,
            externalId: item.case_data.external_id,
            dueDate: item.case_data.due_date,
            totalDebt: item.case_data.total_debt,
            payment: item.case_data.payment,
          },
          creditor: {
            name: item.creditor.business_partner_name,
            logoUrl: item.creditor.logo_url,
          },
          ppIsAllowed: item.pp_is_allowed,
        })) || null;
    },
  },
});
