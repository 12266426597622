import { FC, useState } from 'react';
import { Field } from 'react-final-form';

import { IPropsForField } from '@app/FinalForm/types/TPropsForField';
import { IInputInterface } from '@components/ui/Input/Input';
import { Textarea } from '@components/ui/Textarea/Textarea';
import { useWithTranslation } from '@app/FinalForm/hooks/useWithTranslation';

export const FinalFormTextArea: FC<IPropsForField<IInputInterface>> = ({
  name,
  ...props
}) => {
  const [errorCustom, setErrorCustom] = useState(null);
  const newError = useWithTranslation(errorCustom);

  return (
    <Field name={name}>
      {({ input, meta: { error, touched, submitError } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCustom(error);

        return (
          <Textarea
            {...props}
            {...input}
            error={touched ? newError || submitError : undefined}
          />
        );
      }}
    </Field>
  );
};
