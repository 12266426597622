import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStandardError } from '@common/api/types/IStandardError';
import { Feature } from '@common/enums/Feature';
import { IUserInfo } from '@components/main/Support/types/IUserInfo';
import { supportAsyncActions } from '@components/main/Support/duck/supportAsyncActions';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { IUserInfoResponseDto } from '@common/api/dto/features/UserInfo/IUserInfoResponseDto';
import { ICallBackResponseDto } from '@common/api/dto/features/CallBack/response/ICallBackResponseDto';

interface IState {
  isLoading: boolean;
  userInfo: IUserInfo;
  serverError: string | null;
  datesForCall: undefined | ICallBackResponseDto[];
}

export const supportSlice = createSlice({
  name: Feature.SUPPORT,
  initialState: {
    isLoading: false,
    userInfo: {},
    serverError: null,
    datesForCall: undefined,
  } as IState,
  reducers: {
    restoreError: (draft) => {
      draft.serverError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(supportAsyncActions.prefill.pending.type, (draft) => {
      draft.isLoading = true;
    });
    builder.addCase(supportAsyncActions.prefill.rejected.type, (draft) => {
      draft.isLoading = false;
    });
    builder.addCase(
      supportAsyncActions.prefill.fulfilled.type,
      (draft, action: PayloadAction<IUserInfoResponseDto>) => {
        draft.isLoading = false;

        draft.userInfo = {
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          email: action.payload.email,
        };
      },
    );

    builder.addCase(
      supportAsyncActions.getDatesForCall.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      supportAsyncActions.getDatesForCall.rejected.type,
      (draft) => {
        draft.isLoading = false;
      },
    );
    builder.addCase(
      supportAsyncActions.getDatesForCall.fulfilled.type,
      (draft, action: PayloadAction<ICallBackResponseDto[]>) => {
        draft.isLoading = false;

        draft.datesForCall = action.payload;
      },
    );

    builder.addCase(supportAsyncActions.submitSupport.pending.type, (draft) => {
      draft.isLoading = true;
    });
    builder.addCase(
      supportAsyncActions.submitSupport.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? JSON.stringify(payload.response.data.detail)
          : 'Something went wrong, please try again';
      },
    );
    builder.addCase(
      supportAsyncActions.submitSupport.fulfilled.type,
      (draft) => {
        draft.isLoading = false;
      },
    );

    builder.addCase(
      supportAsyncActions.submitCallSupport.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      supportAsyncActions.submitCallSupport.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? JSON.stringify(payload.response.data.detail)
          : 'Something went wrong, please try again';
      },
    );
    builder.addCase(
      supportAsyncActions.submitCallSupport.fulfilled.type,
      (draft) => {
        draft.isLoading = false;
      },
    );
  },
});
