import { AnyObject } from 'immer/dist/types/types-internal';

import { TPageRouteInfo } from '@app/PageWrapper/types/TPageRouteInfo';
import { apiPage } from '@common/api/services/Page/ApiPage';

export const getResponse = async (
  pageRouteInfo: TPageRouteInfo | null,
): Promise<AnyObject> => {
  if (!pageRouteInfo) {
    throw new Error('Undefined Route');
  }

  const response = await apiPage.init(pageRouteInfo);

  return response.data;
};
