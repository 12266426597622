import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Header } from '@components/main/Header/Header';
import {
  IWithPageState,
  withPageState,
} from '@app/CurrentPage/hocs/withPageState';

const CurrentPageComponent: FC<IWithPageState> = ({ onInit }) => {
  const location = useLocation();

  useEffect(() => {
    void onInit(location);
  }, [onInit, location]);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export const CurrentPage = withPageState(CurrentPageComponent);
