import { FC, useState } from 'react';
import styled from 'styled-components';

import { ErrorPageType } from '@common/api/enums/ErrorPageType';

type TVariant = 'success' | 'error';

interface IProps {
  type?: TVariant;
}

interface IError {
  loc: string[];
  msg: string;
}

interface IErrorInfo {
  fields: null | string[];
  messages: null | string;
}

export const ActionStatus: FC<IProps> = ({ children, type = 'error' }) => {
  const [errorInfo] = useState<IErrorInfo>({
    fields: null,
    messages: null,
  });

  if (Array.isArray(children)) {
    children.map((el: IError) => {
      errorInfo.fields = el.loc;
      errorInfo.messages = el.msg;
    });

    return (
      <SWrapper type={type} column={true}>
        <div>Errors in following fields:</div>
        <div>
          {errorInfo.fields?.map((el, idx) => (
            <ul key={idx}>
              <li>{el}</li>
            </ul>
          ))}
        </div>
        <div>{errorInfo.messages}</div>
      </SWrapper>
    );
  }

  if (children === ErrorPageType.ACCESS_DENIED) {
    return <SWrapper type={type}>Access Denied</SWrapper>;
  }
  if (typeof children === 'string' && children.includes('[')) {
    return <SWrapper type={type}>{children.slice(2, -2)}</SWrapper>;
  }

  return <SWrapper type={type}>{children}</SWrapper>;
};

const SWrapper = styled.div<{ type: TVariant; column?: boolean }>`
  ${(p) => p.theme.font.body.body};
  color: ${(p) =>
    p.type === 'error'
      ? p.theme.colors.base.ERROR
      : p.theme.colors.base.SUCCESS};
  background-color: ${(p) =>
    p.type === 'error'
      ? p.theme.colors.base.ERROR
      : p.theme.colors.base.SUCCESS}10;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: ${(p) => (p.column ? 'column' : 'row')};
  align-items: center;
  padding: 10px 0;
  width: 100%;
  justify-content: center;
`;
