import { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CreatePay } from '@pages/CreatePayment/components/CreatePay/CreatePay';
import { Logo } from '@components/ui/Logo/Logo';
import { Button } from '@components/ui/Button/Button';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { Page, StatefulPage } from '@common/enums/Page';
import { getPayVariantFromUrl } from '@pages/CreatePayment/utils/getPayVariantFromUrl';
import { PromisePay } from '@pages/CreatePayment/components/PromisePay/PromisePay';
import { InstantPay } from '@pages/CreatePayment/components/InstantPay/InstantPay';
import {
  IWithCreatePaymentState,
  withCreatePaymentState,
} from '@pages/CreatePayment/hocs/withCreatePaymentState';
import { useRestoreError } from '@pages/Auth/hooks/useRestoreError';
import { Footer } from '@components/main/Footer';

export const PaymentComponent: FC<IWithCreatePaymentState> = ({
  createPromisePay,
  createPaymentPlan,
  isLoading,
  restoreError,
  serverError,
  submitSuccess,
  createPlanOptions,
  promiseOptions,
}) => {
  const location = useLocation();

  const { t } = useTranslation();

  const { id, payVariant } = getPayVariantFromUrl(location);

  useRestoreError(restoreError);

  return (
    <SWrapper>
      <SLinkWrapper>
        <LinkComponent size='lg' to={`/${StatefulPage.DEBTS}`}>
          <Logo />
        </LinkComponent>
      </SLinkWrapper>
      {payVariant === Page.CREATE_PAY && (
        <CreatePay
          debtId={id}
          serverError={serverError}
          submitSuccess={submitSuccess}
          isLoading={isLoading}
          options={createPlanOptions}
          onSubmit={createPaymentPlan}
        />
      )}
      {payVariant === Page.PROMISE_PAY && (
        <PromisePay
          debtId={id}
          serverError={serverError}
          submitSuccess={submitSuccess}
          isLoading={isLoading}
          onSubmit={createPromisePay}
          promiseOptions={promiseOptions}
        />
      )}
      {payVariant === Page.INSTANT_PAY && <InstantPay debtId={id} />}
      <LinkComponent to={`/${StatefulPage.DEBTS}`}>
        <SButton buttonType='text' underline={true}>
          {t('button.cancelBack')}
        </SButton>
      </LinkComponent>
      <Footer />
    </SWrapper>
  );
};

const SButton = styled(Button)`
  & span {
    mix-blend-mode: difference;
  }
`;
const SLinkWrapper = styled.div`
  padding: 15px 0;
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  align-items: center;
  height: calc(100% - 80px);
  overflow-y: scroll;
`;

export const Payment = withCreatePaymentState(PaymentComponent);
