// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, Matches, MaxLength, MinLength } from 'class-validator';

export class SupportForm {
  @MinLength(2, {
    message: 'errorMessage.nameMaxLength{{$constraint1}}',
  })
  @Matches(/[A-Za-z]/, {
    message: 'errorMessage.isInvalidName',
  })
  @IsDefined({ message: 'errorMessage.isDefinedName' })
  public firstName!: string;

  @MinLength(2, {
    message: 'errorMessage.lastNameMinLength{{$constraint1}}',
  })
  @Matches(/[A-Za-z]/, {
    message: 'errorMessage.isInvalidLastName',
  })
  @IsDefined({ message: 'errorMessage.isDefinedLastName' })
  public lastName!: string;

  @MinLength(6, {
    message: 'errorMessage.numberMinLength{{$constraint1}}s',
  })
  @MaxLength(24, {
    message: 'errorMessage.numberMaxLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isNumberDefined' })
  public phone!: string;
}
