import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IUserInfoResponseDto } from '@common/api/dto/features/UserInfo/IUserInfoResponseDto';

class UserInfo extends ApiServiceBase {
  public constructor() {
    super();
  }

  public fetch(): Promise<ApiResponse<IUserInfoResponseDto>> {
    return this.get('/api/v1/users/contact_us');
  }
}

export const userInfo = new UserInfo();
