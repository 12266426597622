// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, MinLength } from 'class-validator';

export class ResetPassword {
  @MinLength(3, {
    message: 'errorMessage.emailMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isDefined' })
  public login!: string;
}
