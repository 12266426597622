import { FC } from 'react';

import { Tab } from '@components/ui/Tab/Tab';
import { useScrollIntoView } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/hooks/useScrollHeight';

interface IProps {
  id?: string;
  active: boolean;
}

export const TabComponent: FC<IProps> = ({ id, active, children }) => {
  const { onToElementView } = useScrollIntoView(id);

  return (
    <Tab active={active} onClick={onToElementView} shallow={true}>
      {children}
    </Tab>
  );
};
