import { ActionCreator, AnyAction } from '@reduxjs/toolkit';

import { StatefulPage } from '@common/enums/Page';
import { debtDetailsSlice } from '@pages/DebtDetails/duck/slice';
import { profileSlice } from '@pages/Profile/duck/slice';
import { debtsSlice } from '@pages/DebtCases/duck/slice';
import { createPaymentSlice } from '@pages/CreatePayment/duck/slice';

interface IFeatureInfo {
  name: StatefulPage;
  setState: ActionCreator<AnyAction>;
}

type IPageFeatures = Record<StatefulPage, IFeatureInfo[]>;

export const pageFeature: IPageFeatures = {
  [StatefulPage.DEBTS]: [
    {
      name: StatefulPage.DEBTS,
      setState: debtsSlice.actions.setState,
    },
  ],
  [StatefulPage.DEBT_DETAILS]: [
    {
      name: StatefulPage.DEBT_DETAILS,
      setState: debtDetailsSlice.actions.setState,
    },
  ],
  [StatefulPage.PROFILE]: [
    {
      name: StatefulPage.PROFILE,
      setState: profileSlice.actions.setState,
    },
  ],
  [StatefulPage.CREATE_PAYMENT]: [
    {
      name: StatefulPage.CREATE_PAYMENT,
      setState: createPaymentSlice.actions.setCreatePayState,
    },
  ],
  [StatefulPage.CREATE_PAYMENT_PROMISE]: [
    {
      name: StatefulPage.CREATE_PAYMENT_PROMISE,
      setState: createPaymentSlice.actions.setPromisePayState,
    },
  ],
};
