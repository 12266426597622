import { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  title: string;
  subtitle: string;
}

export const Header: FC<IProps> = ({ title, subtitle }) => (
  <SWrapper>
    <STitle>{title}</STitle>
    <SSubtitle>{subtitle}</SSubtitle>
  </SWrapper>
);

const SSubtitle = styled.div`
  ${(p) => p.theme.font.header.h5};
  font-weight: 400;
`;
const STitle = styled.div`
  ${(p) => p.theme.font.header.h2};
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;
`;
