import { DefaultTheme } from 'styled-components';

import { TLinkSize } from '@components/ui/LinkComponent/types/TLinkSize';

interface IButtonParam {
  font: string;
}

export const getLinkParamsBySize = (
  size: TLinkSize,
  { font }: DefaultTheme,
): IButtonParam => {
  switch (size) {
    default:
    case 'lg':
      return {
        font: font.special.large,
      };
    case 'md':
      return {
        font: font.special.regular,
      };
    case 'sm':
      return {
        font: font.special.label,
      };
  }
};
