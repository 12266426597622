import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ICheckAuthResponseDto } from '@common/api/dto/features/AuthUser/response/ICheckAuthResponseDto';
import { ApiResponse } from '@common/api/ApiResponse';

class CheckAuth extends ApiServiceBase {
  public constructor() {
    super();
  }

  public check(): Promise<ApiResponse<ICheckAuthResponseDto>> {
    return this.get('/api/v1/users/me');
  }
}

export const checkAuth = new CheckAuth();
