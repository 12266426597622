import { Dispatch, FC, ReactText } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from '@components/ui/Tooltip/Tooltip';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { Icon } from '@components/ui/Icon/Icon';
import { Label } from '@components/ui/Label/Label';

export interface ITextAreaProps {
  id?: string;
  placeholder?: string;
  value?: ReactText;
  onChange?: Dispatch<string>;
  error?: string | boolean;
  label?: string;
  disabled?: boolean;
}

export const Textarea: FC<ITextAreaProps> = ({
  id,
  value,
  onChange,
  placeholder = 'Type here',
  error,
  label,
  disabled = false,
  ...props
}) => {
  const errorTooltipId = id ? `error-${id}` : undefined;

  return (
    <>
      {label && (
        <SLabelWrapper>
          <Label forId={id}>{label}</Label>
        </SLabelWrapper>
      )}
      <STextAreaWrapper>
        <STextArea
          value={value}
          onChange={onChange && ((e) => onChange(e.target.value))}
          placeholder={placeholder}
          error={!!error}
          disabled={disabled}
          {...props}
        />
        {error && (
          <SWrapper data-tip={true} data-for={errorTooltipId}>
            <SIcon name={IconName.ALERT} size='sm' />
            {typeof error === 'string' && errorTooltipId && (
              <Tooltip id={errorTooltipId}>{error}</Tooltip>
            )}
          </SWrapper>
        )}
      </STextAreaWrapper>
    </>
  );
};

const SLabelWrapper = styled.div`
  margin-bottom: 4px;
`;
const SWrapper = styled.div``;
const SIcon = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${(p) => p.theme.colors.base.ERROR};
`;
const STextAreaWrapper = styled.div`
  position: relative;
  height: 100%;
`;
const STextArea = styled.textarea<{
  error: boolean;
  disabled: boolean;
}>(
  ({ theme, error }) => css`
    ${theme.font.body.body};
    transition-property: border, color, background;
    overflow: overlay;
    border: 1px solid transparent;
    resize: vertical;
    max-height: 300px;
    width: 100%;
    min-height: 48px;
    box-sizing: border-box;
    background: ${theme.colors.base.LIGHT};
    border-radius: 4px;
    padding: 5px 8px;
    height: 100%;
    outline: none;

    &::placeholder {
      transition-property: color;
    }

    transition-duration: ${theme.transition};

    color: ${theme.colors.text.BODY};

    ${error &&
    css`
      border: 1px solid ${theme.colors.base.ERROR};
    `}

    ${!error &&
    css`
      &:hover:not(:disabled):not(:read-only) {
        border: 1px solid ${theme.colors.base.GRAY};
      }
      &:focus:not(:disabled):not(:read-only) {
        border: 1px solid ${theme.colors.base.DARK};
      }
    `}
    &::placeholder {
      transition-duration: ${theme.transition};
    }
    &:disabled {
      color: ${theme.colors.text.MUTED};
    }

    ${error &&
    css`
      border-color: ${theme.colors.base.ERROR};
    `}
  `,
);
