// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, Matches, MinLength } from 'class-validator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Match } from '@pages/Profile/dto/decorator/MathDecorator';

export class ChangePasswordDto {
  @MinLength(6, {
    message: 'errorMessage.passwordMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isDefinedPassword' })
  public passwordCurrent!: string;

  @Matches(/((?=.*\d)|(?=.*\W+))(?![\n.])(?=.*[A-Z])(?=.*[a-z]).*$/, {
    message: 'errorMessage.isWeakPassword',
  })
  @MinLength(6, {
    message: 'errorMessage.passwordMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isDefinedPassword' })
  public passwordNew!: string;

  @Match('passwordNew', { message: 'errorMessage.passwordNotSame' })
  @IsDefined({ message: 'errorMessage.isDefinedPassword' })
  public passwordNewAgain!: string;
}
