// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined } from 'class-validator';

export class PromisePayDto {
  @IsDefined({
    message: 'errorMessage.isDefinedAmount',
  })
  public amount!: string;

  @IsDefined({
    message: 'errorMessage.firstDueDate',
  })
  public date!: Date;
}
