import { useEffect, useState } from 'react';

interface IResult {
  onToElementView(): void;
}

export const useScrollIntoView = (elementId: string | undefined): IResult => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    if (!elementId) {
      return;
    }
    const div = document.querySelector(`#${elementId}`);

    setElement(div);
  }, [elementId]);

  return {
    onToElementView: (): void => {
      if (!element) {
        return;
      }

      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    },
  };
};
