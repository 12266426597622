import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { Input } from '@components/ui/Input/Input';
import { useVerifyCode } from '@components/main/AuthForm/hooks/useVerifyCode';
import { Button } from '@components/ui/Button/Button';
import { ICodeValidationRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeValidationRequestDto';
import { noop } from '@common/utils/functions/noop';

export interface ICodeValidationProps {
  isLoading: boolean;
  onValidateCode?(value: ICodeValidationRequestDto): void;
}

interface IProps extends ICodeValidationProps {
  onResendCode?(): void;
  serverError?: null | string;
}

export const CodeValidation: FC<IProps> = ({
  isLoading,
  onValidateCode = noop,
  serverError,
  onResendCode,
}) => {
  const { inputs, otp, nextInputIndex, ref, onChange, onKeyPress } =
    useVerifyCode({ onSubmit: onValidateCode });

  const code = otp.join('');

  useEffect(() => {
    if (code.length === 4) {
      onValidateCode(String(code));
    }
  }, [code]);

  const { t } = useTranslation();

  return (
    <SWrapper>
      <SOtpWrapper>
        {inputs.map((inp, idx) => (
          <SInput
            key={idx}
            maxLength={4}
            value={otp[idx]}
            onKeyDown={(event) => onKeyPress(event, idx)}
            onChange={(value) => onChange(value, idx)}
            ref={nextInputIndex === idx ? ref : null}
            disabled={isLoading}
          />
        ))}
      </SOtpWrapper>
      <SElementWrapper>
        {serverError && <ActionStatus>{serverError}</ActionStatus>}
      </SElementWrapper>
      <SActionsWrapper>
        <Button
          onClick={() => onValidateCode(String(code))}
          w100={true}
          size='lg'
          disabled={code.length < 4 || isLoading}
          isLoading={isLoading}
        >
          {t('buttons.verifyCode')}
        </Button>
        <Button
          onClick={onResendCode}
          w100={true}
          buttonType='text'
          underline={true}
          disabled={isLoading}
        >
          {t('buttons.sendCodeAgain')}
        </Button>
      </SActionsWrapper>
    </SWrapper>
  );
};

const SElementWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
const SInput = styled(Input)`
  width: 46px;
  height: 48px;
  font-size: 34px;
  text-align: center;
`;
const SActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const SOtpWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: center;
  margin-bottom: 35px;
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
