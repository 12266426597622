import { Component, ReactNode } from 'react';

import { ErrorComponent } from '@app/ErrorBoundry/components/ErrorComponent';
import { ErrorPage } from '@pages/ErrorPage/ErrorPage';
import { apiAxiosInstance } from '@common/api/ApiServiceBase';
import { ApiPageError } from '@common/api/ApiPageError';
import { ErrorPageType } from '@common/api/enums/ErrorPageType';

type IState = {
  errorFromState: boolean;
  apiError?: boolean;
  pageError: ErrorPageType | undefined;
};

interface IProps {
  children: ReactNode;
}

export class ErrorBoundary extends Component<IProps, IState> {
  private responseInterceptor!: number;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      errorFromState: false,
      apiError: false,
      pageError: undefined,
    };
  }

  public static getDerivedStateFromError(): IState {
    console.log('state');

    return {
      errorFromState: true,
      apiError: false,
      pageError: undefined,
    };
  }

  public static componentDidCatch(): void {
    console.log('catch');
  }

  public componentDidMount(): void {
    this.responseInterceptor = apiAxiosInstance.interceptors.response.use(
      (response) => response,
      (error: ApiPageError | unknown) => {
        this.setState({
          pageError: undefined,
        });

        if (error instanceof ApiPageError) {
          this.setState({
            pageError: error.errorPageType,
          });

          throw error;
        }

        throw error;
      },
    );
  }

  public componentWillUnmount(): void {
    apiAxiosInstance.interceptors.response.eject(this.responseInterceptor);
  }

  public componentDidUpdate(): void {
    apiAxiosInstance.interceptors.response.eject(this.responseInterceptor);
  }

  public render(): ReactNode {
    const { children } = this.props;

    if (this.state.pageError === ErrorPageType.NOT_FOUND) {
      return (
        <ErrorComponent message='No answer from server'>
          {children}
        </ErrorComponent>
      );
    }
    // if (this.state.pageError === ErrorPageType.ACCESS_DENIED) {
    //   return (
    //     <ErrorComponent message='Access denied or other Network issue has occurred'>
    //       {children}
    //     </ErrorComponent>
    //   );
    // }
    // if (this.state.pageError === ErrorPageType.ERROR) {
    //   return (
    //     <ErrorComponent message='Something went wrong, we already working on it'>
    //       {children}
    //     </ErrorComponent>
    //   );
    // }
    if (this.state.errorFromState) {
      return (
        <ErrorComponent message='Something went wrong, we already working on it, try to reload the page'>
          <ErrorPage />
        </ErrorComponent>
      );
    }

    return children;
  }
}
