import { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { HandleThunkActionCreator } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import moment from 'moment';

import { useForm } from '@app/FinalForm/hooks/useForm';
import { SelectorWrapper } from '@pages/CreatePayment/components/SelectorWrapper';
import { PromisePayDto } from '@pages/CreatePayment/components/PromisePay/components/Selector/dto/PromisePayDto';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { FinalFormDatePicker } from '@app/FinalForm/components/FinalFormDatePicker';
import { Button } from '@components/ui/Button/Button';
import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { paymentAsyncActions } from '@pages/CreatePayment/duck/paymentAsyncActions';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { StatefulPage } from '@common/enums/Page';
import { IPromisePayOptions } from '@pages/CreatePayment/types/IPromisePayOptions';
import { Loader } from '@components/ui/Loader/Loader';

export interface IPromiseProps {
  debtId: string;
  serverError: null | string;
  submitSuccess: boolean;
  isLoading: boolean;
  onSubmit: HandleThunkActionCreator<
    typeof paymentAsyncActions.createPromisePay
  >;
  promiseOptions: IPromisePayOptions | null;
}

export const Selector: FC<IPromiseProps> = ({
  debtId,
  serverError,
  submitSuccess,
  isLoading,
  onSubmit,
  promiseOptions,
}) => {
  const { fields, validate } = useForm(PromisePayDto);
  const { t } = useTranslation();

  const history = useNavigate();

  useEffect(() => {
    const goBack = debounce(() => history(-1), 3_000);

    if (submitSuccess) {
      goBack();
    }

    return () => {
      goBack.cancel();
    };
  }, [submitSuccess, history]);

  if (!promiseOptions) {
    console.log('Promise Options were not loaded');

    return (
      <SLoaderWrapper>
        <Loader />
      </SLoaderWrapper>
    );
  }

  return (
    <Form<PromisePayDto>
      onSubmit={(values) => {
        void onSubmit({
          case_id: Number(debtId),
          amount: Number(values.amount),
          due_date: moment(values.date).format('YYYY-MM-DD'),
        });
      }}
      validate={validate}
      render={({ handleSubmit, submitting, submitFailed, touched }) => (
        <SForm onSubmit={handleSubmit}>
          <SelectorWrapper
            title={t('form.putAmount')}
            additionalInfo={{
              text: t('form.totalDebtAmount'),
              value: `${promiseOptions?.currentDebt} Kč`,
            }}
          >
            <FinalFormInput
              id='amount'
              name={fields.amount}
              type='number'
              placeholder={t('form.minAmountToPay', {
                value: `${promiseOptions?.minAmount} Kč`,
              })}
              disabled={isLoading}
            />
          </SelectorWrapper>
          <SelectorWrapper
            title={t('form.firstDueDate')}
            additionalInfo={{
              text: t('form.noLaterThan'),
              value: `${promiseOptions?.availableDates
                .slice(-1)[0]
                .split('-')
                .reverse()
                .join('.')}`,
            }}
          >
            <FinalFormDatePicker
              id='date-picker'
              disabled={isLoading}
              name={fields.date}
              maxDate={new Date(promiseOptions?.availableDates.slice(-1)[0])}
              availableDates={promiseOptions?.availableDates}
              placeholderText={t('input.selectDate')}
            />
          </SelectorWrapper>
          {(serverError || submitSuccess) && (
            <SElementWrapper>
              {serverError && <ActionStatus>{serverError}</ActionStatus>}
              {submitSuccess && (
                <ActionStatus type='success'>
                  {t('actionStatus.promiseSuccess')}
                </ActionStatus>
              )}
            </SElementWrapper>
          )}
          {submitSuccess && !serverError ? (
            <LinkComponent to={`/${StatefulPage.DEBTS}`}>
              <Button w100={true} size='lg'>
                {t('buttons.backToMain')}
              </Button>
            </LinkComponent>
          ) : (
            <Button
              w100={true}
              size='lg'
              type='submit'
              color={submitFailed ? 'error' : 'primary'}
              disabled={submitting || !touched || isLoading}
              isLoading={isLoading}
            >
              {t('buttons.createPromise')}
            </Button>
          )}
        </SForm>
      )}
    />
  );
};

const SLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 0;
  margin: 0;
  width: 100%;
`;
