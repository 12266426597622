import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { ILostPasswordVerifyDto } from '@common/api/dto/features/LostPassword/request/ILostPasswordVerifyDto';
import { ILostPasswordChangePasswordDto } from '@common/api/dto/features/LostPassword/request/ILostPasswordChangePasswordDto';
import { ILostPasswordRequestDto } from '@common/api/dto/features/LostPassword/request/ILostPasswordRequestDto';

class LostPassword extends ApiServiceBase {
  public constructor() {
    super();
  }

  public request(
    dto: ILostPasswordRequestDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/lost_pass_request', dto);
  }

  public verify(
    dto: ILostPasswordVerifyDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/lost_pass_verify', dto);
  }

  public change(
    dto: ILostPasswordChangePasswordDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/lost_pass_change_password', dto);
  }
}

export const lostPassword = new LostPassword();
