import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import {
  debtDetailsPaymentPlanSelector,
  isDebtDetailsItemSelector,
  isDebtDetailsLoadingSelector,
  isServerErrorSelector,
} from '@pages/DebtDetails/duck/selectors';
import { IDispatch } from '@common/redux/types/IDispatch';
import { debtDetailsAsyncActions } from '@pages/DebtDetails/duck/debtDetailsAsyncActions';

const mapDispatchToProps = (dispatch: IDispatch) =>
  ({
    getQrCode: (debtId: number) =>
      dispatch(debtDetailsAsyncActions.getQr(debtId)),
    getPaymentPlan: (debtId: number) =>
      dispatch(debtDetailsAsyncActions.getPaymentPlan(debtId)),
    getPaymentPlanPdf: (debtId: number) =>
      dispatch(debtDetailsAsyncActions.getPaymentPlanPdf(debtId)),
  } as const);

export const withDebtDetailsState = connect(
  (state: IRootState) => ({
    serverError: isServerErrorSelector(state),
    isLoading: isDebtDetailsLoadingSelector(state),
    debtItem: isDebtDetailsItemSelector(state),
    paymentPlan: debtDetailsPaymentPlanSelector(state),
  }),
  mapDispatchToProps,
);

export type IWithDebtDetailsState = ConnectedProps<typeof withDebtDetailsState>;
