import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { SupportWidget } from '@components/main/SupportWidget/SupportWidget';
import {
  IWithDebtsFeedState,
  withDebtsFeedState,
} from '@pages/DebtCases/hocs/withDebtsFeedState';
import { DebtsEmpty } from '@pages/DebtCases/components/DebtsEmpty/DebtsEmpty';
import { LoaderSkeleton } from '@components/main/LoaderSkeleton/LoaderSkeleton';
import { DebtCaseWrapper } from '@pages/DebtCases/components/DebtCaseWrapper';
import { Footer } from '@components/main/Footer';

export const DebtsFeedComponent: FC<IWithDebtsFeedState> = ({
  debtItems,
  isLoading,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const [headerHeight, setHeaderHeight] = useState(10);

  useEffect(() => {
    if (!ref) {
      return;
    }
    if (!ref.current) {
      return;
    }

    setHeaderHeight(ref.current.clientHeight);
  }, [ref]);

  return (
    <SWrapper height={headerHeight} data-testid='debts-page'>
      <STitleWrapper ref={ref}>
        <SPageTitle>{t('breadcrumbs.myDebts')}</SPageTitle>
      </STitleWrapper>
      <SContentWrapper>
        <SSupportWrapperMax>
          <SupportWidget background={true} />
        </SSupportWrapperMax>
        {isLoading ? (
          <LoaderSkeleton />
        ) : (
          <SFeedWrapper>
            {!debtItems || debtItems.length === 0 ? (
              <DebtsEmpty />
            ) : (
              debtItems.map((item) => (
                <DebtCaseWrapper key={item.caseData.id} debt={item} />
              ))
            )}
            <SSupportWrapperMin>
              <SupportWidget background={true} />
              <Footer />
            </SSupportWrapperMin>
          </SFeedWrapper>
        )}
      </SContentWrapper>
      <STerms>
        <Footer />
      </STerms>
    </SWrapper>
  );
};

export const DebtsFeed = withDebtsFeedState(DebtsFeedComponent);

// const SInnerWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   max-height: 100vh;
// `;

const STerms = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 150px);

  @media (max-width: 1001px) {
    display: none;
  }
`;
const SPageTitle = styled.div`
  ${(p) => p.theme.font.header.h1};
  color: ${(p) => p.theme.colors.text.BODY};
  text-transform: uppercase;
`;
const STitleWrapper = styled.div`
  display: flex;
  padding: 48px 5% 0;
  position: relative;
  box-shadow: 0 20px 20px 9px ${(p) => p.theme.colors.base.LIGHT};
  z-index: 399;
`;
const SSupportWrapperMin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 29px;
  padding-top: 35px;
  width: 100%;
  @media (min-width: 1001px) {
    display: none;
  }
`;
const SSupportWrapperMax = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 29px;
  padding-top: 35px;
  padding-left: 5%;

  @media (max-width: 1000px) {
    padding-left: 0;
    display: none;
  }
`;
const SContentWrapper = styled.div`
  display: flex;
  row-gap: 20px;
  column-gap: 7%;
  justify-content: space-around;
  height: 105%;

  @media (max-width: 500px) {
    height: 80vh;
  }
`;
const SFeedWrapper = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  width: 100%;
  height: 100%;
  padding: 35px 3% 10%;
  overflow-y: scroll;
  min-width: 320px;

  transition: 500ms, all;
`;
const SWrapper = styled.div<{ height: number }>`
  height: calc(100% - ${(p) => `${p.height + 88}px`});

  @media (max-width: 500px) {
    height: calc(100% - ${(p) => `${p.height + 88 + 70}px`});
  }
`;
