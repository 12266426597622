import { FC, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface IProps {
  message: string;
}

export const ErrorComponent: FC<IProps> = ({ children, message }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6_000}
        onClose={() => setOpen(false)}
      >
        <MuiAlert
          onClose={() => setOpen(false)}
          severity='warning'
          elevation={6}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
      {children}
    </>
  );
};
