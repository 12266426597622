import { FC } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { ILoginRequestDto } from '@common/api/dto/features/AuthUser/request/ILoginRequestDto';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import {
  Actions,
  ILoginActionProps,
} from '@components/main/AuthForm/components/CardVariants/Login/components/Actions';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { AuthLogin } from '@components/main/AuthForm/dto/AuthLogin';
import { noop } from '@common/utils/functions/noop';

export interface ILoginProps extends ILoginActionProps {
  onLogin?(value: ILoginRequestDto): void;
  serverError?: null | string;
}

const CLIENT_ZONE_URL = 'https://clientzone.app/';

export const Login: FC<ILoginProps> = ({
  onLogin = noop,
  isLoading,
  serverError,
  onLostPassword,
}) => {
  const { validate, fields } = useForm(AuthLogin);
  const { t } = useTranslation();

  return (
    <Form<AuthLogin> onSubmit={onLogin} validate={validate}>
      {({ handleSubmit, submitting, touched, submitFailed }) => (
        <SForm onSubmit={handleSubmit}>
          <SElementWrapper>
            <FinalFormInput
              id='login'
              label={t('form.login')}
              name={fields.login}
              disabled={isLoading}
            />
          </SElementWrapper>
          <SElementWrapper>
            <FinalFormInput
              id='password'
              label={t('form.password')}
              name={fields.password}
              disabled={isLoading}
              variant='password'
            />
          </SElementWrapper>
          <SElementWrapper>
            {serverError && <ActionStatus>{serverError}</ActionStatus>}
          </SElementWrapper>
          <Actions
            onLostPassword={onLostPassword}
            error={submitFailed || !!serverError}
            disabled={!touched || submitting || isLoading}
            isLoading={isLoading}
          >
            {t('buttons.login')}
          </Actions>
          <SLinkWrapper>
            <SLink href={CLIENT_ZONE_URL} target='_blank'>
              {t('ClientZone.link')}
            </SLink>
          </SLinkWrapper>
        </SForm>
      )}
    </Form>
  );
};

const SLinkWrapper = styled.div`
  margin-top: 25px;
  text-align: center;
`;
const SLink = styled.a`
  color: ${(p) => p.theme.colors.text.GRAY};
  ${(p) => p.theme.font.header.h6};
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-position: from-font;
`;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
const SElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  margin-bottom: 16px;
`;
