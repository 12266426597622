import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const LoaderSkeleton: FC = () => (
  <SWrapper>
    {Array.from({ length: 5 })
      .fill(0)
      .map((_, idx) => (
        <Skeleton key={idx} height='40vh' width='70ws' />
      ))}
  </SWrapper>
);

const SWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 5%;
`;
