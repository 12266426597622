import { FC } from 'react';
import styled from 'styled-components';

export const BodyWrapper: FC = ({ children }) => (
  <SWrapper>{children}</SWrapper>
);

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 32px 15%;
  border-top: 1px solid ${(p) => p.theme.colors.base.DARK}16;
  border-bottom: 1px solid ${(p) => p.theme.colors.base.DARK}16;
`;
