import { FC } from 'react';
import styled from 'styled-components';

interface IAdditionalInfo {
  text: string;
  value: string;
}

interface IProps {
  title?: string;
  additionalInfo?: IAdditionalInfo;
}

export const SelectorWrapper: FC<IProps> = ({
  title,
  additionalInfo,
  children,
}) => (
  <SWrapper>
    {title && <STitle>{title}</STitle>}
    {children}
    {additionalInfo && (
      <SAdditionalInfoWrapper>
        {additionalInfo.text}: <SValue>{additionalInfo.value}</SValue>
      </SAdditionalInfoWrapper>
    )}
  </SWrapper>
);

const SValue = styled.span`
  ${(p) => p.theme.font.body.body};
  font-weight: 600;
`;
const SAdditionalInfoWrapper = styled.div`
  color: ${(p) => p.theme.colors.text.GRAY};
  text-align: right;
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
`;
const STitle = styled.div`
  color: ${(p) => p.theme.colors.text.GRAY};
`;
