import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TSupportVariant } from '@components/main/Support/types/TSupportVariant';
import { asyncActions } from '@app/PageWrapper/duck/asyncActions';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { IStandardError } from '@common/api/types/IStandardError';

interface ICommonSliceState {
  openedSupport: TSupportVariant;
  loading: boolean;
  serverError: string | null;
}

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    openedSupport: null,
    loading: false,
    serverError: null,
  } as ICommonSliceState,
  reducers: {
    openContactUs: (
      draft,
      { payload }: PayloadAction<Exclude<TSupportVariant, ''>>,
    ) => {
      draft.openedSupport = payload;
    },
    closeContactUs: (draft) => {
      draft.openedSupport = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.initPage.pending.type, (draft) => {
      draft.loading = true;
    });
    builder.addCase(asyncActions.initPage.fulfilled.type, (draft) => {
      draft.loading = false;
    });
    builder.addCase(
      asyncActions.initPage.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.loading = false;

        draft.serverError =
          payload.response?.data.detail || 'Something went wrong';
      },
    );
  },
});
