import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';

class PaymentPlanPdf extends ApiServiceBase {
  public constructor() {
    super();
  }

  public fetch(id: number): Promise<ApiResponse<IBasePayload>> {
    return this.get(`/api/v1/cases/payment_plan/letter/${id}`, {
      responseType: 'blob',
    });
  }
}

export const paymentPlanPdf = new PaymentPlanPdf();
