import { FC } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@components/ui/Button/Button';
import { Separator } from '@components/main/AuthForm/components/Separator';
import { IconName } from '@components/ui/Icon/enums/IconName';

interface IBaseFormCardProps {
  title: string;
  caption?: string;
  stepBack?(): void;
  boxSizing?: 'content-box' | 'border-box';
}

export const BaseFormCard: FC<IBaseFormCardProps> = ({
  title,
  caption,
  stepBack,
  children,
  boxSizing,
}) => (
  <SWrapper boxSizing={boxSizing}>
    {stepBack && (
      <SButtonWrapper>
        <Button
          w100={true}
          icon={{ name: IconName.ARROW_LEFT }}
          buttonType='clickable-icon'
          size='lg'
          onClick={() => stepBack()}
        />
      </SButtonWrapper>
    )}
    <STitleWrapper>{title}</STitleWrapper>
    {caption && <SCaptionWrapper>{caption}</SCaptionWrapper>}
    <SSeparatorWrapper>
      <Separator />
    </SSeparatorWrapper>
    {children}
  </SWrapper>
);

const SButtonWrapper = styled.div`
  position: absolute;
  top: 17px;
  left: 17px;
`;
const SSeparatorWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SCaptionWrapper = styled.div`
  margin-bottom: 30px;
  ${(p) => p.theme.font.body.body};
  color: ${(p) => p.theme.colors.text.GRAY};
  text-align: center;
`;
const STitleWrapper = styled.div`
  margin-bottom: 12px;
  ${(p) => p.theme.font.header.h1};
  color: ${(p) => p.theme.colors.text.BODY};
  text-align: center;
`;
const SWrapper = styled.div<{ boxSizing?: 'content-box' | 'border-box' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 15%;
  width: 100%;
  max-width: 496px;
  overflow-y: overlay;
  background: ${(p) => p.theme.colors.base.WHITE};
  ${(p) => p.theme.shadows.common};
  border-radius: 8px;

  ${({ boxSizing }) =>
    boxSizing &&
    css`
      box-sizing: ${boxSizing};
    `}
`;
