import { Dispatch, FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HandleThunkActionCreator } from 'react-redux';

import { BaseFormCard } from '@app/BaseFormCard/BaseFormCard';
import { ResetPassword } from '@components/main/LostPassword/dto/ResetPassword';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { Button } from '@components/ui/Button/Button';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { lostPasswordAsyncActions } from '@components/main/LostPassword/duck/lostPasswordAsyncActions';
import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';

interface IProps {
  isLoading: boolean;
  serverError: string | null;
  onSetLogin: Dispatch<string>;
  onSubmit: HandleThunkActionCreator<
    typeof lostPasswordAsyncActions.requestCode
  >;
}

export const RestoreContact: FC<IProps> = ({
  isLoading,
  serverError,
  onSetLogin,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { validate, fields } = useForm(ResetPassword);

  return (
    <BaseFormCard title={t('lostPassword.typeEmail')}>
      <Form<ResetPassword>
        onSubmit={async (values, form) => {
          await onSubmit(values.login);

          onSetLogin(values.login);

          form.restart();
        }}
        validate={validate}
        render={({ handleSubmit, submitting, touched, submitFailed }) => (
          <SForm onSubmit={handleSubmit}>
            <SInputWrapper>
              <FinalFormInput
                id='login'
                label={t('lostPassword.labelInput')}
                name={fields.login}
                type='text'
                placeholder={t('placeholder.typeHere')}
                disabled={isLoading}
              />
            </SInputWrapper>
            <SInputWrapper>
              {serverError && <ActionStatus>{serverError}</ActionStatus>}
            </SInputWrapper>
            <SButtonWrapper>
              <Button
                w100={true}
                size='lg'
                type='submit'
                color={submitFailed ? 'error' : 'primary'}
                disabled={!touched || submitting || isLoading}
              >
                {t('buttons.recoverPass')}
              </Button>
            </SButtonWrapper>
          </SForm>
        )}
      />
    </BaseFormCard>
  );
};

const SButtonWrapper = styled.div`
  width: 100%;
`;
const SInputWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
