import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { ICodeValidationResponseDto } from '@common/api/dto/features/AuthUser/response/ICodeValidationResponseDto';
import { ICodeValidationRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeValidationRequestDto';

class ValidateCode extends ApiServiceBase {
  public constructor() {
    super();
  }

  public validate(
    dto: ICodeValidationRequestDto,
  ): Promise<ApiResponse<ICodeValidationResponseDto>> {
    return this.post('/api/v1/users/code_verification', dto);
  }
}

export const validateCode = new ValidateCode();
