import { FC, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Favicon from 'react-favicon';

import { AppWrapper } from '@app/AppWrapper/AppWrapper';
import { LostPassword } from '@components/main/LostPassword/LostPassword';
import { Support } from '@components/main/Support/Support';
import { store } from '@common/redux/store';
import { PageWrapper } from '@app/PageWrapper/PageWrapper';
import 'react-loading-skeleton/dist/skeleton.css';
import { ErrorBoundary } from '@app/ErrorBoundry/ErrorBoundary';
import { Loader } from '@components/ui/Loader/Loader';
// eslint-disable-next-line no-relative-imports/no-relative-imports
import favicon from '../../../public/favicon.png';

export const App: FC = () => (
  <Router>
    <Provider store={store}>
      <Suspense
        fallback={
          <div
            style={{
              height: '100vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader />
          </div>
        }
      >
        <AppWrapper>
          <ErrorBoundary>
            <Favicon url={favicon} />
            <LostPassword />
            <Support />
            <PageWrapper />
          </ErrorBoundary>
        </AppWrapper>
      </Suspense>
    </Provider>
  </Router>
);
