import { FC } from 'react';

import { DebtCase } from '@components/main/DebtCases/DebtCase/DebtCase';
import { IDebtCase } from '@components/main/DebtCases/types/IDebtCase';

interface IProps {
  debt: IDebtCase;
}

export const DebtCaseWrapper: FC<IProps> = ({ debt }) => (
  <DebtCase
    debtItem={debt}
    variant={debt.caseStatus}
    isPlanAllowed={debt.ppIsAllowed}
  />
);
