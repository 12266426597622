import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SPayWrapper } from '@pages/CreatePayment/components/CreatePay/CreatePay';
import { Header } from '@pages/CreatePayment/components/Header';
import { BodyWrapper } from '@pages/CreatePayment/components/BodyWrapper';
import { Selector } from '@pages/CreatePayment/components/InstantPay/components/Selector';

interface IProps {
  debtId: string;
}

export const InstantPay: FC<IProps> = ({ debtId }) => {
  const { t } = useTranslation();

  return (
    <SPayWrapper>
      <Header
        title={t('createPayment.instantPay.title')}
        subtitle={t('createPayment.instantPay.text')}
      />
      <BodyWrapper>
        <Selector debtId={debtId} />
      </BodyWrapper>
      {/*TODO: Uncomment when card payments supported */}
      {/*<Footer />*/}
    </SPayWrapper>
  );
};
