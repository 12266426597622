// export type TDebtPaymentState =
//   | 'promise'
//   | 'promise-late'
//   | 'promise-success'
//   | 'create-plan'
//   | 'create-plan-late'
//   | 'expecting-pay'
//   | 'success-pay'
//   | 'warning'
//   | 'repaid';

// export enum DebtPaymentState {
//   PROMISE = 'promise',
//   PROMISE_LATE = 'promise-late',
//   PROMISE_SUCCESS = 'promise-success',
//
//   SCHEDULE_PAY = 'schedule-pay',
//   SCHEDULE_PAY_LATE = 'schedule-pay-late',
//   SCHEDULE_PAY_SUCCESS = 'schedule-pay-success',
//
//   INSTANT_PAY_EXPECTING = 'instant-pay-expecting',
//   INSTANT_PAY_SUCCESS = 'instant-pay-success',
//
//   NO_PAYMENT_OPTION = 'no-payment-option',
//   REPAID = 'repaid',
// }

export enum CaseStatus {
  PTP_AWAITING = 'ptp_awaiting',
  PTP_PARTLY_REPAID = 'ptp_partly_repaid',
  PTP_ACTIVE = 'ptp_active',

  PP_LATE = 'pp_late',
  PP_ACTIVE = 'pp_active',

  CLOSED = 'close',

  PENDING = 'pending',
  ALL = 'all',
  OPEN = 'open',
  REPAID = 'repaid',
}
