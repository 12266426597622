// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, MinLength } from 'class-validator';

export class AuthLogin {
  @MinLength(4, {
    message: 'errorMessage.loginMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isDefinedLogin' })
  public login!: string;

  @MinLength(6, {
    message: 'errorMessage.passwordMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isDefinedPassword' })
  public password!: string;
}
