import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';
import { commonSelector } from '@common/duck/selectors';

const debtsSelector = getFeatureSelector(Feature.DEBTS);

export const isDebtsLoadingSelector = createSelector(
  commonSelector,
  ({ loading }) => loading,
);

export const debtItemsSelector = createSelector(
  debtsSelector,
  ({ debtItems }) => debtItems,
);
