import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IVerifyUserRequestDto } from '@common/api/dto/features/AuthUser/request/IVerifyUserRequestDto';
import { IVerifyUserResponseDto } from '@common/api/dto/features/AuthUser/response/IVerifyUserResponseDto';

class VerifyUser extends ApiServiceBase {
  public constructor() {
    super();
  }

  public verify(
    dto: IVerifyUserRequestDto,
  ): Promise<ApiResponse<IVerifyUserResponseDto>> {
    return this.post('/api/v1/users/validation', dto);
  }
}

export const verifyUser = new VerifyUser();
