import { connect, ConnectedProps } from 'react-redux';

import { authSlice } from '@pages/Auth/duck/slice';
import { IRootState } from '@common/redux/store';
import {
  isAuthorizedSelector,
  isLoadingSelector,
  authStepSelector,
  verifyContactsSelector,
  loginSelector,
  errorSelector,
} from '@pages/Auth/duck/selectors';
import { authAsyncActions } from '@pages/Auth/duck/authAsyncActions';
import { lostPasswordSlice } from '@components/main/LostPassword/duck/slice';

export const withAuthState = connect(
  (state: IRootState) => ({
    isLoading: isLoadingSelector(state),
    authStep: authStepSelector(state),
    isAuthorized: isAuthorizedSelector(state),
    verifyOptions: verifyContactsSelector(state),
    userLogin: loginSelector(state),
    serverError: errorSelector(state),
  }),
  {
    openLostPassword: lostPasswordSlice.actions.toggleLostPassword,
    ...authSlice.actions,
    ...authAsyncActions,
  },
);

export type IWithAuthState = ConnectedProps<typeof withAuthState>;
