import { Dispatch } from '@reduxjs/toolkit';

import { StatefulPage } from '@common/enums/Page';
import { pageFeature } from '@app/PageWrapper/utils/pageFeatures';

interface IParams {
  pageName: StatefulPage;
  pageResponseDto: unknown;
  dispatch: Dispatch;
}

export const initPageFeatures = ({
  pageName,
  pageResponseDto,
  dispatch,
}: IParams): void => {
  pageFeature[pageName].forEach(({ setState }) => {
    dispatch(setState(pageResponseDto));
  });
};
