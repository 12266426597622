import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PayInfo } from '@components/main/DebtCases/components/PayInfo/PayInfo';
import { ActionButtons } from '@components/main/DebtCases/components/ActionButtons/ActionButtons';
import { Helmet } from '@components/main/DebtCases/components/Helmet/Helmet';
import { InfoStripe } from '@components/main/DebtCases/components/InfoStripe/InfoStripe';
import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';
import { getShadowColorByVariant } from '@components/main/DebtCases/DebtCase/utils/getShadowColorByVariant';
import { StatefulPage } from '@common/enums/Page';
import { IDebtCase } from '@components/main/DebtCases/types/IDebtCase';

interface IProps {
  debtItem: IDebtCase;
  variant: CaseStatus;
  isPlanAllowed: boolean;
}

export const DebtCase: FC<IProps> = ({
  debtItem,
  variant = CaseStatus.ALL,
  isPlanAllowed,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const isDisabledRoute = variant === CaseStatus.CLOSED;

  return (
    <Tooltip
      id='disabled-payment'
      title={<STooltipTitle>{t('disabledPayment.info')}</STooltipTitle>}
      arrow={true}
      placement='top'
      followCursor={true}
      disableHoverListener={!isDisabledRoute}
    >
      <SOuterWrapper
        onClick={() =>
          !isDisabledRoute &&
          navigate(`${StatefulPage.DEBT_DETAILS}/${debtItem.caseData.id}`)
        }
      >
        <SWrapper variant={variant}>
          <SHelmetWrapper>
            <Helmet
              symbol={debtItem.caseData.externalId}
              companyLogo={debtItem.creditor.logoUrl}
              companyName={debtItem.creditor.name}
            />
          </SHelmetWrapper>
          <SInfoStripeWrapper>
            <InfoStripe variant={variant} />
          </SInfoStripeWrapper>
          <SPaymentInfoWrapper>
            <PayInfo
              variant={debtItem.caseStatus}
              paymentInfo={debtItem.caseData}
            />
          </SPaymentInfoWrapper>
          <SActionsWrapper>
            <ActionButtons
              debtId={debtItem.caseData.id}
              variant={debtItem.caseStatus}
              isPlanAllowed={isPlanAllowed}
            />
          </SActionsWrapper>
        </SWrapper>
      </SOuterWrapper>
    </Tooltip>
  );
};

const STooltipTitle = styled.div`
  ${(p) => p.theme.font.header.h6};
`;
const SInfoStripeWrapper = styled.div`
  margin-bottom: 18px;
`;
const SHelmetWrapper = styled.div`
  margin-bottom: 18px;
`;
const SActionsWrapper = styled.div`
  margin-bottom: 17px;
`;
const SPaymentInfoWrapper = styled.div`
  display: flex;
  column-gap: 20%;
  margin-bottom: 17px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    row-gap: 30px;
  }
`;
const SOuterWrapper = styled.div`
  width: 100%;
  cursor: pointer;
`;
const SWrapper = styled.div<{ variant: CaseStatus }>`
  ${({ theme, variant }) => {
    const param = getShadowColorByVariant(variant, theme);

    return css`
      min-height: 256px;

      border-radius: 8px;
      padding: 4%;
      background-color: ${theme.colors.base.WHITE};
      ${param
        ? css`
            box-shadow: 0 16px 36px -13px ${param};
            &:hover {
              box-shadow: 0 16px 36px 0 ${param};
            }
          `
        : theme.shadows.common};
      transition-property: all;
      transition: 0.3s;
    `;
  }}
`;
