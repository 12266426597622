import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';

const header = getFeatureSelector(Feature.HEADER);

export const isHeaderLoadingSelector = createSelector(
  header,
  ({ isLoading }) => isLoading,
);

export const userInfoHeaderSelector = createSelector(
  header,
  ({ userInfo }) => userInfo,
);
