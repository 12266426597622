// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, IsEmail, MinLength } from 'class-validator';

import { SupportForm } from '@components/main/Support/dto/SupportForm';

export class ContactUs extends SupportForm {
  @IsEmail({}, { message: 'errorMessage.isInvalidEmail' })
  @MinLength(3, {
    message: 'errorMessage.emailMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isEmptyEmail' })
  public email!: string;

  @MinLength(3, {
    message: 'errorMessage.messageMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isEmptyMessage' })
  public message!: string;

  @MinLength(3, {
    message: 'errorMessage.subjectMinLength{{$constraint1}}',
  })
  @IsDefined({ message: 'errorMessage.isSubjectMessage' })
  public subject!: string;
}
