import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';
import { InfoColumn } from '@components/main/DebtCases/components/InfoColumn/InfoColumn';
import { ICaseData } from '@components/main/DebtCases/types/IDebtCase';

interface IPaymentInfoProps {
  variant: CaseStatus;
  paymentInfo: ICaseData;
  bankAccount?: string;
  symbol?: string;
  size?: 'sm' | 'lg';
}

export const PayInfo: FC<IPaymentInfoProps> = ({
  variant,
  paymentInfo,
  bankAccount,
  symbol,
  size = 'sm',
}) => {
  const { t } = useTranslation();

  const isPtP =
    variant === CaseStatus.PTP_AWAITING ||
    variant === CaseStatus.PTP_PARTLY_REPAID ||
    variant === CaseStatus.PTP_ACTIVE;

  const isPpLate = variant === CaseStatus.PP_LATE;

  const getDueDateFormat = (): string => {
    if (isPtP) {
      return t('details.dueDatePtp');
    } else {
      return isPpLate ? t('details.dueDatePpLate') : t('details.dueDateNext');
    }
  };

  switch (variant) {
    case CaseStatus.PTP_AWAITING:
    case CaseStatus.PTP_PARTLY_REPAID:
    case CaseStatus.PTP_ACTIVE:
    case CaseStatus.PP_ACTIVE:
    case CaseStatus.PP_LATE:
    case CaseStatus.OPEN:
    case CaseStatus.ALL:
    case CaseStatus.PENDING:
      return (
        <>
          <InfoColumn
            title={t('debtsFeedCase.expectedPay')}
            value={`${paymentInfo.payment} Kč`}
            size='md'
          />
          {paymentInfo.dueDate && (
            <InfoColumn
              title={getDueDateFormat()}
              value={paymentInfo?.dueDate.split('-').reverse().join('.')}
              size='md'
            />
          )}
          {size === 'lg' && bankAccount && (
            <>
              <InfoColumn
                title={t('details.bankAccount')}
                value={bankAccount}
                size='md'
              />
              {symbol && (
                <InfoColumn
                  title={t('details.variableSymbol')}
                  value={symbol}
                  size='md'
                />
              )}
            </>
          )}
          {paymentInfo?.totalDebt && (
            <InfoColumn
              title={t('debtsFeedCase.totalDebt')}
              value={`${paymentInfo.totalDebt} Kč`}
              bold={true}
              size='md'
            />
          )}
        </>
      );
    case CaseStatus.REPAID:
    case CaseStatus.CLOSED:
      return (
        <>
          <InfoColumn
            title={t('debtsFeedCase.minAmount')}
            value={paymentInfo.payment}
            size='md'
          />
          <InfoColumn
            title={t('debtsFeedCase.totalDebt')}
            value={paymentInfo.totalDebt}
            size='md'
          />
        </>
      );
    default:
      return null;
  }
};
