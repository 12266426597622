// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined, Matches, MinLength } from 'class-validator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ValidateSecurityNumber } from '@components/main/AuthForm/dto/decorator/ValidateSecurityNumber';

export class AuthVerification {
  @MinLength(3, {
    message: 'errorMessage.nameMinLength{{$constraint1}}',
  })
  @Matches(/[A-Za-z]/, {
    message: 'errorMessage.isInvalidName',
  })
  @IsDefined({ message: 'errorMessage.isDefinedName' })
  public firstName!: string;

  @MinLength(3, {
    message: 'errorMessage.lastNameMinLength{{$constraint1}}',
  })
  @Matches(/[A-Za-z]/, {
    message: 'errorMessage.isInvalidLastName',
  })
  @IsDefined({ message: 'errorMessage.isDefinedLastName' })
  public lastName!: string;

  @ValidateSecurityNumber('number', { message: 'errorMessage.invalidID' })
  @IsDefined({ message: 'errorMessage.isNumberDefined' })
  public number!: string;
}
