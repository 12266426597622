import { Dispatch, FC, useCallback } from 'react';
import styled from 'styled-components';

import { AuthFormVariant } from '@components/main/AuthForm/enums/AuthFormVariant';
import {
  ILoginProps,
  Login,
} from '@components/main/AuthForm/components/CardVariants/Login/Login';
import {
  IVerificationProps,
  Verification,
} from '@components/main/AuthForm/components/CardVariants/Registration/Verification';
import {
  IVerificationOptionProps,
  VerificationOption,
} from '@components/main/AuthForm/components/CardVariants/Registration/VerificationOption';
import {
  CodeValidation,
  ICodeValidationProps,
} from '@components/main/AuthForm/components/CardVariants/Registration/CodeValidation';
import {
  CreateAccount,
  ICreateAccountProps,
} from '@components/main/AuthForm/components/CardVariants/Registration/CreateAccount';
import { BaseFormCard } from '@app/BaseFormCard/BaseFormCard';
import { TAuthStep } from '@pages/Auth/types/TAuthStep';
import { ICodeSubmitRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeSubmitRequestDto';
import { ISelectedContact } from '@components/main/AuthForm/types/ISelectedContact';
import { getContactType } from '@components/main/AuthForm/utils/getContactType';

export interface IAuthProps
  extends ILoginProps,
    IVerificationProps,
    IVerificationOptionProps,
    ICodeValidationProps,
    ICreateAccountProps {
  stepBack?(value?: TAuthStep): void;
  setSelectedContact?: Dispatch<ISelectedContact | null>;
  onSendCode?(value: ICodeSubmitRequestDto): void;
}

type IProps = IAuthProps & {
  variant: AuthFormVariant;
  title: string;
  caption?: string;
  subtitle?: string;
};

export const AuthForm: FC<IProps> = ({
  title,
  caption,
  subtitle,
  userLogin,
  variant,
  stepBack,
  onSendCode,
  verifyOptions,
  isLoading,
  onLogin,
  onVerify,
  selectedContact,
  setSelectedContact,
  onCreateAccount,
  onValidateCode,
  onLostPassword,
  serverError,
  ...props
}) => {
  const handleSendCode = useCallback((): void => {
    if (selectedContact && onSendCode) {
      onSendCode({
        contact: selectedContact.contact,
        contact_id: selectedContact.contactId,
        contact_type: getContactType(selectedContact.contact),
      });
    }

    return;
  }, [onSendCode, selectedContact]);

  return (
    <BaseFormCard
      title={title}
      caption={caption}
      stepBack={!isLoading ? stepBack : undefined}
    >
      {subtitle && <SSubTitleWrapper>{subtitle}</SSubTitleWrapper>}
      {variant === AuthFormVariant.LOGIN && (
        <Login
          onLogin={onLogin}
          isLoading={isLoading}
          onLostPassword={onLostPassword}
          serverError={serverError}
        />
      )}
      {variant === AuthFormVariant.VERIFICATION && (
        <Verification
          isLoading={isLoading}
          onVerify={onVerify}
          serverError={serverError}
          {...props}
        />
      )}
      {variant === AuthFormVariant.VERIFICATION_OPTION && (
        <VerificationOption
          isLoading={isLoading}
          verifyOptions={verifyOptions}
          selectedContact={selectedContact}
          onSelect={setSelectedContact}
          onSubmitCode={handleSendCode}
          serverError={serverError}
        />
      )}
      {variant === AuthFormVariant.CODE_VALIDATION && (
        <CodeValidation
          isLoading={isLoading}
          onValidateCode={onValidateCode}
          onResendCode={handleSendCode}
          serverError={serverError}
        />
      )}
      {variant === AuthFormVariant.CREATE_ACCOUNT && (
        <CreateAccount
          isLoading={isLoading}
          userLogin={userLogin}
          onCreateAccount={onCreateAccount}
          serverError={serverError}
        />
      )}
    </BaseFormCard>
  );
};

const SSubTitleWrapper = styled.div`
  padding-top: 9px;
  margin-bottom: 41px;
  ${(p) => p.theme.font.header.h4};
  color: ${(p) => p.theme.colors.text.BODY};
  text-align: center;
`;
