import {
  ILoginGuestRequestDto,
  ILoginRequestDto,
} from '@common/api/dto/features/AuthUser/request/ILoginRequestDto';
import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { ILogInResponseDto } from '@common/api/dto/features/AuthUser/response/ILogInResponseDto';

class Login extends ApiServiceBase {
  public constructor() {
    super();
  }

  public auth(dto: ILoginRequestDto): Promise<ApiResponse<ILogInResponseDto>> {
    return this.post('/api/v1/users/token', dto);
  }

  public authGuest(
    dto: ILoginGuestRequestDto,
  ): Promise<ApiResponse<ILogInResponseDto>> {
    return this.post('/api/v1/users/login_by_token', dto);
  }
}

export const logIn = new Login();
