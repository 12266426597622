import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ILoginGuestRequestDto,
  ILoginRequestDto,
} from '@common/api/dto/features/AuthUser/request/ILoginRequestDto';
import { logIn } from '@common/api/services/Login/Login';
import { ApiResponse } from '@common/api/ApiResponse';
import { ILogInResponseDto } from '@common/api/dto/features/AuthUser/response/ILogInResponseDto';
import { ICheckAuthResponseDto } from '@common/api/dto/features/AuthUser/response/ICheckAuthResponseDto';
import { checkAuth } from '@common/api/services/CheckAuth/CheckAuth';
import { IVerifyUserRequestDto } from '@common/api/dto/features/AuthUser/request/IVerifyUserRequestDto';
import { IVerifyUserResponseDto } from '@common/api/dto/features/AuthUser/response/IVerifyUserResponseDto';
import { verifyUser } from '@common/api/services/VerifyUser/VerifyUser';
import { ICodeSubmitRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeSubmitRequestDto';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { codeSubmit } from '@common/api/services/CodeSubmit/CodeSubmit';
import { ICodeValidationRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeValidationRequestDto';
import { validateCode } from '@common/api/services/ValidateCode/ValidateCode';
import { createAccount } from '@common/api/services/CreateAccount/CreateAccount';
import { ICreateAccountRequestDto } from '@common/api/dto/features/AuthUser/request/ICreateAccountRequestDto';
import { StatelessPage } from '@common/enums/Page';

export const authAsyncActions = {
  logIn: createAsyncThunk(
    `${StatelessPage.AUTH}/logIn`,
    async (dto: ILoginRequestDto, { rejectWithValue }) => {
      try {
        const response: ApiResponse<ILogInResponseDto> = await logIn.auth(dto);

        sessionStorage.setItem('accessToken', response.data.access_token);

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),

  logInGuest: createAsyncThunk(
    `${StatelessPage.AUTH}/logInGuest`,
    async (dto: ILoginGuestRequestDto) => {
      const response: ApiResponse<ILogInResponseDto> = await logIn.authGuest(
        dto,
      );

      sessionStorage.setItem('accessToken', response.data.access_token);

      return response.data;
    },
  ),

  checkAuth: createAsyncThunk(`${StatelessPage.AUTH}/checkAuth`, async () => {
    const response: ApiResponse<ICheckAuthResponseDto> =
      await checkAuth.check();

    return response.data;
  }),

  verifyUser: createAsyncThunk(
    `${StatelessPage.AUTH}/verifyUser`,
    async (dto: IVerifyUserRequestDto, { rejectWithValue }) => {
      try {
        const response: ApiResponse<IVerifyUserResponseDto> =
          await verifyUser.verify(dto);

        sessionStorage.setItem('accessToken', response.data.access_token);

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
  sendCode: createAsyncThunk(
    `${StatelessPage.AUTH}/sendCode`,
    async (dto: ICodeSubmitRequestDto, { rejectWithValue }) => {
      try {
        await codeSubmit.submit(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
  validateCode: createAsyncThunk(
    `${StatelessPage.AUTH}/validateCode`,
    async (dto: ICodeValidationRequestDto, { rejectWithValue }) => {
      try {
        const response: ApiResponse<IBasePayload> = await validateCode.validate(
          dto,
        );

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
  createAccount: createAsyncThunk(
    `${StatelessPage.AUTH}/createAccount`,
    async (dto: ICreateAccountRequestDto, { rejectWithValue }) => {
      try {
        await createAccount.create(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
};
