import { useTranslation } from 'react-i18next';

import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';

export const useInfoStripeText = (variant: CaseStatus): string | null => {
  const { t } = useTranslation();

  switch (variant) {
    case CaseStatus.PTP_AWAITING:
      return t('infoStripeStatus.ptpAwait');
    case CaseStatus.PTP_PARTLY_REPAID:
      return t('infoStripeStatus.ptpPartlyRepaid');
    case CaseStatus.PTP_ACTIVE:
      return t('infoStripeStatus.ptpActive');
    case CaseStatus.PP_ACTIVE:
      return t('infoStripeStatus.ppActive');
    case CaseStatus.PP_LATE:
      return t('infoStripeStatus.ppLate');
    case CaseStatus.REPAID:
      return t('infoStripeStatus.repaid');
    // case CaseStatus.ALL:
    //   return t('infoStripeStatus.all');
    case CaseStatus.CLOSED:
      return t('infoStripeStatus.closed');
    case CaseStatus.PENDING:
    case CaseStatus.OPEN:
      return t('infoStripeStatus.open');
    default:
      return null;
  }
};
