import styled, { css } from 'styled-components';
import { ButtonHTMLAttributes, ReactNode } from 'react';

import { getButtonParamsByColor } from '@components/ui/Button/utils/getButtonParamsByColor';
import { getButtonParamsBySize } from '@components/ui/Button/utils/getButtonParamsBySize';
import { getButtonOutlinedParams } from '@components/ui/Button/utils/getButtonOutlinedParams';
import { getButtonTextParamsBySize } from '@components/ui/Button/utils/getButtonTextParamsBySize';
import { TButtonType } from '@components/ui/Button/types/TButtonType';
import { TButtonColor } from '@components/ui/Button/types/TButtonColor';
import { TButtonSize } from '@components/ui/Button/types/TButtonSize';
import { Icon, IIconProps } from '@components/ui/Icon/Icon';
import { getClickableIconParamsByColor } from '@components/ui/Button/utils/getClickableIconParamsByColor';
import { Loader } from '@components/ui/Loader/Loader';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  buttonType?: TButtonType;
  color?: TButtonColor;
  icon?: IIconProps;
  disabled?: boolean;
  size?: TButtonSize;
  underline?: boolean;
  w100?: boolean;
  active?: boolean;
  customIcon?: ReactNode;
  isLoading?: boolean;
}

export const Button = ({
  children,
  w100,
  size = 'md',
  buttonType = 'default',
  color = 'primary',
  disabled = false,
  underline = false,
  active = false,
  icon,
  customIcon,
  isLoading,
  ...props
}: IButtonProps): JSX.Element => {
  switch (buttonType) {
    case 'default':
    default:
      return (
        <SButton
          type='button'
          role='button'
          w100={w100}
          color={color}
          disabled={disabled}
          size={size}
          {...props}
        >
          {icon && (
            <SIcon>
              <Icon {...icon} />
            </SIcon>
          )}
          {customIcon && <SIcon>{customIcon}</SIcon>}
          <span>{isLoading ? <Loader size='sm' /> : children}</span>
        </SButton>
      );
    case 'text':
      return (
        <SButton
          type='button'
          role='button'
          w100={w100}
          color='ghost'
          disabled={disabled}
          size={size}
          {...props}
        >
          <SText size={size} underline={underline}>
            {isLoading ? <Loader size='sm' /> : children}
          </SText>
        </SButton>
      );
    case 'clickable-icon':
      return (
        <SClickableIcon
          type='button'
          role='button'
          size={size}
          w100={w100}
          disabled={disabled}
          {...props}
        >
          {icon && (
            <SIcon>
              <Icon {...icon} />
            </SIcon>
          )}
          {isLoading ? <Loader size='sm' /> : children}
        </SClickableIcon>
      );
    case 'outlined':
      return (
        <SButtonOutlined
          type='button'
          role='button'
          w100={w100}
          color={color}
          size={size}
          active={active}
          {...props}
        >
          {isLoading ? <Loader size='sm' /> : children}
        </SButtonOutlined>
      );
  }
};

const SBaseButton = styled.button<{ w100?: boolean }>`
  display: inline-flex;
  background: none;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 7px 16px;
  white-space: nowrap;

  width: fit-content;

  align-items: center;
  border-radius: 4px;
  justify-content: center;

  ${({ w100 }) =>
    w100 &&
    css`
      width: 100%;
    `}
`;

const SIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 11px;
`;
const SClickableIcon = styled(SBaseButton)<{ size: TButtonSize }>`
  padding: 0;
  background: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  justify-content: center;

  ${({ disabled, size, theme }) => {
    const params = getClickableIconParamsByColor(size, disabled, theme);

    return css`
      ${params.font};
      color: ${params.color};

      &:hover {
        color: ${params.hoverColor};
      }
    `;
  }}
`;
const SText = styled.span<{ size: string; underline: boolean }>`
  ${({ theme, size, underline }) => {
    const params = getButtonTextParamsBySize(size, theme);

    return css`
      ${params.font};

      ${underline &&
      css`
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-position: from-font;
      `}

      &:hover {
        color: ${theme.colors.text.MUTED};
      }
    `;
  }}
`;

const SButtonOutlined = styled(SBaseButton)<{
  color: string;
  size: string;
  active: boolean;
}>`
  ${({ theme, color, active }) => {
    const params = getButtonOutlinedParams(color, theme);

    return css`
      ${active
        ? css`
            background-color: ${params.hoverBackground};
            color: ${theme.colors.base.WHITE};
            border: 1px solid ${theme.colors.text.BODY};
          `
        : css`
            color: ${params.textColor};
            background-color: ${params.background};
            border: 1px solid ${params.borderColor};
          `};

      &:hover {
        background-color: ${params.hoverBackground};
        color: ${theme.colors.base.WHITE};
      }
    `;
  }}
  ${({ theme, size }) => {
    const params = getButtonParamsBySize(size, theme);

    return css`
      padding: ${params.padding};
      ${params.font}
    `;
  }}
`;

const SButton = styled(SBaseButton)<{
  color: string;
  size: string;
  disabled: boolean;
}>`
  ${({ theme, color, disabled }) => {
    const params = getButtonParamsByColor(color, disabled, theme);

    return css`
      color: ${params.textColor};
      background-color: ${params.background};
      border: 1px solid ${params.borderColor};

      &:hover {
        color: ${params.hover};
        background-color: ${params.hoverBackground};
      }
      &:hover ${SText} {
        color: ${theme.colors.base.DARK};
      }
    `;
  }}
  ${({ theme, size }) => {
    const params = getButtonParamsBySize(size, theme);

    return css`
      padding: ${params.padding};
      ${params.font};

      ${SIcon} {
        margin-right: 9px;
      }
    `;
  }}
`;
