import { FC } from 'react';
import styled from 'styled-components';
import { HandleThunkActionCreator } from 'react-redux';

import { DebtDetailedWrapper } from '@components/main/DebtCases/DebtCaseDetailed/components/DebtDetailedWrapper';
import { Helmet } from '@components/main/DebtCases/components/Helmet/Helmet';
import { PaymentDetails } from '@components/main/DebtCases/DebtCaseDetailed/components/MainInfo/components/PayInfo/PaymentDetails';
import { InfoStripe } from '@components/main/DebtCases/components/InfoStripe/InfoStripe';
import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';
import { IDebtCaseDetailed } from '@components/main/DebtCases/types/IDebtCaseDetailed';
import { ICheckCreatePlan } from '@pages/DebtCases/duck/debtCasesAsyncActions';
import { debtDetailsAsyncActions } from '@pages/DebtDetails/duck/debtDetailsAsyncActions';

interface IProps {
  debtItem: IDebtCaseDetailed;
  activeSection: AnchorTabs;
  createPlanData: ICheckCreatePlan | boolean;
  getQrCode: HandleThunkActionCreator<typeof debtDetailsAsyncActions.getQr>;
  hasPaySchedule: boolean;
}

export const MainInfo: FC<IProps> = ({
  debtItem,
  // activeSection,
  createPlanData,
  getQrCode,
  // hasPaySchedule,
}) => (
  <DebtDetailedWrapper>
    <SHelmetWrapper id={AnchorTabs.OVERVIEW}>
      <Helmet
        symbol={debtItem.caseData.externalId}
        companyLogo={debtItem.creditor.logoUrl}
        companyName={debtItem.creditor.name}
        size='lg'
      />
      {/*<TabAnchors*/}
      {/*  activeSection={activeSection}*/}
      {/*  showHistory={!!debtItem?.paymentHistory.length}*/}
      {/*  hasPaySchedule={hasPaySchedule}*/}
      {/*/>*/}
    </SHelmetWrapper>
    <SInfoStripeWrapper>
      <InfoStripe variant={debtItem.caseStatus} />
    </SInfoStripeWrapper>
    <PaymentDetails
      debtItem={debtItem}
      createPlanData={createPlanData}
      getQrCode={getQrCode}
    />
  </DebtDetailedWrapper>
);

const SInfoStripeWrapper = styled.div`
  padding: 5px 0 32px;
`;
const SHelmetWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;
