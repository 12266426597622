import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { ICodeSubmitRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeSubmitRequestDto';

class CodeSubmit extends ApiServiceBase {
  public constructor() {
    super();
  }

  public submit(
    dto: ICodeSubmitRequestDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/contact_verification', dto);
  }
}

export const codeSubmit = new CodeSubmit();
