import { FieldValidator } from 'final-form';

import { IClass } from '@common/types/object/IClass';
import { getFieldValidators } from '@app/FinalForm/utils/getFieldValidators';

type IFieldValidatorResult<Dto> = {
  [key in keyof Dto]?: string | undefined;
};

export type TFormValidator<Dto> = (
  dto: Omit<Dto, 'prototype'>,
) => IFieldValidatorResult<Dto>;

export const getFormValidator = <TDto extends IClass>(
  Dto: TDto,
): TFormValidator<TDto> => {
  const validators = getFieldValidators(Dto);

  const dtoInstance = new Dto();

  return (dto) =>
    Object.fromEntries(
      Object.keys(dtoInstance).map((key) => [
        key,
        validators[key]
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            ((validators[key] as FieldValidator<typeof key>)(dto[key], dto) as
              | string
              | undefined)
          : undefined,
      ]),
    ) as IFieldValidatorResult<TDto>;
};
