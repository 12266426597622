import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Element } from '@components/main/DebtCases/DebtCaseDetailed/components/PaymentPlan/Table/components/Element';
import { IPaymentPlan } from '@components/main/DebtCases/types/IDebtCaseDetailed';
import { useScrollIntoView } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/hooks/useScrollHeight';
import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';

interface IProps {
  data: IPaymentPlan[];
}

export const Table: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const { onToElementView } = useScrollIntoView(AnchorTabs.OVERVIEW);

  return (
    <SWrapper>
      <SLabelsWrapper>
        <SCol>
          <SLabel>{t('details.payId')}</SLabel>
          <SLabel>{t('debtsFeedCase.dueDate')}</SLabel>
        </SCol>
        <SLabelLast>{t('form.monthlyPay')}</SLabelLast>
      </SLabelsWrapper>
      {data.map((el) => (
        <Element key={el.installmentId} plan={el} onClick={onToElementView} />
      ))}
    </SWrapper>
  );
};

const SCol = styled.div`
  display: flex;
  column-gap: 10px;
`;
const SLabel = styled.div`
  padding: 16px 12px;
`;
const SLabelLast = styled.div`
  padding: 16px 25px;
`;
const SLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.text.GRAY};
  border-top: 1px solid ${(p) => p.theme.colors.base.DARK_WHITE};
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.font.special.label};
`;
