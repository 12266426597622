import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { support } from '@common/api/services/Support/Support';
import { ApiResponse } from '@common/api/ApiResponse';
import { ISupportRequestDto } from '@common/api/dto/features/Support/request/ISupportRequestDto';
import { IUserInfoResponseDto } from '@common/api/dto/features/UserInfo/IUserInfoResponseDto';
import { userInfo } from '@common/api/services/UserInfo/UserInfo';
import { callBack } from '@common/api/services/CallBack/CallBack';
import { ICallBackResponseDto } from '@common/api/dto/features/CallBack/response/ICallBackResponseDto';
import { ICallBackRequestDto } from '@common/api/dto/features/CallBack/request/ICallBackRequestDto';

export const supportAsyncActions = {
  submitSupport: createAsyncThunk(
    `${Feature.SUPPORT}/submit`,
    async (dto: ISupportRequestDto, { rejectWithValue }) => {
      try {
        await support.submit(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),

  submitCallSupport: createAsyncThunk(
    `${Feature.SUPPORT}/call-me-back`,
    async (dto: ICallBackRequestDto, { rejectWithValue }) => {
      try {
        await callBack.submit(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),

  prefill: createAsyncThunk(`${Feature.SUPPORT}/prefill`, async () => {
    const response: ApiResponse<IUserInfoResponseDto> = await userInfo.fetch();

    return response.data;
  }),

  getDatesForCall: createAsyncThunk(
    `${Feature.SUPPORT}/dates-for-call`,

    async () => {
      const response: ApiResponse<ICallBackResponseDto> =
        await callBack.fetch();

      return response.data;
    },
  ),
};
