import { Dispatch, FC } from 'react';
import { HandleThunkActionCreator } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseFormCard } from '@app/BaseFormCard/BaseFormCard';
import { CodeValidation } from '@components/main/AuthForm/components/CardVariants/Registration/CodeValidation';
import { lostPasswordAsyncActions } from '@components/main/LostPassword/duck/lostPasswordAsyncActions';
import { hideMiddleText } from '@common/utils/functions/text/hideMiddleText';

interface IProps {
  isLoading: boolean;
  login: string;
  onSetCode: Dispatch<string>;
  onSubmit: HandleThunkActionCreator<
    typeof lostPasswordAsyncActions.verifyCode
  >;
  onResend: HandleThunkActionCreator<
    typeof lostPasswordAsyncActions.requestCode
  >;
  serverError: string | null;
}

export const CodeVerify: FC<IProps> = ({
  isLoading,
  login,
  serverError,
  onSetCode,
  onSubmit,
  onResend,
}) => {
  const { t } = useTranslation();

  return (
    <BaseFormCard
      title={t('lostPassword.success')}
      caption={`${t('registration.stepThree.caption', {
        value: hideMiddleText(login),
      })}`}
      boxSizing='content-box'
    >
      <CodeValidation
        isLoading={isLoading}
        serverError={serverError}
        onResendCode={async () => {
          await onResend(login);
        }}
        onValidateCode={async (code) => {
          await onSubmit({ login, code });
          onSetCode(code);
        }}
      />
    </BaseFormCard>
  );
};
