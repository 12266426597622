import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { ISupportRequestDto } from '@common/api/dto/features/Support/request/ISupportRequestDto';

class Support extends ApiServiceBase {
  public constructor() {
    super();
  }

  public submit(dto: ISupportRequestDto): Promise<ApiResponse<IBasePayload>> {
    const bodyFormData = new FormData();

    if (dto.attachment) {
      bodyFormData.append('attachment', dto.attachment);
    }

    bodyFormData.append('first_name', dto.first_name);
    bodyFormData.append('last_name', dto.last_name);
    bodyFormData.append('email', dto.email);
    bodyFormData.append('phone', dto.phone);
    bodyFormData.append('subject', dto.subject);
    bodyFormData.append('message', dto.message);

    return this.post('/api/v1/users/contact_us', bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export const support = new Support();
