import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SupportCall } from '@components/main/Support/components/SupportCall';
import { SupportContact } from '@components/main/Support/components/SupportContact';
import {
  IWithSupportState,
  withSupportState,
} from '@components/main/Support/hocs/withSupportState';
import { SentSuccess } from '@components/main/LostPassword/components/SentSuccess';
import { PopupView } from '@app/PopupView/PopupView';

export const SupportComponent: FC<IWithSupportState> = ({
  isLoading,
  onClose,
  openedSupportState = null,
  userInfo,
  submitSupport,
  submitCallSupport,
  prefill,
  serverError,
  onRestoreError,
  isAuthorized,
  getDatesForCall,
  datesForCall,
}) => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);

  const handleClose = (): void => {
    onClose();
    setIsSent(false);
  };

  if (!openedSupportState) {
    return null;
  }

  return (
    <PopupView
      isOpen={!!openedSupportState}
      close={handleClose}
      testId='support-popup'
    >
      {isSent && !serverError ? (
        <SentSuccess
          title={t('support.success')}
          actionTitle={t('buttons.close')}
          onClick={handleClose}
        />
      ) : openedSupportState === 'call-back' ? (
        <SupportCall
          onClose={onClose}
          setIsSent={setIsSent}
          isLoading={isLoading}
          userInfo={userInfo}
          prefill={prefill}
          onSubmit={submitCallSupport}
          serverError={serverError}
          onRestoreError={onRestoreError}
          getDatesForCall={getDatesForCall}
          datesForCall={datesForCall}
        />
      ) : (
        <SupportContact
          onClose={onClose}
          setIsSent={setIsSent}
          isLoading={isLoading}
          userInfo={userInfo}
          onSubmit={submitSupport}
          prefill={prefill}
          serverError={serverError}
          isAuthorized={isAuthorized}
          onRestoreError={onRestoreError}
        />
      )}
    </PopupView>
  );
};

export const Support = withSupportState(SupportComponent);
