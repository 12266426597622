import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Navigate } from 'react-router-dom';

import { Loader } from '@components/ui/Loader/Loader';
import { Page } from '@common/enums/Page';

export const PreLoader: FC = () => {
  const [delayed, setDelayed] = useState(true);

  const debounceLoader = debounce(() => {
    setDelayed(false);
  }, 2_000);

  useEffect(() => {
    if (delayed) {
      debounceLoader();
    }

    return () => {
      debounceLoader.cancel();
    };
  }, [delayed, debounceLoader]);

  if (delayed) {
    return (
      <SWrapper>
        <Loader size='lg' />
      </SWrapper>
    );
  }

  return <Navigate to={Page.AUTH} />;
};

const SWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
