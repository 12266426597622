import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { userInfo } from '@common/api/services/UserInfo/UserInfo';
import { ApiResponse } from '@common/api/ApiResponse';
import { IUserInfoResponseDto } from '@common/api/dto/features/UserInfo/IUserInfoResponseDto';

export const headerAsyncActions = {
  fetchUser: createAsyncThunk(`${Feature.HEADER}/fetch-user`, async () => {
    const response: ApiResponse<IUserInfoResponseDto> = await userInfo.fetch();

    return response.data;
  }),
};
