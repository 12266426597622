import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { ICreateAccountRequestDto } from '@common/api/dto/features/AuthUser/request/ICreateAccountRequestDto';

class CreateAccount extends ApiServiceBase {
  public constructor() {
    super();
  }

  public create(
    dto: ICreateAccountRequestDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/password', dto);
  }
}

export const createAccount = new CreateAccount();
