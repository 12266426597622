import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LngSwitch } from '@components/main/Header/components/LngSwitch';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { Page, StatefulPage } from '@common/enums/Page';

export const Burger: FC = () => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  return (
    <StyledBurger open={active} onClick={() => setActive(!active)}>
      <div />
      <div />
      <div />
      <SUl open={active}>
        <LinkComponent
          size='lg'
          to={StatefulPage.DEBTS}
          active={location.pathname === StatefulPage.DEBTS}
        >
          {t('header.pages.myDebts')}
        </LinkComponent>
        <LinkComponent
          size='lg'
          to={Page.FAQ}
          active={location.pathname === Page.FAQ}
        >
          {t('header.pages.faq')}
        </LinkComponent>
        <LngSwitch />
      </SUl>
    </StyledBurger>
  );
};

const StyledBurger = styled.div<{ open: boolean }>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 500;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${(p) => p.theme.colors.base.GRAY};
    z-index: 500;

    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
const SUl = styled.ul<{ open: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: start;
  row-gap: 30px;
}
  li {
    padding: 18px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: ${(p) => p.theme.colors.base.LIGHT};
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    ${(p) => p.theme.shadows.common};
    li {
      color: ${(p) => p.theme.colors.text.BODY};
    }
  }
`;
