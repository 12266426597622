export const hideMiddleText = (string: string): string => {
  if (!string.includes('@')) {
    return [...string]
      .map((i, idx) => (idx > 3 && idx < string.length - 3 ? '*' : i))
      .join('');
  }

  const textSplitByAt = string.split('@');

  if (textSplitByAt[0].length <= 4) {
    return (
      [...textSplitByAt[0]]
        .map((i, idx) => (idx >= 1 && idx <= 2 ? '*' : i))
        .join('') +
      '@' +
      textSplitByAt[1]
    );
  }
  if (textSplitByAt[0].length <= 7) {
    return (
      [...textSplitByAt[0]]
        .map((i, idx) =>
          idx >= 2 && idx <= textSplitByAt[0].length - 2 ? '*' : i,
        )
        .join('') +
      '@' +
      textSplitByAt[1]
    );
  }

  return (
    [...textSplitByAt[0]]
      .map((i, idx) =>
        idx >= 2 && idx <= textSplitByAt[0].length - 4 ? '*' : i,
      )
      .join('') +
    '@' +
    textSplitByAt[1]
  );
};
