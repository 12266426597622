import { FieldValidator } from 'final-form';
import { ReactText } from 'react';
import { getMetadataStorage, ValidationArguments } from 'class-validator';
import { ValidationUtils } from 'class-validator/cjs/validation/ValidationUtils';

import { IClass } from '@common/types/object/IClass';
import { composeValidators } from '@app/FinalForm/utils/composeValidators';

type IFieldValidators<Dto> = {
  [key in keyof Dto]: FieldValidator<key>;
};

export const getFieldValidators = <TDto extends IClass>(
  Dto: TDto,
): IFieldValidators<TDto> => {
  const dtoInstance = new Dto();

  const metadataStorage = getMetadataStorage();

  const targetMetadatas = metadataStorage.getTargetValidationMetadatas(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    dtoInstance.constructor,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    false,
    false,
  );

  const groupedMetadatas = metadataStorage.groupByPropertyName(targetMetadatas);

  return Object.fromEntries(
    Object.entries(groupedMetadatas).map(([key, groupedMetadata]) => {
      const fieldValidators: Array<FieldValidator<ReactText>> =
        groupedMetadata.map((metadata) => (value, values) => {
          const customValidationMetadata =
            metadataStorage.getTargetValidatorConstraints(
              metadata.constraintCls,
            )[0];

          const validationArguments: ValidationArguments = {
            targetName: dtoInstance.constructor
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                dtoInstance.constructor.name
              : undefined,
            property: metadata.propertyName,
            object: {
              ...dtoInstance,
              ...values,
            },
            value,
            constraints: metadata.constraints,
          };

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return customValidationMetadata.instance.validate(
            value,
            validationArguments,
          )
            ? undefined
            : // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
              ValidationUtils.replaceMessageSpecialTokens(
                metadata.message,
                validationArguments,
              );
        });

      return [key as keyof TDto, composeValidators(...fieldValidators)];
    }),
  ) as unknown as IFieldValidators<TDto>;
};
