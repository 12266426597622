import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Page } from '@common/enums/Page';

interface IState {
  prevPage: string | null;
}

interface IProps {
  id?: string;
}

export const DebtDetailedWrapper: FC<IProps> = ({ id, children }) => {
  const location = useLocation();

  const state = location.state as IState;

  const repeatAction = state?.prevPage === Page.CREATE_PAY;

  return (
    <SWrapper id={id} background={repeatAction}>
      {children}
    </SWrapper>
  );
};

const SWrapper = styled.div<{ background: boolean }>`
  ${({ theme, background }) => {
    const breathAnimation = keyframes`
    0% {
      box-shadow: 0 16px 36px -13px ${theme.colors.base.WARNING};
    } 
    50% {
      box-shadow: 0 16px 36px 10px ${theme.colors.base.WARNING};
    }
    100% {
      box-shadow: 0 16px 36px -13px ${theme.colors.base.WARNING};
    }
    `;

    return css`
      overflow-y: overlay;
      position: relative;
      width: 100%;
      border-radius: 8px;
      color: ${(p) => p.theme.colors.text.BODY};
      padding: 4% 6%;
      overflow-x: overlay;
      background-color: ${theme.colors.base.WHITE};
      ${theme.shadows.common};

      ${background &&
      css`
        &:first-child {
          animation: ${breathAnimation} 1.5s linear infinite;
          box-shadow: 0 16px 36px -13px ${theme.colors.base.WARNING};

          &:hover {
            box-shadow: 0 16px 36px 0 ${theme.colors.base.WARNING};
          }
        }
      `}
    `;
  }}
`;
