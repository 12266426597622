import { DefaultTheme } from 'styled-components';

import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';

interface IInfoStripParams {
  textColor: string;
  backgroundColor: string;
}

export const getInfoStripeParams = (
  variant: CaseStatus,
  { colors }: DefaultTheme,
): IInfoStripParams => {
  switch (variant) {
    case CaseStatus.PP_ACTIVE:
    case CaseStatus.PTP_ACTIVE:
      return {
        textColor: colors.base.PRIMARY,
        backgroundColor: colors.base.PRIMARY_LIGHT,
      };
    case CaseStatus.ALL:
    case CaseStatus.REPAID:
      return {
        textColor: colors.base.SUCCESS,
        backgroundColor: colors.base.SUCCESS_LIGHT,
      };
    case CaseStatus.CLOSED:
    case CaseStatus.PENDING:
      return {
        textColor: colors.base.SECONDARY,
        backgroundColor: colors.base.DARK_WHITE,
      };
    case CaseStatus.PP_LATE:
    case CaseStatus.PTP_AWAITING:
    case CaseStatus.PTP_PARTLY_REPAID:
    case CaseStatus.OPEN:
    default:
      return {
        textColor: colors.base.WARNING,
        backgroundColor: colors.base.WARNING_LIGHT,
      };
  }
};
