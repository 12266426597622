import { DefaultTheme } from 'styled-components';

interface IButtonParam {
  padding: string;
  font: string;
}

export const getButtonParamsBySize = (
  size: string,
  { font }: DefaultTheme,
): IButtonParam => {
  switch (size) {
    default:
    case 'lg':
      return {
        padding: '12px 20px',
        font: font.special.large,
      };
    case 'md':
      return {
        padding: '10px 16px',
        font: font.special.regular,
      };
    case 'sm':
      return {
        padding: '6px 12px',
        font: font.special.regular,
      };
  }
};
