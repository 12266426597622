import { AxiosResponse } from 'axios';

import { ErrorPageType } from '@common/api/enums/ErrorPageType';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { IBaseApiError } from '@common/api/types/IBaseApiError';

export class ApiPageError extends Error implements IBaseApiError<IBasePayload> {
  public constructor(
    public readonly errorPageType: ErrorPageType,
    public readonly response?: AxiosResponse<ApiResponse<IBasePayload>>,
  ) {
    super();
  }
}
