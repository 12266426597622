import styled from 'styled-components';
import { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { HandleThunkActionCreator } from 'react-redux';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { SupportWrapper } from '@components/main/Support/components/SupportWrapper';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { ContactUs } from '@components/main/Support/dto/ContactUs';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { FinalFormTextArea } from '@app/FinalForm/components/FinalFormTextArea';
import { Button } from '@components/ui/Button/Button';
import { ISupportForm } from '@components/main/Support/types/ISupportForm';
import { supportAsyncActions } from '@components/main/Support/duck/supportAsyncActions';

interface IProps extends ISupportForm {
  isAuthorized: boolean;
  onSubmit: HandleThunkActionCreator<typeof supportAsyncActions.submitSupport>;
}

export const SupportContact: FC<IProps> = ({
  setIsSent,
  isLoading,
  userInfo,
  onSubmit,
  prefill,
  serverError,
  onRestoreError,
  // isAuthorized,
  onClose,
}) => {
  useEffect(() => {
    void prefill();
  }, [prefill]);

  const { validate, fields } = useForm(ContactUs);

  // const [file, setFile] = useState<undefined | File>();
  // const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   if (event.target.files) {
  //     setFile(event.target.files[0]);
  //   }
  // };

  const { t } = useTranslation();

  useEffect(() => {
    onRestoreError();
  }, [onRestoreError]);

  return (
    <SupportWrapper close={onClose} title={t('contactUs.title')}>
      <Form<ContactUs>
        onSubmit={async (values) => {
          await onSubmit({
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone: values.phone,
            subject: values.subject,
            message: values.message,
            // attachment: file,
          });

          setIsSent(true);
        }}
        validate={validate}
        initialValues={userInfo}
        render={({ handleSubmit, submitting, touched, submitFailed }) => (
          <SForm onSubmit={handleSubmit}>
            <SInputContainer>
              <SInputNameWrapper>
                <FinalFormInput
                  id='name'
                  label={t('form.name')}
                  name={fields.firstName}
                  placeholder={t('placeholder.firstName')}
                  inProgress={isLoading}
                />
              </SInputNameWrapper>
              <SInputNameWrapper>
                <FinalFormInput
                  id='last-name'
                  label={t('form.surname')}
                  name={fields.lastName}
                  placeholder={t('form.surname')}
                  inProgress={isLoading}
                />
              </SInputNameWrapper>
            </SInputContainer>
            <SElementWrapper>
              <FinalFormInput
                id='email'
                label='Email'
                name={fields.email}
                inProgress={isLoading}
              />
            </SElementWrapper>
            <SElementWrapper>
              <FinalFormInput
                id='number'
                type='tel'
                onKeyUp={(e) => {
                  const target = e.target as HTMLInputElement;

                  target.value = target.value.replace(/[^\d-|()+]/g, '');
                }}
                label={t('form.phoneNum')}
                name={fields.phone}
                placeholder={t('placeholder.phone')}
              />
            </SElementWrapper>
            <SElementWrapper>
              <FinalFormInput
                id='subject'
                label={t('form.subject')}
                name={fields.subject}
                placeholder={t('placeholder.subject')}
                inProgress={isLoading}
              />
            </SElementWrapper>
            <SElementWrapper>
              <FinalFormTextArea
                id='message'
                label={t('form.message')}
                name={fields.message}
                placeholder={t('placeholder.message')}
                inProgress={isLoading}
              />
            </SElementWrapper>
            {/*{isAuthorized && (*/}
            {/*  <SElementWrapper>*/}
            {/*    <AttachBtn*/}
            {/*      fileName={file?.name}*/}
            {/*      onChange={handleChange}*/}
            {/*      onRemove={() => setFile(undefined)}*/}
            {/*    />*/}
            {/*  </SElementWrapper>*/}
            {/*)}*/}
            {serverError && (
              <SElementWrapper>
                <ActionStatus>{serverError}</ActionStatus>
              </SElementWrapper>
            )}
            <SButtonWrapper>
              <Button
                w100={true}
                size='lg'
                type='submit'
                color={submitFailed ? 'error' : 'primary'}
                disabled={!touched || submitting || isLoading}
                isLoading={isLoading}
              >
                {t('buttons.send')}
              </Button>
            </SButtonWrapper>
          </SForm>
        )}
      />
    </SupportWrapper>
  );
};

const SButtonWrapper = styled.div`
  width: 100%;
`;
const SElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const SInputContainer = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 16px;
`;
const SInputNameWrapper = styled.div``;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
