import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { AnyObject } from 'react-final-form';

import { TLinkSize } from '@components/ui/LinkComponent/types/TLinkSize';
import { getLinkParamsBySize } from '@components/ui/LinkComponent/utils/getLinkParamsBySize';

interface ILinkProps {
  to: string;
  state?: AnyObject;
  size?: TLinkSize;
  underline?: boolean;
  active?: boolean;
  disabled?: boolean;
}

export const LinkComponent: FC<ILinkProps> = ({
  size = 'md',
  state,
  to,
  children,
  underline = false,
  disabled = false,
  active = false,
}) => {
  const resolved = useResolvedPath(`/${to}`);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <SLink
      size={size}
      state={state}
      to={to}
      $underline={underline}
      $active={active || !!match}
      $disabled={disabled}
    >
      {children}
    </SLink>
  );
};

const SLink = styled(Link)<{
  size: TLinkSize;
  $underline: boolean;
  $active: boolean;
  $disabled: boolean;
}>`
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-position: from-font;
  ${({ $disabled, theme }) => {
    if ($disabled) {
      return css`
        pointer-events: none;
        color: ${theme.colors.text.GRAY};
      `;
    }
  }}
  color: ${(p) =>
    p.$active ? p.theme.colors.base.DARK : p.theme.colors.text.GRAY};

  ${({ theme, size, $underline }) => {
    const params = getLinkParamsBySize(size, theme);

    return css`
      ${params.font};
      ${!$underline &&
      css`
        text-decoration: none;
      `}
    `;
  }}

  &:hover {
    color: ${(p) => p.theme.colors.base.DARK};
  }
`;
