import { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled, { css } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { Label } from '@components/ui/Label/Label';
import { Icon } from '@components/ui/Icon/Icon';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { Tooltip } from '@components/ui/Tooltip/Tooltip';
import { ZIndex } from '@common/enums/Zindex';

export interface IDatePickerInterface extends ReactDatePickerProps {
  label?: string;
  error?: boolean | string;
  availableDates?: string[];
}

export const DatePicker: FC<IDatePickerInterface> = ({
  id,
  label,
  error,
  selected,
  availableDates,
  placeholderText = 'Select the date',
  ...props
}) => {
  const errorTooltipId = id ? `error-${id}` : undefined;

  return (
    <>
      {label && (
        <SLabelWrapper>
          <Label forId={id}>{label}</Label>
        </SLabelWrapper>
      )}
      <SDatePickerWrapper error={!!error}>
        <SReactDatePicker
          placeholderText={placeholderText}
          error={!!error}
          selected={selected || null}
          inputSize='md'
          dateFormat='dd.MM.yyyy'
          includeDates={
            availableDates?.length
              ? availableDates.map((d) => new Date(d.split('-').join('.')))
              : undefined
          }
          {...props}
        />
        {error && (
          <>
            <SErrorWrapper data-tip={true} data-for={errorTooltipId}>
              <SErrorIcon
                data-tip={true}
                data-for={errorTooltipId}
                name={IconName.ALERT}
                size='sm'
              />
            </SErrorWrapper>
            {typeof error === 'string' && errorTooltipId && (
              <Tooltip id={errorTooltipId}>{error}</Tooltip>
            )}
          </>
        )}
        <SIconWrapper>
          <SRightIcon
            error={!!error}
            {...{ name: IconName.CALENDAR, size: 'md' }}
          />
        </SIconWrapper>
      </SDatePickerWrapper>
    </>
  );
};

const SErrorIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.base.ERROR};
  height: fit-content;
  cursor: pointer;
`;
const SErrorWrapper = styled.div`
  display: flex;
  margin-right: 44px;
`;

const SRightIcon = styled(Icon)<{ error: boolean }>`
  color: ${({ theme, error }) =>
    error ? theme.colors.base.ERROR : theme.colors.text.GRAY};
  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.base.ERROR};
      margin-left: 5px;
    `}
`;

const SIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  z-index: 5;
  background: ${(p) => p.theme.colors.base.LIGHT};
`;

const SLabelWrapper = styled.div`
  margin-bottom: 4px;
`;

const SReactDatePicker = styled(ReactDatePicker)<{
  error?: boolean;
  disabled?: boolean;
  inputSize?: 'lg' | 'md';
}>`
  cursor: pointer;
  transition-property: border, color, background;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  padding: 12px 11px;
  z-index: 10;

  ${({ inputSize }) => {
    if (inputSize === 'lg') {
      return css`
        height: 52px;
      `;
    }

    return css`
      height: 48px;
    `;
  }}

  &[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  &::placeholder {
    transition-property: color;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ theme, error }) => css`
    ${theme.font.body.body};
    transition-duration: ${theme.transition};
    color: ${theme.colors.text.BODY};

    ${error &&
    css`
      color: ${theme.colors.base.ERROR};
    `};

    background: transparent;

    ${!error &&
    css`
      &:hover:not(:disabled):not(:read-only) {
        border: 1px solid ${theme.colors.base.GRAY};
      }

      &:focus:not(:disabled):not(:read-only) {
        border: 1px solid ${theme.colors.base.DARK};
      }
    `}

    &:disabled {
      color: ${theme.colors.text.MUTED};
    }
  `}
`;

const SDatePickerWrapper = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: ${(p) => p.theme.colors.base.LIGHT};
  border-radius: 4px;
  width: 100%;
  z-index: ${ZIndex.POPUP};

  ${({ theme, error }) => css`
    ${error &&
    css`
      border: 1px solid ${theme.colors.base.ERROR};
      color: ${theme.colors.base.ERROR};
    `}
  `}

  & .react-datepicker-wrapper {
    z-index: 10;
  }
`;
