import { FC } from 'react';
import styled from 'styled-components';

interface IPageWrapperProps {
  title: string;
  subtitle?: string;
}

export const PageWrapper: FC<IPageWrapperProps> = ({
  title,
  subtitle,
  children,
}) => (
  <SWrapper>
    <STitle>{title}</STitle>
    {subtitle && <SSubtitle>{subtitle}</SSubtitle>}
    {children}
  </SWrapper>
);

const SSubtitle = styled.div`
  color: ${(p) => p.theme.colors.text.GRAY};
  padding-bottom: 32px;
  border-bottom: 1px solid ${(p) => p.theme.colors.base.DARK_WHITE};
  ${(p) => p.theme.font.body.body}
`;
const STitle = styled.div`
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.text.BODY};
  ${(p) => p.theme.font.header.h2}
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7% 9%;
  width: 100%;
  z-index: 10;
  height: fit-content;
  border-radius: 8px;
  background: ${(p) => p.theme.colors.base.WHITE};
  ${(p) => p.theme.shadows.common}
`;
