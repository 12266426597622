import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { HandleThunkActionCreator } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SupportWidget } from '@components/main/SupportWidget/SupportWidget';
import { MainInfo } from '@components/main/DebtCases/DebtCaseDetailed/components/MainInfo/MainInfo';
import { DebtInfo } from '@components/main/DebtCases/DebtCaseDetailed/components/DebtInfo/DebtInfo';
import { PayHistory } from '@components/main/DebtCases/DebtCaseDetailed/components/PayHistory/PayHistory';
import { useIntersectionObserver } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/hooks/useIntersectionObserver';
import { PaymentPlan } from '@components/main/DebtCases/DebtCaseDetailed/components/PaymentPlan/PaymentPlan';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { Button } from '@components/ui/Button/Button';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { ZIndex } from '@common/enums/Zindex';
import {
  IDebtCaseDetailed,
  IPaymentPlan,
} from '@components/main/DebtCases/types/IDebtCaseDetailed';
import { debtDetailsAsyncActions } from '@pages/DebtDetails/duck/debtDetailsAsyncActions';
import { TabAnchors } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/TabAnchors';

interface IProps {
  debtItem: IDebtCaseDetailed;
  paymentPlan: IPaymentPlan[] | null;
  getQrCode: HandleThunkActionCreator<typeof debtDetailsAsyncActions.getQr>;
  getPaymentPlan: HandleThunkActionCreator<
    typeof debtDetailsAsyncActions.getPaymentPlan
  >;
  getPaymentPlanPdf: HandleThunkActionCreator<
    typeof debtDetailsAsyncActions.getPaymentPlanPdf
  >;
}

export const DebtCaseDetailed: FC<IProps> = ({
  debtItem,
  getQrCode,
  getPaymentPlan,
  getPaymentPlanPdf,
  paymentPlan,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { activeTab } = useIntersectionObserver(ref);

  const { t } = useTranslation();

  useEffect(() => {
    void getPaymentPlan(debtItem.caseData.id);
  }, [debtItem.caseData.id, getPaymentPlan]);

  return (
    <SWrapper>
      <SHeaderWrapper>
        <TabAnchors
          activeSection={activeTab}
          showHistory={!!debtItem?.paymentHistory.length}
          hasPaySchedule={!!(paymentPlan && paymentPlan.length > 0)}
        />
        {/*<Breadcrumbs crumbs={breadcrumbs} current={StatefulPage.DEBT_DETAILS} />*/}
        <LinkComponent to='/'>
          <Button
            buttonType='clickable-icon'
            icon={{ name: IconName.ARROW_LEFT, size: 'md' }}
            size='sm'
          >
            {t('buttons.getBack')}
          </Button>
        </LinkComponent>
      </SHeaderWrapper>
      <SDebtInfoWrapper ref={ref}>
        <MainInfo
          debtItem={debtItem}
          activeSection={activeTab}
          createPlanData={debtItem.ppIsAllowed}
          getQrCode={getQrCode}
          hasPaySchedule={!!(paymentPlan && paymentPlan.length > 0)}
        />
        {paymentPlan && paymentPlan.length > 0 && (
          <PaymentPlan
            paymentId={debtItem.caseData.id}
            plan={paymentPlan}
            getPdf={getPaymentPlanPdf}
          />
        )}
        <DebtInfo debtItem={debtItem} />
        {!!debtItem?.paymentHistory.length && (
          <PayHistory items={debtItem.paymentHistory} />
        )}
        <SupportWidget background={true} />
      </SDebtInfoWrapper>
    </SWrapper>
  );
};

const SHeaderWrapper = styled.div`
  display: flex;
  row-gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: ${ZIndex.HEADER};
  background-color: ${(p) => p.theme.colors.base.LIGHT};
  box-shadow: 0 20px 20px 9px ${(p) => p.theme.colors.base.LIGHT};
  padding: 2% 12% 0;
`;

const SDebtInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;
  padding: 0 12% 12.5%;

  @media (max-width: 768px) {
    padding: 0 5% 5.5%;
  }
`;

const SWrapper = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;
`;
