import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { SupportWidget } from '@components/main/SupportWidget/SupportWidget';
import { AuthForm } from '@components/main/AuthForm/AuthForm';
import Background from '@pages/Auth/img/Background.png';
import InnerBackground from '@pages/Auth/img/Littered-items.png';
import { Logo } from '@components/ui/Logo/Logo';
import { Page, StatefulPage } from '@common/enums/Page';
import { Registration } from '@pages/Auth/components/Registratiom/Registration';
import { IWithAuthState, withAuthState } from '@pages/Auth/hocs/withAuthState';
import { AuthActions } from '@pages/Auth/components/AuthActions';
import { AuthFormVariant } from '@components/main/AuthForm/enums/AuthFormVariant';
import { useRestoreError } from '@pages/Auth/hooks/useRestoreError';
import { LngSwitch } from '@components/main/Header/components/LngSwitch';
import { Footer } from '@components/main/Footer';

const CLIENT_ZONE_URL = 'https://clientzone.app/';

export const AuthComponent: FC<IWithAuthState> = ({
  logIn,
  logInGuest,
  sendCode,
  isLoading,
  verifyUser,
  openLostPassword,
  validateCode,
  isAuthorized,
  createAccount,
  serverError,
  restoreError,
  ...props
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useRestoreError(restoreError);

  const {
    onLogin,
    onLoginGuest,
    onVerify,
    onSendCode,
    onValidateCode,
    onCreateAccount,
  } = AuthActions({
    logIn,
    logInGuest,
    sendCode,
    verifyUser,
    validateCode,
    createAccount,
  });

  useEffect(() => {
    if (!isAuthorized) {
      const token = searchParams.get('auth_token') || '';

      const checkGuest = async (): Promise<void> => {
        await onLoginGuest({ access_token: token });
      };

      if (token) {
        void checkGuest();
      }
    }

    if (isAuthorized) {
      navigate(`/${StatefulPage.DEBTS}`);
    }
  }, [isAuthorized, navigate, onLoginGuest, restoreError, searchParams]);

  const { t } = useTranslation();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleNavigate = (): void => {
    window.open(CLIENT_ZONE_URL, '_blank');
  };

  return (
    <SWrapper data-testid='auth-page'>
      <SInnerWrapper>
        <SLanguageSwitchWrapper>
          <LngSwitch />
        </SLanguageSwitchWrapper>
        <SLogoWrapper>
          <Logo onClick={handleNavigate} />
        </SLogoWrapper>
        <SAuthCardWrapper>
          <Routes>
            <Route
              path='/'
              element={
                <AuthForm
                  variant={AuthFormVariant.LOGIN}
                  title={t('form.login')}
                  onLogin={onLogin}
                  onLostPassword={openLostPassword}
                  isLoading={isLoading}
                  serverError={serverError}
                />
              }
            />
            <Route
              path={Page.REGISTRATION}
              element={
                <Registration
                  onVerify={onVerify}
                  isLoading={isLoading}
                  onSendCode={onSendCode}
                  onValidateCode={onValidateCode}
                  onCreateAccount={onCreateAccount}
                  serverError={serverError}
                  {...props}
                />
              }
            />
            <Route path='*' element={<Navigate to={`/${Page.PAGE_404}`} />} />
          </Routes>
        </SAuthCardWrapper>
        <SupportWidget />
        <Footer />
      </SInnerWrapper>
    </SWrapper>
  );
};

const SAuthCardWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 496px;
`;
const SLogoWrapper = styled.div`
  position: fixed;
  top: 40px;
  color: ${(p) => p.theme.colors.base.WHITE};
`;
const SLanguageSwitchWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
`;
const SWrapper = styled.div`
  position: relative;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: 100% 50%;
  width: 100%;
  height: inherit;
`;
const SInnerWrapper = styled.div`
  position: absolute;
  height: inherit;
  background-image: url(${InnerBackground});
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-top: 125px;
`;

export const Auth = withAuthState(AuthComponent);
