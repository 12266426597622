import { useCallback, useState } from 'react';

export interface IIsOpenResult {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  close(): void;
  open(): void;
  toggle(): void;
}

export const useIsOpen = (defaultValue: boolean): IIsOpenResult => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const close = useCallback(() => setIsOpen(false), []);

  const open = useCallback(() => setIsOpen(true), []);

  const toggle = useCallback(() => setIsOpen((value) => !value), []);

  return {
    isOpen,
    setIsOpen,
    close,
    open,
    toggle,
  };
};
