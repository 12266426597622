import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IPaymentPlanDto } from '@common/api/dto/features/PaymentPlan/response/IPaymentPlanDto';

class PaymentPlan extends ApiServiceBase {
  public constructor() {
    super();
  }

  public fetch(id: number): Promise<ApiResponse<IPaymentPlanDto>> {
    return this.get(`/api/v1/cases/payment_plan/schedule/${id}`);
  }
}

export const paymentPlan = new PaymentPlan();
