import { TContactType } from '@components/main/AuthForm/types/TContactType';

export const getContactType = (contact: string): TContactType => {
  const splitContact = contact.split('');

  if (splitContact.includes('@')) {
    return 'EMAIL';
  }

  return 'PHONE';
};
