import { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/ui/Button/Button';
import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';
import { Page, StatefulPage } from '@common/enums/Page';

interface IProps {
  debtId: number;
  variant: CaseStatus;
  isPlanAllowed: boolean;
}

export const ActionButtons: FC<IProps> = ({
  debtId,
  variant,
  isPlanAllowed,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  // const isDebtDetails = location.pathname.includes(StatefulPage.DEBT_DETAILS);
  const { t } = useTranslation();

  const handleNavigate = (e: MouseEvent, route: string): void => {
    e.stopPropagation();
    navigate(route);
  };

  const activePay =
    variant === CaseStatus.PP_ACTIVE || variant === CaseStatus.PTP_ACTIVE;
  //TODO Pay by card option disabled

  switch (variant) {
    case CaseStatus.PTP_ACTIVE:
    case CaseStatus.PTP_AWAITING:
    case CaseStatus.PTP_PARTLY_REPAID:
    case CaseStatus.PP_ACTIVE:
    case CaseStatus.PP_LATE:
      return (
        <SWrapper>
          {/*<Button*/}
          {/*  onClick={(e) => handleNavigate(e, `/${Page.INSTANT_PAY}/${debtId}`)}*/}
          {/*  color={activePay ? 'primary' : 'warning'}*/}
          {/*>*/}
          {/*  {t('buttons.payCard')}*/}
          {/*</Button>*/}
          <>
            <Button
              onClick={() =>
                navigate(
                  `/${StatefulPage.DEBTS}/${StatefulPage.DEBT_DETAILS}/${debtId}`,
                  { state: location.pathname },
                )
              }
              color={activePay ? 'primary' : 'warning'}
            >
              {t('buttons.payTransfer')}
            </Button>
            {/*{isPlanAllowed && (*/}
            {/*  <Button*/}
            {/*    onClick={(e) =>*/}
            {/*      handleNavigate(e, `/${Page.CREATE_PAY}/${debtId}`)*/}
            {/*    }*/}
            {/*    color='secondary'*/}
            {/*  >*/}
            {/*    {t('buttons.createPlan')}*/}
            {/*  </Button>*/}
            {/*)}*/}
          </>
        </SWrapper>
      );
    case CaseStatus.OPEN:
    case CaseStatus.ALL:
    case CaseStatus.PENDING:
      return (
        <SWrapper>
          {/*<Button*/}
          {/*  onClick={(e) => handleNavigate(e, `/${Page.INSTANT_PAY}/${debtId}`)}*/}
          {/*  color='warning'*/}
          {/*>*/}
          {/*  {t('buttons.partialTotal')}*/}
          {/*</Button>*/}
          <Button
            onClick={() =>
              navigate(
                `/${StatefulPage.DEBTS}/${StatefulPage.DEBT_DETAILS}/${debtId}`,
                { state: location.pathname },
              )
            }
            color='primary'
          >
            {t('buttons.payTransfer')}
          </Button>
          <Button
            onClick={(e) => handleNavigate(e, `/${Page.PROMISE_PAY}/${debtId}`)}
            color='secondary'
          >
            {t('buttons.payLater')}
          </Button>
          {isPlanAllowed && (
            <Button
              onClick={(e) =>
                handleNavigate(e, `/${Page.CREATE_PAY}/${debtId}`)
              }
              color='secondary'
            >
              {t('buttons.createPlan')}
            </Button>
          )}
        </SWrapper>
      );
    default:
      return null;
  }
};

const SWrapper = styled.div`
  display: flex;
  column-gap: 30px;
  row-gap: 9px;
  flex-wrap: wrap;
`;
