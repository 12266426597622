import styled, { css } from 'styled-components';
import { FC } from 'react';

interface IProps {
  onClick?(): void;
}

export const Logo: FC<IProps> = ({ onClick }) => (
  <SWrapper onClick={onClick}>
    <span>CLIENT</span>
    <span>ZONE</span>
  </SWrapper>
);

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 47px;
  height: 46px;
  cursor: pointer;
  ${({ theme }) => css`
    ${theme.font.header.h3};
    line-height: 20px;
  `}
  transition: 0.2s all;

  &:hover {
    transform: scale(1.2);
  }
`;
