import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Header } from '@pages/CreatePayment/components/Header';
import { BodyWrapper } from '@pages/CreatePayment/components/BodyWrapper';
import {
  ICreatePaymentProps,
  Selector,
} from '@pages/CreatePayment/components/CreatePay/components/Selector/Selector';

export const CreatePay: FC<ICreatePaymentProps> = (props) => {
  const { t } = useTranslation();

  return (
    <SPayWrapper>
      <Header
        title={t('createPayment.paymentPlan.title')}
        subtitle={t('createPayment.paymentPlan.text')}
      />
      <BodyWrapper>
        <Selector {...props} />
      </BodyWrapper>
      {/*TODO: Uncomment when card payments supported */}
      {/*<Footer />*/}
    </SPayWrapper>
  );
};

export const SPayWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.base.WHITE};
  height: fit-content;
  max-width: 544px;
  padding: 7% 4%;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  row-gap: 32px;
  position: relative;
  border-radius: 8px;
  ${(p) => p.theme.shadows.common}
`;
