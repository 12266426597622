import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DebtDetailedWrapper } from '@components/main/DebtCases/DebtCaseDetailed/components/DebtDetailedWrapper';
import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';
import { IPaymentHistory } from '@components/main/DebtCases/types/IDebtCaseDetailed';

interface IPayHistoryProps {
  items: IPaymentHistory[];
}

export const PayHistory: FC<IPayHistoryProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <DebtDetailedWrapper id={AnchorTabs.PAY_HISTORY}>
      <SWrapper>
        <STitle>{t('details.paymentHistory')}</STitle>
        <STable>
          <SRow>
            <SLabelCell>{t('details.payId')}</SLabelCell>
            <SLabelCell>{t('details.date')}</SLabelCell>
            <SLabelCell>{t('details.amount')}</SLabelCell>
          </SRow>
          {items.map((el) => (
            <SRow key={el.id}>
              <SCell>{el.id}</SCell>
              <SCell>{el.date.split('-').reverse().join('.')}</SCell>
              <SCell>{el.amount} Kč</SCell>
            </SRow>
          ))}
        </STable>
      </SWrapper>
    </DebtDetailedWrapper>
  );
};

const SCell = styled.div`
  ${(p) => p.theme.font.body.body};
  color: ${(p) => p.theme.colors.text.BODY};
  width: 100px;
  text-align: left;

  &:last-child {
    text-align: right;
  }
`;
const SLabelCell = styled.div`
  ${(p) => p.theme.font.body.small};
  color: ${(p) => p.theme.colors.text.GRAY};
  width: 100px;

  &:last-child {
    text-align: right;
  }
`;
const SRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-top: 1px solid ${(p) => p.theme.colors.base.LIGHT};
  overflow-x: overlay;
`;
const STitle = styled.div`
  ${(p) => p.theme.font.header.h3};
  text-align: left;
  padding-bottom: 5%;
  border-bottom: 1px solid ${(p) => p.theme.colors.base.LIGHT};
`;

const STable = styled.div`
  min-width: 385px;
  overflow: hidden;
`;
const SWrapper = styled.div`
  overflow: overlay;
`;
