import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

import LoaderSVG from '@components/ui/Loader/img/loader.svg';
import { TLoaderSize } from '@components/ui/Loader/types/TLoaderSize';

interface IProps {
  size?: TLoaderSize;
}

export const Loader: FC<IProps> = ({ size = 'md' }) => <SLoader size={size} />;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SLoader = styled.div<{ size: string }>`
  background: url(${LoaderSVG}) no-repeat;
  ${({ size }) => {
    switch (size) {
      case 'lg':
      default:
        return css`
          width: 48px;
          height: 48px;
        `;
      case 'md':
        return css`
          width: 32px;
          height: 32px;
        `;
      case 'sm':
        return css`
          width: 20px;
          height: 20px;
        `;
    }
  }}
  animation: ${rotate} 1s linear infinite
`;
