import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { paymentAsyncActions } from '@pages/CreatePayment/duck/paymentAsyncActions';
import { IStandardError } from '@common/api/types/IStandardError';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { Feature } from '@common/enums/Feature';
import { ICreatePaymentPlanResponseDto } from '@common/api/dto/features/CreatePayment/response/ICreatePaymentPlanResponseDto';
import { ICreatePaymentPlanOptions } from '@pages/CreatePayment/types/ICreatePaymentPlanOptions';
import { ICreatePromisePayResponseDto } from '@common/api/dto/features/CreatePayment/response/ICreatePromisePayResponseDto';
import { IPromisePayOptions } from '@pages/CreatePayment/types/IPromisePayOptions';

interface IState {
  isLoading: boolean;
  serverError: string | null;
  submitSuccess: boolean;
  createPlanOptions: ICreatePaymentPlanOptions | null;
  promiseOptions: IPromisePayOptions | null;
}

export const createPaymentSlice = createSlice({
  name: Feature.CREATE_PAYMENT,
  initialState: {
    isLoading: false,
    serverError: null,
    submitSuccess: false,
    createPlanOptions: null,
    promiseOptions: null,
  } as IState,
  reducers: {
    setCreatePayState: (
      draft,
      { payload }: PayloadAction<ICreatePaymentPlanResponseDto>,
    ) => {
      draft.createPlanOptions = {
        totalOptions: payload.amount_options.map((option) => ({
          installmentsAmount: option.number_of_installments,
          monthlyPay: option.amount,
        })),
        availableDates: payload.available_dates,
      };
    },
    setPromisePayState: (
      draft,
      { payload }: PayloadAction<ICreatePromisePayResponseDto>,
    ) => {
      draft.promiseOptions = {
        minAmount: payload.min_amount,
        currentDebt: payload.current_debt,
        availableDates: payload.available_dates,
      };
    },
    restoreError: (draft) => {
      draft.serverError = null;
      draft.submitSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      paymentAsyncActions.createPromisePay.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : payload.errorPageType || 'Something went wrong, please try again';
      },
    );
    builder.addCase(
      paymentAsyncActions.createPromisePay.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      paymentAsyncActions.createPromisePay.fulfilled.type,
      (draft) => {
        draft.isLoading = false;
        draft.submitSuccess = true;
      },
    );

    builder.addCase(
      paymentAsyncActions.createPaymentPlan.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : payload.errorPageType || 'Something went wrong, please try again';
      },
    );
    builder.addCase(
      paymentAsyncActions.createPaymentPlan.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      paymentAsyncActions.createPaymentPlan.fulfilled.type,
      (draft) => {
        draft.isLoading = false;
        draft.submitSuccess = true;
      },
    );
  },
});
