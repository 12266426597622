import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { IChangePasswordRequestDto } from '@common/api/dto/features/ChangePassword/request/IChangePasswordRequestDto';

class ChangePassword extends ApiServiceBase {
  public constructor() {
    super();
  }

  public submit(
    dto: IChangePasswordRequestDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/change_password', dto);
  }
}

export const changePassword = new ChangePassword();
