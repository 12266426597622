import { FC, useState } from 'react';
import { Field } from 'react-final-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import cs from 'date-fns/locale/cs';
// import en from 'date-fns/esm/locale/en-US';
import { registerLocale } from 'react-datepicker';

import { IPropsForField } from '@app/FinalForm/types/TPropsForField';
import {
  DatePicker,
  IDatePickerInterface,
} from '@components/ui/DatePicker/DatePicker';
import { useWithTranslation } from '@app/FinalForm/hooks/useWithTranslation';

registerLocale('cs', cs);

type IProps = IPropsForField<IDatePickerInterface>;

export const FinalFormDatePicker: FC<IProps> = ({ name, ...props }) => {
  const [errorCustom, setErrorCustom] = useState(null);
  const newError = useWithTranslation(errorCustom);

  return (
    <Field name={name}>
      {({ input, meta: { error, touched, submitError } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorCustom(error);

        return (
          <DatePicker
            id={`final-form-${name}`}
            locale={window.localStorage.getItem('lng') === 'cz' ? 'cs' : 'en'}
            selected={input.value}
            onChange={(date) => input.onChange(date)}
            {...props}
            error={touched ? newError || submitError : undefined}
          />
        );
      }}
    </Field>
  );
};
