import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/order
import { BrowserTracing } from '@sentry/tracing';
// eslint-disable-next-line no-relative-imports/no-relative-imports
import './common/i18next/i18n';

import { App } from '@app/App';

document.addEventListener('DOMContentLoaded', () => {
  const ROOT = '#root';

  Sentry.init({
    dsn: 'https://c1eea6aac7f94b5388656d7b0e21d464@sentry8.volsor.com/62',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['*'],
      }),
    ],
    tracesSampleRate: 1,
    enabled: process.env.NODE_ENV !== 'development',
  });

  ReactDOM.render(<App />, document.querySelector(ROOT));
});
