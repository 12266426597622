import { Location } from 'history';

import { TPayVariant } from '@pages/CreatePayment/types/TPayVariant';
interface IResult {
  id: string;
  payVariant: TPayVariant;
}

export const getPayVariantFromUrl = (location: Location): IResult => {
  const id = location.pathname.slice(1).split('/')[1];
  const payVariant = location.pathname.slice(1).split('/')[0] as TPayVariant;

  return {
    id,
    payVariant,
  };
};
