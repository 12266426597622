import { FC } from 'react';
import styled from 'styled-components';

export const Dropdown: FC = ({ children }) => (
  <SDropdownWrapper>{children}</SDropdownWrapper>
);

const SDropdownWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.base.WHITE};
  border-radius: 4px;
  position: relative;
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  ${(p) => p.theme.shadows.common};
`;
