import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { PreLoader } from '@app/PageWrapper/components/PreLoader';

interface IRequireAuthProps {
  isAuthorized: boolean;
  checkAuth(): void;
}

export const RequireAuth: FC<IRequireAuthProps> = ({
  isAuthorized,
  checkAuth,
}) => {
  useEffect(() => {
    void checkAuth();
  }, [checkAuth, isAuthorized]);

  return isAuthorized ? <Outlet /> : <PreLoader />;
};
