import styled from 'styled-components';
import { FC } from 'react';

import { IconName } from '@components/ui/Icon/enums/IconName';
import { Button } from '@components/ui/Button/Button';
import { Icon } from '@components/ui/Icon/Icon';

interface IProps {
  title: string;
  actionTitle: string;
  onClick?(): void;
}

export const SentSuccess: FC<IProps> = ({ title, onClick, actionTitle }) => (
  <SSentWrapper>
    <STitle>{title}</STitle>
    <SIcon name={IconName.CHECKMARK} />
    <Button w100={true} size='lg' color='primary' onClick={onClick}>
      {actionTitle}
    </Button>
  </SSentWrapper>
);

const SIcon = styled(Icon)`
  width: 70px;
  height: 70px;
  margin-bottom: 24px;
  color: ${(p) => p.theme.colors.base.SUCCESS};
`;
const STitle = styled.div`
  ${(p) => p.theme.font.header.h3};
  color: ${(p) => p.theme.colors.text.BODY};
  text-align: center;
  margin-bottom: 24px;
`;
const SSentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.base.WHITE};
  padding: 10% 15%;
  border-radius: 8px;
  min-width: 300px;
  box-sizing: content-box;
`;
