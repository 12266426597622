import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';
import { ITheme } from '@app/ThemeProvider/ThemeProvider';

export const getShadowColorByVariant = (
  variant: CaseStatus,
  { colors }: ITheme,
): string | null => {
  switch (variant) {
    case CaseStatus.PP_LATE:
    case CaseStatus.PTP_PARTLY_REPAID:
    case CaseStatus.PTP_AWAITING:
    case CaseStatus.OPEN:
      return colors.base.WARNING;
    case CaseStatus.CLOSED:
    case CaseStatus.PENDING:
    default:
      return null;
  }
};
