import { generatePath } from '@common/utils/routing/generatePath';
import { pagePathRoutes } from '@common/utils/routing/pagePathRoutes';
import { TPageRouteInfo } from '@app/PageWrapper/types/TPageRouteInfo';

export const generatePageRoutePath = (
  pageRouteInfo: TPageRouteInfo,
): string | never => {
  if (!pageRouteInfo) {
    throw new TypeError(
      'There was a try to init the page by undefined route path',
    );
  }

  return generatePath(pagePathRoutes[pageRouteInfo.name], pageRouteInfo.params);
};
