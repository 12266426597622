import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { debounce, isEmpty } from 'lodash';
import { HandleThunkActionCreator } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { CreatePayDto } from '@pages/CreatePayment/components/CreatePay/components/Selector/dto/CreatePayDto';
import { FinalFormSelect } from '@app/FinalForm/components/FinalFormSelect';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { SelectorWrapper } from '@pages/CreatePayment/components/SelectorWrapper';
import { Button } from '@components/ui/Button/Button';
import { FinalFormDatePicker } from '@app/FinalForm/components/FinalFormDatePicker';
import { formatDate } from '@components/ui/Input/utils/formatDate';
import { getMonthlyPay } from '@pages/CreatePayment/components/CreatePay/utils/getMonthlyPay';
import { ICreatePaymentPlanOptions } from '@pages/CreatePayment/types/ICreatePaymentPlanOptions';
import { Loader } from '@components/ui/Loader/Loader';
import { paymentAsyncActions } from '@pages/CreatePayment/duck/paymentAsyncActions';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { StatefulPage } from '@common/enums/Page';
import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';

export interface ICreatePaymentProps {
  serverError: null | string;
  submitSuccess: boolean;
  isLoading: boolean;
  onSubmit: HandleThunkActionCreator<
    typeof paymentAsyncActions.createPaymentPlan
  >;
  options: ICreatePaymentPlanOptions | null;
  debtId: string;
}

export const Selector: FC<ICreatePaymentProps> = ({
  serverError,
  submitSuccess,
  isLoading,
  onSubmit,
  options,
  debtId,
}) => {
  const { fields, validate } = useForm(CreatePayDto);

  const { t } = useTranslation();

  const history = useNavigate();

  useEffect(() => {
    const goBack = debounce(() => history(-1), 3_000);

    if (submitSuccess) {
      goBack();
    }

    return () => {
      goBack.cancel();
    };
  }, [submitSuccess, history]);

  //TODO Handle error when there are no options
  if (!options) {
    return (
      <SLoaderWrapper>
        <Loader />
      </SLoaderWrapper>
    );
  }

  const getMonthVariant = (order: number): string => {
    if (order === 0) {
      return t('month');
    } else if (order > 0 && order < 4) {
      return t('months');
    } else {
      return t('monthsCzech');
    }
  };

  const paymentSpan = options.totalOptions.map((option, idx) =>
    getMonthlyPay(option.installmentsAmount, getMonthVariant(idx)),
  );

  return (
    <Form<CreatePayDto>
      onSubmit={(values) => {
        void onSubmit({
          case_id: Number(debtId),
          number_of_installments: Number.parseInt(values.option),
          first_installment_due_date: moment(values.date).format('YYYY-MM-DD'),
        });
      }}
      validate={validate}
      render={({ handleSubmit, submitting, submitFailed, touched, values }) => {
        const isIntervalSelected = !isEmpty(values);
        const selectedInterval = Number.parseInt(values.option) || 0;

        const max = new Date();

        max.setMonth(max.getMonth() + selectedInterval);

        const currentOption = Number(values.option?.split(' ').slice(0, 1)[0]);
        const currentPayment = options?.totalOptions.find(
          (option) => option.installmentsAmount === currentOption,
        );

        return (
          <SForm onSubmit={handleSubmit}>
            <SelectorWrapper
              title={t('form.howLongToPay')}
              additionalInfo={
                currentPayment?.monthlyPay
                  ? {
                      text: t('form.monthlyPay'),
                      value: `${currentPayment?.monthlyPay} Kč`,
                    }
                  : undefined
              }
            >
              <FinalFormSelect
                placeholder={t('form.select')}
                name={fields.option}
                options={paymentSpan}
                disabled={isLoading}
              />
            </SelectorWrapper>
            <SelectorWrapper title={t('form.firstDueDate')}>
              <FinalFormDatePicker
                id='date-picker'
                disabled={isLoading}
                name={fields.date}
                maxDate={
                  isIntervalSelected ? new Date(formatDate(max)) : undefined
                }
                availableDates={options?.availableDates}
                placeholderText={t('input.selectDate')}
              />
            </SelectorWrapper>
            <SelectorWrapper>
              {(serverError || submitSuccess) && (
                <SElementWrapper>
                  {serverError && <ActionStatus>{serverError}</ActionStatus>}
                  {submitSuccess && (
                    <ActionStatus type='success'>
                      {t('actionStatus.createPaySuccess')}
                    </ActionStatus>
                  )}
                </SElementWrapper>
              )}
            </SelectorWrapper>
            {submitSuccess && !serverError ? (
              <LinkComponent to={`/${StatefulPage.DEBTS}`}>
                <Button w100={true} size='lg'>
                  {t('buttons.backToMain')}
                </Button>
              </LinkComponent>
            ) : (
              <Button
                isLoading={isLoading}
                w100={true}
                size='lg'
                type='submit'
                color={submitFailed ? 'error' : 'primary'}
                disabled={submitting || !touched}
              >
                {t('buttons.createPlan')}
              </Button>
            )}
          </SForm>
        );
      }}
    />
  );
};

const SElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
const SLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const SForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 0;
  margin: 0;
  width: 100%;
`;
