import { DefaultTheme } from 'styled-components';

interface IButtonParams {
  background: string;
  hoverBackground?: string;
  textColor: string;
  borderColor: string;
}

export const getButtonOutlinedParams = (
  color: string,
  { colors }: DefaultTheme,
): IButtonParams => {
  switch (color) {
    case 'primary':
    default:
      return {
        background: 'transparent',
        hoverBackground: colors.base.DISABLED,
        textColor: colors.base.PRIMARY,
        borderColor: colors.base.PRIMARY,
      };
    case 'secondary':
      return {
        background: 'transparent',
        hoverBackground: colors.text.BODY,
        textColor: colors.text.GRAY,
        borderColor: colors.base.GRAY,
      };
  }
};
