export enum Page {
  AUTH = 'Auth',
  REGISTRATION = 'Registration',
  FAQ = 'FAQ',
  CONTACT_US = 'Contact-us',
  PAGE_404 = '404',
  CREATE_PAY = 'Create-pay',
  PROMISE_PAY = 'Promise-pay',
  INSTANT_PAY = 'Instant-pay',
}

export enum StatelessPage {
  AUTH = 'Auth',
}

export enum StatefulPage {
  DEBTS = 'Debts',
  PROFILE = 'Profile',
  DEBT_DETAILS = 'Details',
  CREATE_PAYMENT = 'Create-pay',
  CREATE_PAYMENT_PROMISE = 'Promise-pay',
}

export type TPages = Page | StatefulPage | StatelessPage;
