import { FC, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { Checkbox } from '@components/ui/Checkbox/Checkbox';
import { AuthCreateAccount } from '@components/main/AuthForm/dto/AuthCreateAccount';
import { Input } from '@components/ui/Input/Input';
import { ICreateAccountRequestDto } from '@common/api/dto/features/AuthUser/request/ICreateAccountRequestDto';
import { noop } from '@common/utils/functions/noop';
import { SubmitBtn } from '@components/main/AuthForm/components/CardVariants/components/SubmitBtn';
import TermsPopup from '@components/main/TermsPopup/TermsPopup';

export interface ICreateAccountProps {
  isLoading: boolean;
  userLogin?: string | null;
  onCreateAccount?(value: ICreateAccountRequestDto): void;
}

interface IProps extends ICreateAccountProps {
  serverError?: null | string;
}

export const CreateAccount: FC<IProps> = ({
  isLoading,
  userLogin,
  serverError,
  onCreateAccount = noop,
}) => {
  const { validate, fields } = useForm(AuthCreateAccount);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <TermsPopup isOpen={showPopup} toggle={() => setShowPopup(!showPopup)} />
      <Form<AuthCreateAccount>
        onSubmit={(values) =>
          onCreateAccount({
            password: values.password,
            password_confirmation: values.passwordConfirmation,
          })
        }
        validate={validate}
        render={({ handleSubmit, submitting, touched, submitFailed }) => (
          <SForm onSubmit={handleSubmit}>
            <SContent>
              <SElementWrapper>
                <Input
                  label={t('form.login')}
                  value={userLogin || 'undefined'}
                  type='text'
                  disabled={true}
                />
              </SElementWrapper>
              <SElementWrapper>
                <FinalFormInput
                  id='password'
                  label={t('form.password')}
                  name={fields.password}
                  disabled={isLoading}
                  variant='password'
                  placeholder={t('placeholder.typePassword')}
                />
              </SElementWrapper>
              <SElementWrapper>
                <FinalFormInput
                  id='password-again'
                  label={t('form.passwordAgain')}
                  name={fields.passwordConfirmation}
                  disabled={isLoading}
                  variant='password'
                  placeholder={t('placeholder.typePasswordAgain')}
                />
              </SElementWrapper>
              <SElementWrapper>
                {serverError && <ActionStatus>{serverError}</ActionStatus>}
              </SElementWrapper>
            </SContent>
            <SCheckboxWrapper>
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <span>
                <SClickable onClick={() => setShowPopup(!showPopup)}>
                  {t('agree')} {t('personalData')}
                  {t('and')} {t('businessTerms')}
                </SClickable>
              </span>
            </SCheckboxWrapper>
            <SubmitBtn
              error={submitFailed}
              disabled={!touched || submitting || !checked || isLoading}
              isLoading={isLoading}
            >
              {t('buttons.createAcc')}
            </SubmitBtn>
          </SForm>
        )}
      />
    </>
  );
};

const SClickable = styled.u`
  cursor: pointer;
`;

const SCheckboxWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 22px;
`;
const SContent = styled.div`
  margin-bottom: 28px;
`;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
const SElementWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
