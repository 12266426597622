import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HandleThunkActionCreator } from 'react-redux';
import FileDownload from 'js-file-download';

import { DebtDetailedWrapper } from '@components/main/DebtCases/DebtCaseDetailed/components/DebtDetailedWrapper';
import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';
import { Table } from '@components/main/DebtCases/DebtCaseDetailed/components/PaymentPlan/Table/Table';
import { IPaymentPlan } from '@components/main/DebtCases/types/IDebtCaseDetailed';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { Button } from '@components/ui/Button/Button';
import { debtDetailsAsyncActions } from '@pages/DebtDetails/duck/debtDetailsAsyncActions';

interface IProps {
  paymentId: number;
  plan: IPaymentPlan[];
  getPdf: HandleThunkActionCreator<
    typeof debtDetailsAsyncActions.getPaymentPlanPdf
  >;
}

export const PaymentPlan: FC<IProps> = ({ paymentId, plan, getPdf }) => {
  const { t } = useTranslation();

  const download = async (): Promise<void> => {
    const { payload } = await getPdf(paymentId);

    FileDownload(payload as Blob, 'payment-plan.pdf');
  };

  return (
    <DebtDetailedWrapper id={AnchorTabs.PAY_SCHEDULE}>
      <SHeader>
        <STitle>{t('details.paymentPlanSchedule')}</STitle>
        <Button
          buttonType='clickable-icon'
          icon={{ name: IconName.DOWNLOAD }}
          onClick={download}
        />
      </SHeader>
      <SPlanWrapper>
        <Table data={plan} />
      </SPlanWrapper>
    </DebtDetailedWrapper>
  );
};

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const SPlanWrapper = styled.div`
  min-width: 400px;
`;
const STitle = styled.div`
  ${(p) => p.theme.font.header.h3};
`;
