import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PopupView } from '@app/PopupView/PopupView';
import {
  IWithLostPasswordState,
  withLostPasswordState,
} from '@components/main/LostPassword/hocs/withLostPasswordState';
import { SentSuccess } from '@components/main/LostPassword/components/SentSuccess';
import { RestoreContact } from '@components/main/LostPassword/components/RestoreContact';
import { CodeVerify } from '@components/main/LostPassword/components/CodeVerify';
import { LostPasswordVariant } from '@components/main/LostPassword/enum/LostPasswordVariant';
import { PasswordSet } from '@components/main/LostPassword/components/PasswordSet';

export const LostPasswordComponent: FC<IWithLostPasswordState> = ({
  isLoading,
  isOpen = false,
  toggle,
  currentVariant,
  requestCode,
  verifyCode,
  changePassword,
  serverError,
}) => {
  const { t } = useTranslation();

  const [login, setLogin] = useState('');
  const [code, setCode] = useState('');

  return (
    <PopupView
      isOpen={isOpen}
      close={() => {
        toggle();
      }}
    >
      {currentVariant === LostPasswordVariant.RESTORE_CONTACT && (
        <RestoreContact
          serverError={serverError}
          onSubmit={requestCode}
          onSetLogin={setLogin}
          isLoading={isLoading}
        />
      )}
      {currentVariant === LostPasswordVariant.CODE_VERIFY && (
        <CodeVerify
          onSubmit={verifyCode}
          onResend={requestCode}
          login={login}
          onSetCode={setCode}
          serverError={serverError}
          isLoading={isLoading}
        />
      )}
      {currentVariant === LostPasswordVariant.PASSWORD_SET && (
        <PasswordSet
          isLoading={isLoading}
          login={login}
          code={code}
          onSubmit={changePassword}
          serverError={serverError}
        />
      )}
      {currentVariant === LostPasswordVariant.SUCCESS && (
        <SentSuccess
          title={t('lostPassword.successTitle')}
          actionTitle={t('lostPassword.getBack')}
          onClick={toggle}
        />
      )}
    </PopupView>
  );
};

export const LostPassword = withLostPasswordState(LostPasswordComponent);
