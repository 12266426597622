import { FC } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useForm } from '@app/FinalForm/hooks/useForm';
import { SelectorWrapper } from '@pages/CreatePayment/components/SelectorWrapper';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { Button } from '@components/ui/Button/Button';
import { InstantPayDto } from '@pages/CreatePayment/components/InstantPay/dto/InstantPayDto';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { Page, StatefulPage } from '@common/enums/Page';

interface IProps {
  debtId: string;
}
//TODO Hide "Pay by card" option
export const Selector: FC<IProps> = ({ debtId }) => {
  const { fields, validate } = useForm(InstantPayDto);
  const { t } = useTranslation();

  return (
    <Form<InstantPayDto>
      onSubmit={() => console.log('submitted')}
      validate={validate}
      render={({ handleSubmit, submitting, submitFailed, touched }) => (
        <SForm onSubmit={handleSubmit}>
          <SelectorWrapper
            title={t('form.putAmount')}
            additionalInfo={{
              text: t('form.totalDebtAmount'),
              value: '5 711 Kč',
            }}
          >
            <FinalFormInput
              id='amount'
              name={fields.amount}
              type='number'
              placeholder={t('form.putAmount')}
            />
          </SelectorWrapper>
          {/*<Button*/}
          {/*  w100={true}*/}
          {/*  size='lg'*/}
          {/*  type='submit'*/}
          {/*  color={submitFailed ? 'error' : 'primary'}*/}
          {/*  disabled={submitting || !touched}*/}
          {/*>*/}
          {/*  {t('buttons.payCard')}*/}
          {/*</Button>*/}
          <LinkComponent
            to={`/${StatefulPage.DEBTS}/${StatefulPage.DEBT_DETAILS}/${debtId}`}
            state={{ prevPage: Page.CREATE_PAY }}
          >
            <Button color='secondary' underline={true} w100={true}>
              {t('buttons.payTransaction')}
            </Button>
          </LinkComponent>
        </SForm>
      )}
    />
  );
};

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 0;
  margin: 0;
  width: 100%;
`;
