import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend'
import XHR from "i18next-http-backend"

const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
    apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
    // refLng: process.env.REACT_APP_LOCIZE_REFLNG,
    version: process.env.REACT_APP_LOCIZE_VERSION
}

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        lng: window.localStorage.getItem('lng') || 'en',
        ns: ['prod'],
        defaultNS: 'prod',
        fallbackNS: 'ns',
        supportedLngs: ['en','cz'],
        debug: true,
        fallbackLng: 'cz',
        interpolation: {
            escapeValue: false,
        },
        backend: locizeOptions,
        detection: {order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        },
        saveMissing: false
    });

i18n.on('initialized', async () => {
  if (window.localStorage.getItem('lng')) {
    return;
  }

  const curLang = navigator.language

  if (curLang.includes('en')) {
   await i18n.changeLanguage('en')
    window.localStorage.setItem('lng', 'en')
  }
  if (curLang === 'cs') {
    await i18n.changeLanguage('cz')
    window.localStorage.setItem('lng', 'cz')
  }
});

export default i18n;
