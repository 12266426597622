import {
  registerDecorator,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { validate } from 'czech-personal-number';

import { AuthVerification } from '@components/main/AuthForm/dto/AuthVerification';

export const ValidateSecurityNumber =
  (property: string, validationOptions?: ValidationOptions) =>
  (object: AuthVerification, propertyName: string): void => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [property],
      validator: ValidateSecurityNumberConstraint,
    });
  };

@ValidatorConstraint({ name: 'ValidateSecurityNumber' })
export class ValidateSecurityNumberConstraint
  implements ValidatorConstraintInterface
{
  validate(value: string): boolean {
    return validate(value).isValid;
  }
}
