import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LostPasswordVariant } from '@components/main/LostPassword/enum/LostPasswordVariant';
import { lostPasswordAsyncActions } from '@components/main/LostPassword/duck/lostPasswordAsyncActions';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { IStandardError } from '@common/api/types/IStandardError';

interface IState {
  isLoading: boolean;
  isLostPasswordOpen: boolean;
  currentVariant: LostPasswordVariant;
  serverError: string | null;
}

export const lostPasswordSlice = createSlice({
  name: 'lost-password',
  initialState: {
    isLoading: false,
    currentVariant: LostPasswordVariant.RESTORE_CONTACT,
    isLostPasswordOpen: false,
    serverError: null,
  } as IState,
  reducers: {
    restoreError: (draft) => {
      draft.serverError = null;
    },
    toggleLostPassword: (draft) => {
      draft.isLostPasswordOpen = !draft.isLostPasswordOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      lostPasswordAsyncActions.requestCode.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.requestCode.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError =
          payload.response?.data.detail || 'Something went wrong, try again';
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.requestCode.fulfilled.type,
      (draft) => {
        draft.serverError = null;
        draft.isLoading = false;

        draft.currentVariant = LostPasswordVariant.CODE_VERIFY;
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.verifyCode.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.verifyCode.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError =
          payload.response?.data.detail || 'Something went wrong, try again';
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.verifyCode.fulfilled.type,
      (draft) => {
        draft.serverError = null;
        draft.isLoading = false;

        draft.currentVariant = LostPasswordVariant.PASSWORD_SET;
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.changePassword.pending.type,
      (draft) => {
        draft.isLoading = true;
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.changePassword.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError =
          payload.response?.data.detail || 'Something went wrong, try again';
      },
    );
    builder.addCase(
      lostPasswordAsyncActions.changePassword.fulfilled.type,
      (draft) => {
        draft.serverError = null;
        draft.isLoading = false;

        draft.currentVariant = LostPasswordVariant.SUCCESS;
      },
    );
  },
});
