import { useEffect, useState } from 'react';

export const usePortal = (): Element | null => {
  const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(
    null,
  );

  useEffect(() => {
    const element = document.createElement('div');

    document.body.append(element);

    setPortalElement(element);

    return () => {
      setPortalElement(null);
      element.remove();
    };
  }, []);

  return portalElement;
};
