import { useCallback, useEffect, useState } from 'react';

import { TPositionType } from '@app/DropdownView/types/TPositionType';
import { IDropdownPositionParams } from '@app/DropdownView/types/IDropdownPositionParams';

const getDefaultPosition = (
  { left, right, top, bottom }: DOMRect,
  { offsetWidth, offsetHeight }: HTMLElement,
): TPositionType => {
  const isRightFits = offsetWidth + left <= window.innerWidth;
  const isLeftFirs = right - offsetWidth >= 0;

  const isBottomFits = offsetHeight + bottom + 8 <= window.innerHeight;
  const isTopFits = top - offsetHeight - 8 >= 0;

  if (isRightFits && isBottomFits) {
    return 'bottom-right';
  }

  if (isLeftFirs && isBottomFits) {
    return 'bottom-left';
  }

  if (isRightFits && isTopFits) {
    return 'top-right';
  }

  if (isLeftFirs && isTopFits) {
    return 'top-left';
  }

  const isBottomMoreRight = window.innerHeight - bottom >= top;

  if (isBottomMoreRight && isRightFits) {
    return 'bottom-right-max-height';
  }
  if (isBottomMoreRight && isLeftFirs) {
    return 'bottom-left-max-height';
  }
  if (!isBottomMoreRight && isRightFits) {
    return 'top-right-max-height';
  }
  if (!isBottomMoreRight && isLeftFirs) {
    return 'top-left-max-height';
  }

  return 'bottom-right-max-height';
};

const setDefaultPosition = (
  positionType: TPositionType,
  contentElementRef: HTMLElement,
  switcherRect: DOMRect,
): void => {
  switch (positionType) {
    case 'bottom-left':
    case 'bottom-right':
    case 'bottom-left-max-height':
    case 'bottom-right-max-height':
    default:
      contentElementRef.style.top = `${switcherRect.bottom + 8}px`;

      break;
    case 'top-left':
    case 'top-right':
    case 'top-left-max-height':
    case 'top-right-max-height':
      contentElementRef.style.bottom = `${
        window.innerHeight - switcherRect.top + 8
      }px`;

      break;
  }

  switch (positionType) {
    case 'bottom-left-max-height':
    case 'bottom-right-max-height':
      contentElementRef.style.height = `${
        window.innerHeight - switcherRect.bottom - 8
      }px`;
      break;

    case 'top-left-max-height':
    case 'top-right-max-height':
      contentElementRef.style.height = `${switcherRect.top - 8}px`;

      break;
    default:
  }
};

const setPosition = (
  contentElementRef: HTMLElement,
  switcherElement: HTMLElement,
  isSwitcherWidth: boolean,
): TPositionType => {
  const switcherRect = switcherElement.getBoundingClientRect();

  if (isSwitcherWidth) {
    contentElementRef.style.width = `${switcherRect.width}px`;
  }

  const positionType = getDefaultPosition(switcherRect, contentElementRef);

  switch (positionType) {
    case 'bottom-right':
    case 'top-right':
    case 'bottom-right-max-height':
    default:
      contentElementRef.style.left = `${switcherRect.left}px`;
      break;
    case 'bottom-left':
    case 'top-left':
    case 'bottom-left-max-height':
      contentElementRef.style.right = `${
        window.innerWidth - switcherRect.left - switcherElement.offsetWidth
      }px`;
      break;
  }

  setDefaultPosition(positionType, contentElementRef, switcherRect);

  return positionType;
};

export const useFixedPosition = ({
  isOpen,
  switcherRef,
  contentRef,
  isSwitcherWidth,
}: IDropdownPositionParams): {
  isPositionPrepared: boolean;
  positionType: TPositionType | null;
} => {
  const [isPositionPrepared, setIsPositionPrepared] = useState(false);
  const [positionType, setPositionType] = useState<TPositionType | null>(null);

  const changePosition = useCallback(
    (e?: Event): void => {
      if (!switcherRef.current || !contentRef.current || !isOpen) {
        return;
      }

      if (e?.target instanceof Node && contentRef.current?.contains(e.target)) {
        return;
      }

      contentRef.current.style.display = 'block';
      contentRef.current.style.top = '';
      contentRef.current.style.bottom = '';
      contentRef.current.style.left = '';
      contentRef.current.style.right = '';
      contentRef.current.style.height = '';

      setPositionType(
        setPosition(contentRef.current, switcherRef.current, isSwitcherWidth),
      );

      contentRef.current.style.display = '';

      setIsPositionPrepared(true);
    },
    [switcherRef, contentRef, isOpen, isSwitcherWidth],
  );

  useEffect(() => {
    if (!isOpen) {
      setIsPositionPrepared(false);
    }
  }, [isOpen, contentRef]);

  useEffect(() => {
    changePosition();

    document.addEventListener('scroll', changePosition, true);
    window.addEventListener('resize', changePosition);

    return () => {
      document.removeEventListener('scroll', changePosition, true);
      window.addEventListener('resize', changePosition);
    };
  }, [changePosition]);

  return {
    isPositionPrepared,
    positionType,
  };
};
