import { IconName } from '@components/ui/Icon/enums/IconName';
import { TSvgrComponent } from '@common/types/TSvgrComponent';
import { ReactComponent as User } from '@components/ui/Icon/img/user.svg';
import { ReactComponent as ArrowUp } from '@components/ui/Icon/img/arrow-up.svg';
import { ReactComponent as ArrowDown } from '@components/ui/Icon/img/arrow-down.svg';
import { ReactComponent as ArrowLeft } from '@components/ui/Icon/img/arrow-left.svg';
import { ReactComponent as Calendar } from '@components/ui/Icon/img/calendar.svg';
import { ReactComponent as Checkmark } from '@components/ui/Icon/img/checkmark.svg';
import { ReactComponent as OpenedEye } from '@components/ui/Icon/img/opened-eye.svg';
import { ReactComponent as ClosedEye } from '@components/ui/Icon/img/closed-eye.svg';
import { ReactComponent as CurrencyKc } from '@components/ui/Icon/img/currency-kc.svg';
import { ReactComponent as Close } from '@components/ui/Icon/img/close.svg';
import { ReactComponent as ProblemSolving } from '@components/ui/Icon/img/problem-solving.svg';
import { ReactComponent as Help } from '@components/ui/Icon/img/help.svg';
import { ReactComponent as Refresh } from '@components/ui/Icon/img/refresh.svg';
import { ReactComponent as Promise } from '@components/ui/Icon/img/promise.svg';
import { ReactComponent as Alert } from '@components/ui/Icon/img/alert.svg';
import { ReactComponent as Eng } from '@components/ui/Icon/img/eng.svg';
import { ReactComponent as Cz } from '@components/ui/Icon/img/cz.svg';
import { ReactComponent as Download } from '@components/ui/Icon/img/download.svg';

const icons: { [key in IconName]: TSvgrComponent } = {
  [IconName.USER]: User,
  [IconName.ARROW_UP]: ArrowUp,
  [IconName.ARROW_DOWN]: ArrowDown,
  [IconName.ARROW_LEFT]: ArrowLeft,
  [IconName.CALENDAR]: Calendar,
  [IconName.CHECKMARK]: Checkmark,
  [IconName.CURRENCY_KC]: CurrencyKc,
  [IconName.CLOSE]: Close,
  [IconName.OPENED_EYE]: OpenedEye,
  [IconName.CLOSED_EYE]: ClosedEye,
  [IconName.PROBLEM_SOLVING]: ProblemSolving,
  [IconName.HELP]: Help,
  [IconName.REFRESH]: Refresh,
  [IconName.PROMISE]: Promise,
  [IconName.ALERT]: Alert,
  [IconName.ALERT]: Alert,
  [IconName.ENG]: Eng,
  [IconName.CZ]: Cz,
  [IconName.DOWNLOAD]: Download,
};

export const getIconByName = (name: IconName): TSvgrComponent => icons[name];
