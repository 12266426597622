import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStandardError } from '@common/api/types/IStandardError';
import { AuthFormVariant } from '@components/main/AuthForm/enums/AuthFormVariant';
import {
  IContact,
  IVerifyUserResponseDto,
} from '@common/api/dto/features/AuthUser/response/IVerifyUserResponseDto';
import { TAuthStep } from '@pages/Auth/types/TAuthStep';
import { authAsyncActions } from '@pages/Auth/duck/authAsyncActions';
import { ICodeValidationResponseDto } from '@common/api/dto/features/AuthUser/response/ICodeValidationResponseDto';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { ICheckAuthResponseDto } from '@common/api/dto/features/AuthUser/response/ICheckAuthResponseDto';
import { StatelessPage } from '@common/enums/Page';

interface IState {
  isLoading: boolean;
  isAuthorized: boolean;
  authStep: TAuthStep;
  verifyOptions: IContact[] | null;
  userLogin: string | null;
  serverError?: string | null;
}

export const authSlice = createSlice({
  name: StatelessPage.AUTH,
  initialState: {
    isLoading: false,
    isAuthorized: false,
    authStep: AuthFormVariant.VERIFICATION,

    verifyOptions: null,
    userLogin: null,

    serverError: null,
  } as IState,
  reducers: {
    restoreError: (draft) => {
      draft.serverError = null;
    },
    stepBack: (draft, { payload }: PayloadAction<TAuthStep>) => {
      if (payload === AuthFormVariant.VERIFICATION_OPTION) {
        draft.authStep = AuthFormVariant.VERIFICATION;
      }
      if (payload === AuthFormVariant.CODE_VALIDATION) {
        draft.authStep = AuthFormVariant.VERIFICATION_OPTION;
      }
      if (payload === AuthFormVariant.CREATE_ACCOUNT) {
        draft.authStep = AuthFormVariant.VERIFICATION;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      authAsyncActions.logIn.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : 'Something went wrong, please try again';
      },
    );
    builder.addCase(authAsyncActions.logIn.pending.type, (draft) => {
      draft.isLoading = true;
      draft.serverError = null;
    });
    builder.addCase(authAsyncActions.logIn.fulfilled.type, (draft) => {
      draft.isAuthorized = true;
      draft.isLoading = false;
    });

    builder.addCase(authAsyncActions.logInGuest.pending.type, (draft) => {
      draft.isLoading = true;
      draft.serverError = null;
    });
    builder.addCase(authAsyncActions.logInGuest.fulfilled.type, (draft) => {
      draft.isAuthorized = true;
      draft.isLoading = false;
    });

    builder.addCase(
      authAsyncActions.checkAuth.fulfilled.type,
      (draft, action: PayloadAction<ICheckAuthResponseDto>) => {
        draft.isAuthorized = action.payload.confirmed;
      },
    );
    builder.addCase(authAsyncActions.checkAuth.rejected.type, (draft) => {
      draft.isAuthorized = false;
    });

    builder.addCase(authAsyncActions.verifyUser.pending.type, (draft) => {
      draft.isLoading = true;
      draft.serverError = null;
    });
    builder.addCase(
      authAsyncActions.verifyUser.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : 'Something went wrong, please try again';
      },
    );
    builder.addCase(
      authAsyncActions.verifyUser.fulfilled.type,
      (draft, action: PayloadAction<IVerifyUserResponseDto>) => {
        draft.isLoading = false;

        draft.serverError = null;

        draft.verifyOptions = [
          ...(action.payload.phones || []),
          ...(action.payload.emails || []),
        ];
        draft.userLogin = action.payload.login || null;

        draft.authStep = action.payload.is_verified
          ? AuthFormVariant.CREATE_ACCOUNT
          : AuthFormVariant.VERIFICATION_OPTION;
      },
    );

    builder.addCase(authAsyncActions.sendCode.pending.type, (draft) => {
      draft.isLoading = true;
    });
    builder.addCase(
      authAsyncActions.sendCode.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : 'Something went wrong, please try again';
      },
    );

    builder.addCase(authAsyncActions.sendCode.fulfilled.type, (draft) => {
      draft.authStep = AuthFormVariant.CODE_VALIDATION;
      draft.serverError = null;
      draft.isLoading = false;
    });

    builder.addCase(
      authAsyncActions.validateCode.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : 'Something went wrong, please try again';
      },
    );

    builder.addCase(authAsyncActions.validateCode.pending.type, (draft) => {
      draft.serverError = null;
      draft.isLoading = true;
    });

    builder.addCase(
      authAsyncActions.validateCode.fulfilled.type,
      (draft, action: PayloadAction<ICodeValidationResponseDto>) => {
        draft.authStep = AuthFormVariant.CREATE_ACCOUNT;
        draft.serverError = null;
        draft.isLoading = false;

        draft.userLogin = action.payload.login || null;
      },
    );

    builder.addCase(authAsyncActions.createAccount.pending.type, (draft) => {
      draft.isLoading = true;
    });

    builder.addCase(
      authAsyncActions.createAccount.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? payload.response.data.detail
          : 'Something went wrong, please try again';
      },
    );
    builder.addCase(authAsyncActions.createAccount.fulfilled.type, (draft) => {
      draft.isLoading = false;
      draft.isAuthorized = true;
    });
  },
});
