export enum IconName {
  USER = 'user',
  PROMISE = 'promise',
  CALENDAR = 'calendar',
  CHECKMARK = 'checkmark',
  CLOSED_EYE = 'closed-eye',
  OPENED_EYE = 'opened-eye',
  CLOSE = 'close',
  ALERT = 'alert',
  HELP = 'help',
  PROBLEM_SOLVING = 'problem_solving',
  ARROW_UP = 'arrow-up',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  CURRENCY_KC = 'currency-kc',
  REFRESH = 'refresh',
  ENG = 'eng',
  CZ = 'cz',
  DOWNLOAD = 'download',
}
