import { FC } from 'react';
import styled from 'styled-components';

export const Separator: FC = () => <SBase />;

const SBase = styled.div`
  width: 32px;
  height: 3px;
  background: ${(p) => p.theme.colors.base.PRIMARY};
  ${(p) => p.theme.shadows.common};
  border-radius: 4px;
`;
