export enum BaseColor {
  PRIMARY = '#1A82E2',
  PRIMARY_HOVERED = '#0167cb',
  SECONDARY = '#252729',
  PRIMARY_LIGHT = '#EFF7FF',
  SUCCESS = '#39BA55',
  SUCCESS_LIGHT = '#EFFFF3',
  GREEN = '#82BC00',
  ERROR = '#E83131',
  ERROR_HOVERED = '#c70303',
  WARNING = '#F1821C',
  WARNING_HOVERED = '#cb6705',
  WARNING_LIGHT = '#FFF7F0',
  INFO = '#47B8D1',
  DARK = '#212225',
  GRAY = '#797C86',
  LIGHT_GRAY = '#BAC0C5',
  LIGHT = '#F2F5F6',
  WHITE = '#FFFFFF',
  DARK_WHITE = '#EFF1F3',
  DISABLED = '#9ECDF7',
}
