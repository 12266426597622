import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import {
  isLostPasswordLoadingOpenSelector,
  isLostPasswordOpenSelector,
  isLostPasswordServerErrorSelector,
  lostPasswordVariantSelector,
} from '@components/main/LostPassword/duck/selectors';
import { lostPasswordSlice } from '@components/main/LostPassword/duck/slice';
import { lostPasswordAsyncActions } from '@components/main/LostPassword/duck/lostPasswordAsyncActions';

export const withLostPasswordState = connect(
  (state: IRootState) => ({
    isLoading: isLostPasswordLoadingOpenSelector(state),
    serverError: isLostPasswordServerErrorSelector(state),
    isOpen: isLostPasswordOpenSelector(state),
    currentVariant: lostPasswordVariantSelector(state),
  }),
  {
    restoreError: lostPasswordSlice.actions.restoreError,
    toggle: lostPasswordSlice.actions.toggleLostPassword,
    ...lostPasswordAsyncActions,
  },
);

export type IWithLostPasswordState = ConnectedProps<
  typeof withLostPasswordState
>;
