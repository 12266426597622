import { FC, ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled, { css } from 'styled-components';
import { createPortal } from 'react-dom';

import { IIsOpenResult } from '@common/hooks/useIsOpen';
import { ZIndex } from '@common/enums/Zindex';
import { noop } from '@common/utils/functions/noop';
import { TPositionType } from '@app/DropdownView/types/TPositionType';
import { useIsOpenDropdown } from '@app/DropdownView/hooks/useIsOpenDropdown';
import { useDropdownPosition } from '@app/DropdownView/hooks/useDropdownPosition';
import { usePortal } from '@common/utils/hooks/usePortal';

interface IChildrenDropdownParams {
  close(shallow?: boolean): void;
  isOpen: boolean;
  positionType: TPositionType | null;
}

interface IDropdownViewProps {
  isEqualWidth?: boolean;
  w100?: boolean;
  switcher: ((params: IIsOpenResult) => ReactNode) | ReactNode;
  children: ((params: IChildrenDropdownParams) => ReactNode) | ReactNode;
  onClose?(): void;
  onOpen?(): void;
  isOpen?: boolean;
  zIndex?: number;
}

export const DropdownView: FC<IDropdownViewProps> = ({
  switcher,
  children,
  onClose = noop,
  onOpen = noop,
  isOpen: isOpenExternal,
  zIndex = ZIndex.DROPDOWN,
  isEqualWidth = false,
  w100 = false,
}) => {
  const isOpenParams = useIsOpenDropdown({
    isOpen: isOpenExternal,
    onOpen,
    onClose,
  });

  const switcherRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const { isPositionPrepared, positionType } = useDropdownPosition({
    isOpen: isOpenParams.isOpen,
    switcherRef,
    contentRef,
    isSwitcherWidth: isEqualWidth,
  });

  const portalElement = usePortal();

  useClickAway(contentRef, (event) => {
    if (
      switcherRef.current?.contains(event.target as Node | null) ||
      contentRef.current?.contains(event.target as Node | null)
    ) {
      return;
    }

    isOpenParams.close();
  });

  const viewContent = isOpenParams.isOpen ? (
    <SContent
      zIndex={zIndex}
      ref={contentRef}
      isPositionPrepared={isPositionPrepared}
      onClick={(e) => e.stopPropagation()}
    >
      {children instanceof Function
        ? children({
            close: isOpenParams.close,
            isOpen: isOpenParams.isOpen,
            positionType,
          })
        : children}
    </SContent>
  ) : null;

  return (
    <SDropdownView w100={w100}>
      <SSwitcher ref={switcherRef}>
        {switcher instanceof Function ? switcher(isOpenParams) : switcher}
      </SSwitcher>
      {portalElement ? createPortal(viewContent, portalElement) : viewContent}
    </SDropdownView>
  );
};

const SSwitcher = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100%;
`;

const SContent = styled.div<{ isPositionPrepared: boolean; zIndex: number }>`
  position: fixed;
  z-index: ${(p) => p.zIndex};
  width: max-content;
  visibility: ${(p) => (p.isPositionPrepared ? 'visible' : 'hidden')};
`;
const SDropdownView = styled.div<{ w100: boolean }>`
  position: relative;
  display: inline-flex;

  ${(p) =>
    p.w100 &&
    css`
      width: 100%;
      overflow: hidden;
    `}
`;
