import { FC } from 'react';
import styled from 'styled-components';

import { IconName } from '@components/ui/Icon/enums/IconName';
import { Tooltip } from '@components/ui/Tooltip/Tooltip';
import { Icon } from '@components/ui/Icon/Icon';

interface IProps {
  error: string | boolean;
  id?: string;
}

export const ErrorWrapper: FC<IProps> = ({ error, id }) => {
  const errorTooltipId = id ? `error-${id}` : undefined;

  return (
    <SWrapper data-tip={true} data-for={errorTooltipId}>
      <SDescriptionIcon name={IconName.ALERT} size='sm' />
      {typeof error === 'string' && errorTooltipId && (
        <Tooltip id={errorTooltipId}>{error}</Tooltip>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SDescriptionIcon = styled(Icon)`
  margin-right: 16px;
  color: ${(p) => p.theme.colors.base.ERROR};
`;
