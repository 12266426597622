import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { IChangePasswordRequestDto } from '@common/api/dto/features/ChangePassword/request/IChangePasswordRequestDto';
import { changePassword } from '@common/api/services/ChangePassword/ChangePassword';

export const profileAsyncActions = {
  editPassword: createAsyncThunk(
    `${Feature.PROFILE}/changePassword`,
    async (dto: IChangePasswordRequestDto, { rejectWithValue }) => {
      try {
        await changePassword.submit(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
};
