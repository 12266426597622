import { useLocation, useNavigate } from 'react-router-dom';
import { HandleThunkActionCreator } from 'react-redux';

import {
  ILoginGuestRequestDto,
  ILoginRequestDto,
} from '@common/api/dto/features/AuthUser/request/ILoginRequestDto';
import { IVerifyUserRequestDto } from '@common/api/dto/features/AuthUser/request/IVerifyUserRequestDto';
import { TLocationStateProps } from '@components/main/AuthForm/types/TLocationStateProps';
import { authAsyncActions } from '@pages/Auth/duck/authAsyncActions';
import { ICodeSubmitRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeSubmitRequestDto';
import { ICodeValidationRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeValidationRequestDto';
import { ICreateAccountRequestDto } from '@common/api/dto/features/AuthUser/request/ICreateAccountRequestDto';

interface IProps {
  logIn: HandleThunkActionCreator<typeof authAsyncActions.logIn>;
  logInGuest: HandleThunkActionCreator<typeof authAsyncActions.logInGuest>;
  sendCode: HandleThunkActionCreator<typeof authAsyncActions.sendCode>;
  verifyUser: HandleThunkActionCreator<typeof authAsyncActions.verifyUser>;
  validateCode: HandleThunkActionCreator<typeof authAsyncActions.validateCode>;
  createAccount: HandleThunkActionCreator<
    typeof authAsyncActions.createAccount
  >;
}

interface IResult {
  onLogin(value: ILoginRequestDto): Promise<void>;
  onLoginGuest(value: ILoginGuestRequestDto): Promise<void>;
  onVerify(value: IVerifyUserRequestDto): Promise<void>;
  onSendCode(value: ICodeSubmitRequestDto): Promise<void>;
  onValidateCode(value: ICodeValidationRequestDto): Promise<void>;
  onCreateAccount(value: ICreateAccountRequestDto): Promise<void>;
}

export const AuthActions = ({
  logIn,
  logInGuest,
  sendCode,
  verifyUser,
  validateCode,
  createAccount,
}: IProps): IResult => {
  const navigate = useNavigate();
  const location = useLocation();
  const myState = location.state as TLocationStateProps;
  const from = !!myState && myState.from.pathname;

  const onLogin = async ({
    login,
    password,
  }: ILoginRequestDto): Promise<void> => {
    await logIn({ login, password });

    if (from) {
      navigate(from, { replace: false });
    }
  };

  const onLoginGuest = async ({
    access_token,
  }: ILoginGuestRequestDto): Promise<void> => {
    await logInGuest({ access_token });
  };

  const onVerify = async ({
    first_name,
    last_name,
    personal_number,
  }: IVerifyUserRequestDto): Promise<void> => {
    await verifyUser({
      first_name,
      last_name,
      personal_number,
    });
  };

  const onSendCode = async ({
    contact,
    contact_id,
    contact_type,
  }: ICodeSubmitRequestDto): Promise<void> => {
    await sendCode({
      contact,
      contact_id,
      contact_type,
    });
  };

  const onValidateCode = async (
    title: ICodeValidationRequestDto,
  ): Promise<void> => {
    await validateCode(title);
  };

  const onCreateAccount = async ({
    password,
    password_confirmation,
  }: ICreateAccountRequestDto): Promise<void> => {
    await createAccount({
      password,
      password_confirmation,
    });
  };

  return {
    onLogin,
    onLoginGuest,
    onVerify,
    onSendCode,
    onValidateCode,
    onCreateAccount,
  };
};
