import { DefaultTheme } from 'styled-components';

interface IButtonParams {
  background: string;
  hoverBackground?: string;
  hover?: string;
  textColor: string;
  borderColor: string;
}

export const getButtonParamsByColor = (
  color: string,
  disabled: boolean | undefined,
  { colors }: DefaultTheme,
): IButtonParams => {
  if (disabled) {
    switch (color) {
      case 'primary':
      default:
        return {
          background: colors.base.DISABLED,
          textColor: colors.base.WHITE,
          borderColor: 'transparent',
        };
      case 'secondary':
        return {
          background: '',
          hoverBackground: '',
          textColor: '',
          borderColor: 'transparent',
        };
      case 'ghost':
        return {
          background: '',
          hoverBackground: '',
          textColor: '',
          borderColor: 'transparent',
        };
    }
  }
  switch (color) {
    case 'primary':
    default:
      return {
        background: colors.base.PRIMARY,
        hoverBackground: colors.base.PRIMARY_HOVERED,
        textColor: colors.base.WHITE,
        borderColor: 'transparent',
      };
    case 'secondary':
      return {
        background: colors.base.LIGHT,
        hoverBackground: colors.base.LIGHT_GRAY,
        textColor: colors.text.GRAY,
        borderColor: 'transparent',
      };
    case 'promise':
      return {
        background: '',
        hoverBackground: '',
        textColor: '',
        borderColor: 'transparent',
      };
    case 'success':
      return {
        background: colors.base.SUCCESS,
        hoverBackground: colors.base.DISABLED,
        textColor: colors.base.WHITE,
        borderColor: 'transparent',
      };
    case 'error':
      return {
        background: colors.base.ERROR,
        hoverBackground: colors.base.ERROR_HOVERED,
        textColor: colors.base.WHITE,
        borderColor: 'transparent',
      };
    case 'warning':
      return {
        background: colors.base.WARNING,
        hoverBackground: colors.base.WARNING_HOVERED,
        textColor: colors.base.WHITE,
        borderColor: 'transparent',
      };
    case 'ghost':
      return {
        background: 'none',
        hoverBackground: 'none',
        hover: colors.base.DARK,
        textColor: colors.text.GRAY,
        borderColor: 'transparent',
      };
  }
};
