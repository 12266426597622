import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { QnADropdown } from '@pages/FAQ/components/QnADropdown';
import { Footer } from '@pages/FAQ/components/Footer';

interface IQnAWrapperProps {
  onSupport(): void;
}

export const QnAWrapper: FC<IQnAWrapperProps> = ({ onSupport }) => {
  const { t } = useTranslation();

  const qnaItems = [
    {
      title: t('qna.one.title'),
      text: t('qna.one.text'),
    },
    {
      title: t('qna.two.title'),
      text: [
        t('qna.two.text.pOne'),
        t('qna.two.text.pTwo'),
        t('qna.two.text.pThree'),
        t('qna.two.text.pFour'),
        t('qna.two.text.pFive'),
        t('qna.twoTextPSix'),
        t('qna.twoTextPSeven'),
      ],
    },
    {
      title: t('qna.three.title'),
      text: `<strong>${t('qna.three.text.creditor')}</strong> ${t(
        'qna.three.text.pOne',
      )} <strong>${t('qna.three.text.admin')}</strong> - ${t(
        'qna.three.text.pTwo',
      )}`,
    },
    {
      title: t('qna.four.title'),
      text: t('qna.four.text'),
    },
    {
      title: t('qna.five.title'),
      text: [
        t('qna.five.text.pOne'),
        t('qna.five.text.pTwo'),
        t('qna.five.text.pThree'),
        t('qna.five.text.pFour'),
      ],
    },
    {
      title: t('qna.six.title'),
      text: [
        t('qna.six.text.pOne'),
        `${t('qna.six.text.pTwo')} - <strong>${t(
          'qna.six.text.pTwoValue',
        )}</strong>`,
      ],
    },
    {
      title: t('qna.seven.title'),
      text: `${t('qna.seven.text')} <u id="support-form">${t(
        'qna.seven.text.link',
      )}</u>`,
    },
    {
      title: t('qna.eight.title'),
      text: t('qna.eight.text'),
      list: [
        t('qna.eight.textPOne'),
        t('qna.eight.textPTwo'),
        t('qna.eight.textPThree'),
      ],
    },
    {
      title: t('qna.ten.title'),
      text: `${t('qna.ten.text')} <strong>${t('qna.ten.text.value')}</strong>`,
    },
  ];

  const [currentOpen, setCurrentOpen] = useState<number | null>(null);

  return (
    <SWrapper>
      <SQnAWrapper>
        <STitle>{t('faq.title')}</STitle>
        <SSubtitleWrapper>{t('faq.subtitle')}</SSubtitleWrapper>
        <SDropdownsWrapper>
          {qnaItems.map((item, idx) => (
            <QnADropdown
              key={idx}
              title={item.title}
              externalOpened={idx === currentOpen}
              onToggle={() => {
                if (currentOpen === idx) {
                  setCurrentOpen(null);
                } else {
                  setCurrentOpen(idx);
                }
              }}
              onSetOpened={() => {
                setCurrentOpen(idx);
              }}
              onSetClosed={() => {
                if (currentOpen === idx) {
                  setCurrentOpen(null);
                }
              }}
            >
              {item?.list ? (
                <SOl>
                  {item.list.map((e, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: e }} />
                  ))}
                </SOl>
              ) : Array.isArray(item.text) ? (
                <SUl>
                  {item.text.map((e, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: e }} />
                  ))}
                </SUl>
              ) : (
                <div
                  onClick={() => {
                    if (item.text.includes('support-form')) {
                      onSupport();
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              )}
            </QnADropdown>
          ))}
        </SDropdownsWrapper>
        <Footer onSupport={onSupport} />
      </SQnAWrapper>
    </SWrapper>
  );
};

const SOl = styled.ol`
  ${(p) => p.theme.font.body.small};
  color: ${(p) => p.theme.colors.text.GRAY};
  padding-left: 24px;

  & li {
    margin: 10px 0;
  }
`;

const SUl = styled.ul`
  ${(p) => p.theme.font.body.small};
  color: ${(p) => p.theme.colors.text.GRAY};
  padding-left: 24px;

  & li {
    margin: 10px 0;
  }
`;

const SDropdownsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-bottom: 1px solid ${(p) => `${p.theme.colors.base.DARK}16`};
  padding-bottom: 10%;
`;
const SSubtitleWrapper = styled.div`
  color: ${(p) => p.theme.colors.text.GRAY};
  ${(p) => p.theme.font.body.body};
  margin-bottom: 8%;
`;
const STitle = styled.div`
  width: 100%;
  color: ${(p) => p.theme.colors.text.BODY};
  ${(p) => p.theme.font.header.h2};
  margin-bottom: 6px;
`;
const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  word-break: break-word;
`;
const SQnAWrapper = styled.div`
  ${(p) => css`
    z-index: 1;

    display: flex;
    flex-direction: column;
    background-color: ${p.theme.colors.base.WHITE};
    ${p.theme.shadows.common};
    padding: 5% 7%;
    width: 60%;
    border-radius: 8px;

    @media (max-width: 950px) {
      width: 90%;
    }
  `}
`;
