import { FC, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ContactDetails } from '@pages/Profile/components/ProfileInfo/components/ContactDetails';
import { Checkbox } from '@components/ui/Checkbox/Checkbox';
import { Button } from '@components/ui/Button/Button';
import { PageWrapper } from '@pages/Profile/components/PageWrapper';
import { noop } from '@common/utils/functions/noop';
import { ProfileInfoDto } from '@pages/Profile/dto/ProfileInfoDto';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { IUserInfo } from '@pages/Profile/components/ProfileInfo/types/IUserInfo';
import TermsPopup from '@components/main/TermsPopup/TermsPopup';

interface IProfileInfoProps {
  userInfo: IUserInfo | null;
  isLoading: boolean;
}

export const ProfileInfo: FC<IProfileInfoProps> = ({ userInfo, isLoading }) => {
  const { validate, fields } = useForm(ProfileInfoDto);

  const initialValues = {
    firstName: userInfo?.first_name || undefined,
    lastName: userInfo?.last_name || undefined,
    phone: userInfo?.phone || undefined,
    email: userInfo?.email || undefined,
  };
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);

  const [agreed, setAgreed] = useState(false);

  return (
    <PageWrapper
      title={t('profile.titleProfile')}
      subtitle={t('profile.subtitle')}
    >
      <TermsPopup isOpen={showPopup} toggle={() => setShowPopup(!showPopup)} />
      <Form<ProfileInfoDto>
        onSubmit={noop}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, touched, submitFailed }) => (
          <SForm onSubmit={handleSubmit}>
            <ContactDetails
              fields={fields}
              isLoading={isLoading}
              isMailDisabled={!!userInfo?.email}
              isPhoneDisabled={!!userInfo?.phone}
            />
            {/*<Address fields={fields} isLoading={isLoading} />*/}
            {/*<SCheckWrapper>*/}
            {/*  <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} />*/}
            {/*  <span>*/}
            {/*    {t('agree')}{' '}*/}
            {/*    <SClickable onClick={() => setShowPopup(!showPopup)}>*/}
            {/*      {t('personalData')}*/}
            {/*    </SClickable>{' '}*/}
            {/*    /!*{t('and')}{' '}*!/*/}
            {/*    /!*<SClickable onClick={() => setShowPopup(!showPopup)}>*!/*/}
            {/*    /!*  {t('businessTerms')}*!/*/}
            {/*    /!*</SClickable>*!/*/}
            {/*  </span>*/}
            {/*</SCheckWrapper>*/}
            {/*<SButtonWrapper>*/}
            {/*  <Button*/}
            {/*    type='submit'*/}
            {/*    w100={true}*/}
            {/*    color={submitFailed ? 'error' : 'primary'}*/}
            {/*    size='lg'*/}
            {/*    disabled={!touched || submitting || !agreed}*/}
            {/*  >*/}
            {/*    {t('buttons.save')}*/}
            {/*  </Button>*/}
            {/*</SButtonWrapper>*/}
          </SForm>
        )}
      />
    </PageWrapper>
  );
};

const SClickable = styled.u`
  cursor: pointer;
`;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
const SButtonWrapper = styled.div`
  width: 200px;
`;
const SCheckWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 32px 0 24px;
  color: ${(p) => p.theme.colors.text.BODY};
  ${(p) => p.theme.font.body.body}
`;
