import { FC } from 'react';
import styled, { css } from 'styled-components';

import { TInfoIndicatorSize } from '@components/main/DebtCases/DebtCaseDetailed/types/TInfoIndicatorSize';

interface IInfoIndicatorProps {
  title: string;
  value: string | number;
  size?: TInfoIndicatorSize;
  bold?: boolean;
}

export const InfoColumn: FC<IInfoIndicatorProps> = ({
  title,
  value,
  size = 'lg',
  bold = false,
}) => (
  <SWrapper>
    <STitle size={size} bold={bold}>
      {title}
    </STitle>
    <SValue size={size}>{value}</SValue>
  </SWrapper>
);

const SValue = styled.div<{ size: TInfoIndicatorSize }>`
  color: ${(p) => p.theme.colors.text.BODY};
  ${({ theme, size }) => {
    if (size === 'lg') {
      return css`
        ${theme.font.header.h4};
      `;
    }
    if (size === 'md') {
      return css`
        ${theme.font.header.h5}
      `;
    }

    return css`
      ${theme.font.header.h6}
    `;
  }}
`;
const STitle = styled.div<{ size: TInfoIndicatorSize; bold: boolean }>`
  color: ${(p) => p.theme.colors.text.GRAY};
  ${({ theme, size }) =>
    size === 'lg' || size === 'md'
      ? theme.font.body.small
      : theme.font.header.h6};
  ${({ bold }) =>
    bold &&
    css`
      font-size: 15px;
      font-weight: 600;
    `};
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  min-width: 73px;
`;
