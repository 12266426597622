import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { ICallBackResponseDto } from '@common/api/dto/features/CallBack/response/ICallBackResponseDto';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { ICallBackRequestDto } from '@common/api/dto/features/CallBack/request/ICallBackRequestDto';

class CallBack extends ApiServiceBase {
  public constructor() {
    super();
  }

  public submit(dto: ICallBackRequestDto): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/users/call_me_back', dto);
  }

  public fetch(): Promise<ApiResponse<ICallBackResponseDto>> {
    return this.get('/api/v1/users/call_me_back');
  }
}

export const callBack = new CallBack();
