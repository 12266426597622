import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICreatePromiseRequestDto } from '@common/api/dto/features/CreatePayment/request/ICreatePromiseRequestDto';
import { createPayment } from '@common/api/services/CreatePayment/CreatePayment';
import { StatefulPage } from '@common/enums/Page';
import { ICreatePaymentPlanRequestDto } from '@common/api/dto/features/CreatePayment/request/ICreatePaymentPlanRequestDto';

export const paymentAsyncActions = {
  createPromisePay: createAsyncThunk(
    `${StatefulPage.CREATE_PAYMENT}/promise`,
    async (dto: ICreatePromiseRequestDto, { rejectWithValue }) => {
      try {
        await createPayment.createPromise(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
  createPaymentPlan: createAsyncThunk(
    `${StatefulPage.CREATE_PAYMENT}/create-plan`,
    async (dto: ICreatePaymentPlanRequestDto, { rejectWithValue }) => {
      try {
        await createPayment.createPaymentPlan(dto);
      } catch (error) {
        return rejectWithValue(error);
      }
    },
  ),
};
