import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import {
  createPlanOptionsSelector,
  isLoadingSelector,
  promiseOptionsSelector,
  serverErrorSelector,
  submitSuccessSelector,
} from '@pages/CreatePayment/duck/selectors';
import { createPaymentSlice } from '@pages/CreatePayment/duck/slice';
import { paymentAsyncActions } from '@pages/CreatePayment/duck/paymentAsyncActions';

export const withCreatePaymentState = connect(
  (state: IRootState) => ({
    isLoading: isLoadingSelector(state),
    serverError: serverErrorSelector(state),
    submitSuccess: submitSuccessSelector(state),
    createPlanOptions: createPlanOptionsSelector(state),
    promiseOptions: promiseOptionsSelector(state),
  }),
  {
    ...createPaymentSlice.actions,
    ...paymentAsyncActions,
  },
);

export type IWithCreatePaymentState = ConnectedProps<
  typeof withCreatePaymentState
>;
