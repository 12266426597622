import { IClass } from '@common/types/object/IClass';
import {
  getFormValidator,
  TFormValidator,
} from '@app/FinalForm/utils/getFormValidator';
import { getClassFields, IKeys } from '@common/utils/class/getClassFields';

export interface IFormResult<Dto extends IClass> {
  validate: TFormValidator<Dto>;
  fields: IKeys<Dto>;
}
export const getForm = <Dto extends IClass>(dto: Dto): IFormResult<Dto> => ({
  validate: getFormValidator(dto),
  fields: getClassFields(dto),
});
