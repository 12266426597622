import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { IUserInfoResponseDto } from '@common/api/dto/features/UserInfo/IUserInfoResponseDto';
import { IUserInfo } from '@components/main/Support/types/IUserInfo';
import { headerAsyncActions } from '@components/main/Header/duck/headerAsyncActions';

interface IState {
  userInfo: IUserInfo | null;
  isLoading: boolean;
}

export const headerSlice = createSlice({
  name: Feature.HEADER,
  initialState: {
    userInfo: null,
    isLoading: false,
  } as IState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(headerAsyncActions.fetchUser.pending, (draft) => {
      draft.isLoading = true;
    });
    builder.addCase(headerAsyncActions.fetchUser.rejected, (draft) => {
      draft.isLoading = false;
    });
    builder.addCase(
      headerAsyncActions.fetchUser.fulfilled,
      (draft, { payload }: PayloadAction<IUserInfoResponseDto>) => {
        draft.isLoading = false;

        draft.userInfo = {
          firstName: payload.first_name,
          lastName: payload.last_name,
        };
      },
    );
  },
});
