import {
  IsDefined,
  IsEmail,
  Matches,
  MaxLength,
  MinLength,
} from 'class-validator';

export class ProfileInfoDto {
  @MinLength(2, {
    message: 'Name can not be shorter than $constraint1 symbols',
  })
  @MaxLength(34, {
    message: 'Name can not be longer than $constraint1 symbols',
  })
  @Matches(/[A-Za-z]/, {
    message: 'Invalid First Name',
  })
  @IsDefined({ message: 'Name field can not be empty' })
  public firstName!: string;

  @MinLength(2, {
    message: 'Surname can not be shorter than $constraint1 symbols',
  })
  @MaxLength(34, {
    message: 'Surname can not be longer than $constraint1 symbols',
  })
  @Matches(/[A-Za-z]/, {
    message: 'Invalid Last Name',
  })
  @IsDefined({ message: 'Last name field can not be empty' })
  public lastName!: string;

  @MinLength(6, {
    message: 'Phone number can not be shorter than $constraint1 symbols',
  })
  @MaxLength(24, {
    message: 'Phone number can not be longer than $constraint1 symbols',
  })
  @Matches(/^(?:[\d+].*\d|\d)$/, {
    message: 'Invalid phone number.',
  })
  @IsDefined({ message: 'Phone number field can not be empty' })
  public phone!: string;

  @IsEmail({}, { message: 'Invalid Email' })
  @MinLength(3, {
    message: 'Email can not be shorter than $constraint1 symbols',
  })
  @IsDefined({ message: 'Email field can not be empty' })
  public email!: string;

  @MinLength(2, {
    message: 'Street can not be shorter than $constraint1 symbols',
  })
  @MaxLength(34, {
    message: 'Street can not be longer than $constraint1 symbols',
  })
  @IsDefined({ message: 'Street field can not be empty' })
  public street!: string;

  @MinLength(2, {
    message: 'No. can not be shorter than $constraint1 symbols',
  })
  @MaxLength(34, {
    message: 'No. can not be longer than $constraint1 symbols',
  })
  @Matches(/^\d+(\/\d+)*$/, {
    message: 'Invalid NO.',
  })
  @IsDefined({ message: 'No. field can not be empty' })
  public no!: string;

  @MinLength(2, {
    message: 'City can not be shorter than $constraint1 symbols',
  })
  @MaxLength(34, {
    message: 'City can not be longer than $constraint1 symbols',
  })
  @IsDefined({ message: 'City field can not be empty' })
  public city!: string;

  @MinLength(2, {
    message: 'Zip can not be shorter than $constraint1 symbols',
  })
  @MaxLength(34, {
    message: 'Zip can not be longer than $constraint1 symbols',
  })
  @IsDefined({ message: 'Zip field can not be empty' })
  public zip!: number;
}
