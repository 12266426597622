import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

import { usePortal } from '@common/utils/hooks/usePortal';
import { ZIndex } from '@common/enums/Zindex';

interface IPopupViewProps {
  close?(): void;
  isOpen: boolean;
  zIndex?: number;
  testId?: string;
}

export const PopupView: FC<IPopupViewProps> = ({
  children,
  isOpen,
  close,
  testId,
  zIndex = ZIndex.POPUP,
}) => {
  const portalElement = usePortal();

  const [savedChildren, setSavedChildren] = useState(children);

  useEffect(() => {
    if (children) {
      setSavedChildren(children);
    }

    return () => setSavedChildren(undefined);
  }, [children]);

  useEffect(() => {
    const resetBodyLock = (): void => {
      document.body.style.overflow = '';
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';

      return resetBodyLock;
    }

    resetBodyLock();

    return resetBodyLock;
  }, [isOpen]);

  const viewContent = (
    <SContainer isOpen={isOpen} zIndex={zIndex} data-testid={testId}>
      <SBackground onClick={() => close && close()} />
      <SContent>
        <SFakeBackground onClick={() => close && close()} />
        <SContentWrapper>{savedChildren}</SContentWrapper>
      </SContent>
    </SContainer>
  );

  return portalElement ? createPortal(viewContent, portalElement) : viewContent;
};

const SContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-height: 100%;
`;
const SFakeBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;
const SContent = styled.div`
  width: max-content;
  height: calc(100% - 64px);
  max-width: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(24, 24, 24, 0.4);
`;
const SContainer = styled.div<{ isOpen: boolean; zIndex: number }>`
  z-index: ${({ zIndex }) => zIndex};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
