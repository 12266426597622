import { FC, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { HandleThunkActionCreator } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { Button } from '@components/ui/Button/Button';
import { PageWrapper } from '@pages/Profile/components/PageWrapper';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { ChangePasswordDto } from '@pages/Profile/dto/ChangePasswordDto';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { profileAsyncActions } from '@pages/Profile/duck/profileAsyncActions';

interface IProps {
  onChangePassword: HandleThunkActionCreator<
    typeof profileAsyncActions.editPassword
  >;
  serverError: string | null;
}

export const ChangePassword: FC<IProps> = ({
  onChangePassword,
  serverError,
}) => {
  const { validate, fields } = useForm(ChangePasswordDto);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('profile.title')}>
      <Form<ChangePasswordDto>
        onSubmit={async (values, { restart }) => {
          await onChangePassword({
            old_password: values.passwordCurrent,
            password: values.passwordNew,
            password_confirmation: values.passwordNewAgain,
          });
          setSuccess(true);
          restart();
        }}
        validate={validate}
        render={({ handleSubmit, submitting, touched, submitFailed }) => (
          <SForm onSubmit={handleSubmit}>
            <SCurrentPassWrapper>
              <FinalFormInput
                id='password-current'
                name={fields.passwordCurrent}
                placeholder={t('form.currentPass')}
                variant='password'
                label={t('form.currentPass')}
              />
            </SCurrentPassWrapper>
            <SNewPasswordWrapper>
              <SInputWrapper>
                <FinalFormInput
                  id='password-new'
                  name={fields.passwordNew}
                  placeholder={t('form.newPass')}
                  variant='password'
                  label={t('form.newPass')}
                />
              </SInputWrapper>
              <SInputWrapper>
                <FinalFormInput
                  id='password-new-again'
                  name={fields.passwordNewAgain}
                  placeholder={t('form.newPassAgain')}
                  variant='password'
                  label={t('form.newPassAgain')}
                />
              </SInputWrapper>
            </SNewPasswordWrapper>
            {!serverError && success && (
              <SActionContainer>
                <ActionStatus type='success'>
                  {t('profile.success')}
                </ActionStatus>
              </SActionContainer>
            )}
            {serverError && (
              <SActionContainer>
                <ActionStatus>{serverError}</ActionStatus>
              </SActionContainer>
            )}
            <SButtonWrapper>
              <Button
                w100={true}
                type='submit'
                size='lg'
                color={submitFailed ? 'error' : 'primary'}
                disabled={!touched || submitting}
              >
                {t('buttons.changePassword')}
              </Button>
            </SButtonWrapper>
          </SForm>
        )}
      />
    </PageWrapper>
  );
};

const SActionContainer = styled.div`
  margin-bottom: 27px;
`;
const SInputWrapper = styled.div`
  width: 100%;
`;
const SNewPasswordWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 27px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;
const SCurrentPassWrapper = styled.div`
  max-width: 50%;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;
const SForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 31px 0 27px;
`;
const SButtonWrapper = styled.div`
  width: 200px;
`;
