import { useCallback, useMemo } from 'react';

import { noop } from '@common/utils/functions/noop';
import { IIsOpenResult, useIsOpen } from '@common/hooks/useIsOpen';

interface IIsOpenDropdownParams {
  onClose?(): void;
  onOpen?(): void;
  isOpen?: boolean;
}

export const useIsOpenDropdown = ({
  isOpen: isOpenExternal,
  onClose = noop,
  onOpen = noop,
}: IIsOpenDropdownParams): IIsOpenResult => {
  const params = useIsOpen(false);

  const isOpen = useMemo(() => {
    if (isOpenExternal !== undefined) {
      return isOpenExternal;
    }

    return params.isOpen;
  }, [isOpenExternal, params]);

  const close = useCallback(
    (shallow?: boolean) => {
      if (!shallow) {
        onClose();
      }
      params.close();
    },
    [onClose, params],
  );

  const open = useCallback((): void => {
    onOpen();

    params.open();
  }, [params, onOpen]);

  const toggle = useCallback((): void => {
    if (isOpen) {
      onOpen();
    }
    if (!isOpen) {
      onClose();
    }

    params.toggle();
  }, [isOpen, onOpen, onClose, params]);

  const setIsOpen = useCallback(
    (value: boolean): void => {
      if (value && !isOpen) {
        open();
      }
      if (!value && isOpen) {
        close();
      }
    },
    [isOpen, open, close],
  );

  return {
    isOpen,
    close,
    open,
    toggle,
    setIsOpen,
  };
};
