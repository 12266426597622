import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';

interface IResult {
  activeTab: AnchorTabs;
}

const getAllElementsWithAttribute = (
  attribute: string,
  target: Element,
): Element | null => {
  if (target?.id) {
    return target;
  }

  const allElements = target.querySelectorAll('*');

  for (let i = 0, n = allElements.length; i < n; i++) {
    if (allElements[i].getAttribute(attribute) !== null) {
      return allElements[i];
    }
  }

  return null;
};

export const useIntersectionObserver = (
  ref: MutableRefObject<HTMLDivElement | null>,
): IResult => {
  const [allTabs, setAllTabs] = useState<HTMLCollection | []>([]);
  const [activeTab, setActiveTab] = useState(AnchorTabs.OVERVIEW);

  const observeFunc = useCallback(
    (entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          const elementWithId = getAllElementsWithAttribute('id', entry.target);

          if (elementWithId) {
            setActiveTab(elementWithId.id as AnchorTabs);
          }
        }
      });
    },
    [],
  );

  const tabObserver = useMemo(
    () =>
      new IntersectionObserver(observeFunc, {
        root: null,
        threshold: 0.5,
      }),
    [observeFunc],
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setAllTabs(ref.current.children);

    return () => {
      tabObserver.disconnect();
    };
  }, [ref, tabObserver]);

  Array.from(allTabs).forEach((e: Element) => tabObserver.observe(e));

  return { activeTab };
};
