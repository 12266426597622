import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { IUserInfo } from '@pages/Profile/components/ProfileInfo/types/IUserInfo';
import { IProfileResponse } from '@common/api/dto/features/Profile/response/IProfileResponse';
import { profileAsyncActions } from '@pages/Profile/duck/profileAsyncActions';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { IStandardError } from '@common/api/types/IStandardError';

interface IState {
  isLoading: boolean;
  userInfo: IUserInfo | null;
  serverError: string | null;
}

export const profileSlice = createSlice({
  name: Feature.PROFILE,
  initialState: {
    isLoading: false,
    userInfo: null,
    serverError: null,
  } as IState,
  reducers: {
    setState: (draft, action: PayloadAction<IProfileResponse>) => {
      draft.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profileAsyncActions.editPassword.pending.type, (draft) => {
      draft.isLoading = true;
    });
    builder.addCase(
      profileAsyncActions.editPassword.fulfilled.type,
      (draft) => {
        draft.isLoading = false;
      },
    );
    builder.addCase(
      profileAsyncActions.editPassword.rejected.type,
      (draft, { payload }: PayloadAction<ApiErrorDto<IStandardError>>) => {
        draft.isLoading = false;

        draft.serverError = payload.response
          ? JSON.stringify(payload.response.data.detail)
          : 'Something went wrong, please try again';
      },
    );
  },
});
