import { Location } from 'history';

import { StatefulPage } from '@common/enums/Page';
import { TPageRouteInfo } from '@app/PageWrapper/types/TPageRouteInfo';

export const getCurrentPageName = (location: Location): TPageRouteInfo => {
  const ArrayOfLocation = location.pathname.split('/');

  if (
    ArrayOfLocation.includes(StatefulPage.DEBTS) &&
    ArrayOfLocation.includes(StatefulPage.DEBT_DETAILS)
  ) {
    return {
      name: StatefulPage.DEBT_DETAILS,
      params: { id: ArrayOfLocation.slice(-1).join('') },
    };
  }
  if (ArrayOfLocation.includes(StatefulPage.DEBTS)) {
    return {
      name: StatefulPage.DEBTS,
    };
  }
  if (ArrayOfLocation.includes(StatefulPage.PROFILE)) {
    return {
      name: StatefulPage.PROFILE,
    };
  }
  if (ArrayOfLocation.includes(StatefulPage.CREATE_PAYMENT)) {
    return {
      name: StatefulPage.CREATE_PAYMENT,
      params: { id: ArrayOfLocation.slice(-1).join('') },
    };
  }
  if (ArrayOfLocation.includes(StatefulPage.CREATE_PAYMENT_PROMISE)) {
    return {
      name: StatefulPage.CREATE_PAYMENT_PROMISE,
      params: { id: ArrayOfLocation.slice(-1).join('') },
    };
  }

  throw new Error('Undefined Page');
};
