import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Icon } from '@components/ui/Icon/Icon';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { useIsOpen } from '@common/hooks/useIsOpen';

interface IQnADropdownProps {
  externalOpened?: boolean | null;
  onToggle?(): void;
  onSetOpened?(): void;
  onSetClosed?(): void;
  title: string;
}

const transitionName = 'fade';

export const QnADropdown: FC<IQnADropdownProps> = ({
  children,
  title,
  externalOpened,
  onToggle,
  onSetOpened,
  onSetClosed,
}) => {
  const { toggle, isOpen, setIsOpen } = useIsOpen(false);

  const textRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(56);

  const handleResize = useCallback(() => {
    if (!textRef.current) {
      return;
    }

    setHeight(textRef.current.scrollHeight);
  }, [textRef]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toggle, textRef, handleResize]);

  const open = (): void => {
    setIsOpen(true);
  };
  const close = (): void => {
    setIsOpen(false);
  };

  return (
    <SOuterWrapper>
      <SWrapper
        onClick={onToggle || toggle}
        isOpen={externalOpened || isOpen}
        height={height}
        ref={textRef}
      >
        <SLabel>
          <STitle>
            {title}
            <div>
              <SIcon
                name={IconName.ARROW_DOWN}
                isOpen={externalOpened || isOpen}
                size='sm'
              />
            </div>
          </STitle>
          <SCSSTransition
            in={externalOpened || isOpen}
            unmountOnExit={true}
            onEnter={onSetOpened || open}
            onExited={onSetClosed || close}
            classNames={transitionName}
            timeout={450}
          >
            <SText>{children}</SText>
          </SCSSTransition>
        </SLabel>
      </SWrapper>
    </SOuterWrapper>
  );
};

const SOuterWrapper = styled.div`
  position: relative;
`;
const SCSSTransition = styled(CSSTransition)`
  transition: all 500ms;
  &.fade-enter {
    opacity: 0;
    transform: translateY(-25px);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 500ms, transform 500ms;
  }
`;
const SText = styled.div`
  ${(p) => p.theme.font.body.small};
  color: ${(p) => p.theme.colors.text.GRAY};
  font-weight: 400;
  padding-top: 12px;
`;
const STitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) => p.theme.font.body.body};
  color: ${(p) => p.theme.colors.text.BODY};
  padding-right: 20px;
`;
const SIcon = styled(Icon)<{ isOpen: boolean }>`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 18px;
  top: 25px;
  transform: ${({ isOpen }) => (isOpen ? 'scale(1, -1)' : 'scale(1, 1)')};
  transition: transform 500ms;
  color: ${(p) => p.theme.colors.text.GRAY};
`;
const SLabel = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: height, 500ms;
`;
const SWrapper = styled.div<{ isOpen: boolean; height: number }>`
  padding: 3%;
  border-radius: 4px;
  overflow-y: ${({ isOpen }) => (isOpen ? 'overlay' : 'hidden')};
  cursor: pointer;
  word-break: break-word;
  box-sizing: content-box;
  transition-property: max-height, height, background-color;
  transition-duration: 500ms;
  height: auto;
  max-height: ${({ isOpen, height }) => (isOpen ? '500px' : `${height}px`)};
  background-color: ${(p) =>
    p.isOpen ? `${p.theme.colors.base.LIGHT}80` : p.theme.colors.base.LIGHT};

  &:hover ${SIcon} {
    color: ${(p) => p.theme.colors.text.BODY};
  }
`;
