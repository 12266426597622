import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';
import { commonSelector } from '@common/duck/selectors';

const debtDetailsSelector = getFeatureSelector(Feature.DEBT_DETAILS);

export const isDebtDetailsLoadingSelector = createSelector(
  commonSelector,
  ({ loading }) => loading,
);
export const isServerErrorSelector = createSelector(
  commonSelector,
  ({ serverError }) => serverError,
);
export const isDebtDetailsItemSelector = createSelector(
  debtDetailsSelector,
  ({ debtItem }) => debtItem,
);
export const debtDetailsPaymentPlanSelector = createSelector(
  debtDetailsSelector,
  ({ paymentPlan }) => paymentPlan,
);
