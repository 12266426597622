import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { SupportWidget } from '@components/main/SupportWidget/SupportWidget';
import { Tab } from '@components/ui/Tab/Tab';
import { ProfileTab } from '@pages/Profile/enums/ProfileTab';
import { ProfileInfo } from '@pages/Profile/components/ProfileInfo/ProfileInfo';
import { ChangePassword } from '@pages/Profile/components/ChangePassword/ChangePassword';
import {
  IWithProfileState,
  withProfileState,
} from '@pages/Profile/hocs/withProfileState';
import { Footer } from '@components/main/Footer';

export const ProfileComponent: FC<IWithProfileState> = ({
  userInfo,
  isLoading,
  editPassword,
  serverError,
}) => {
  const [currentTab, setCurrentTab] = useState(ProfileTab.EDIT_PROFILE);
  const { t } = useTranslation();

  return (
    <SWrapper data-testid='profile-page'>
      <SInnerWrapper>
        <SSidebarWrapper>
          <SPageTitle>{t('profile.yourProfile')}</SPageTitle>
          <STabsWrapper>
            <Tab
              onClick={() => setCurrentTab(ProfileTab.EDIT_PROFILE)}
              active={currentTab === ProfileTab.EDIT_PROFILE}
            >
              {t('profile.titleProfile')}
            </Tab>
            <Tab
              onClick={() => setCurrentTab(ProfileTab.CHANGE_PASSWORD)}
              active={currentTab === ProfileTab.CHANGE_PASSWORD}
            >
              {t('profile.title')}
            </Tab>
          </STabsWrapper>
          <SSupportWrapperMin>
            <SupportWidget background={true} />
          </SSupportWrapperMin>
        </SSidebarWrapper>

        {currentTab === ProfileTab.EDIT_PROFILE && (
          <ProfileInfo userInfo={userInfo} isLoading={isLoading} />
        )}
        {currentTab === ProfileTab.CHANGE_PASSWORD && (
          <ChangePassword
            onChangePassword={editPassword}
            serverError={serverError}
          />
        )}
        <SSupportWrapperMax>
          <SupportWidget background={true} />
        </SSupportWrapperMax>
      </SInnerWrapper>
      <Footer />
    </SWrapper>
  );
};

export const Profile = withProfileState(ProfileComponent);

const SPageTitle = styled.div`
  ${(p) => p.theme.font.header.h1};
  color: ${(p) => p.theme.colors.text.BODY};
  margin-bottom: 32px;
`;
const SSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 33px;
`;
const SSupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 29px;
`;

const SSupportWrapperMax = styled(SSupportWrapper)`
  @media (min-width: 999px) {
    display: none;
  }
  padding-top: 30px;
  width: 100%;
  justify-content: center;
`;
const SSupportWrapperMin = styled(SSupportWrapper)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const STabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;
const SInnerWrapper = styled.div`
  display: flex;
  padding: 48px 10%;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }

  @media (max-width: 500px) {
    padding: 48px 5%;
  }
`;
const SWrapper = styled.div`
  height: 90%;
  overflow-y: scroll;

  @media (max-width: 500px) {
    //height: calc(100% - 15%);
  }
`;
