import { TPositionType } from '@app/DropdownView/types/TPositionType';
import { IDropdownPositionParams } from '@app/DropdownView/types/IDropdownPositionParams';
import { useFixedPosition } from '@app/DropdownView/hooks/useFixedPosition';

interface IResult {
  isPositionPrepared: boolean;
  positionType: TPositionType | null;
}

export const useDropdownPosition = (
  params: IDropdownPositionParams,
): IResult => {
  const {
    isPositionPrepared: fixedPositionPrepared,
    positionType: fixedPositionType,
  } = useFixedPosition(params);

  return {
    isPositionPrepared: fixedPositionPrepared,
    positionType: fixedPositionType,
  };
};
