import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { StatelessPage } from '@common/enums/Page';

const authSelector = getFeatureSelector(StatelessPage.AUTH);

export const isAuthorizedSelector = createSelector(
  authSelector,
  ({ isAuthorized }) => isAuthorized,
);
