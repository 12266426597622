import { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/ui/Button/Button';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { LinkComponent } from '@components/ui/LinkComponent/LinkComponent';
import { Logo } from '@components/ui/Logo/Logo';
import { DropdownView } from '@app/DropdownView/DropdownView';
import { OptionsPopup } from '@components/main/Header/components/OptionsPopup';
import { Page, StatefulPage } from '@common/enums/Page';
import {
  IWithHeaderState,
  withHeaderState,
} from '@components/main/Header/hocs/withHeaderState';
import { ZIndex } from '@common/enums/Zindex';
import { getInitials } from '@components/main/Header/utils/getInitials';
import { Loader } from '@components/ui/Loader/Loader';
import { LngSwitch } from '@components/main/Header/components/LngSwitch';
import { Burger } from '@components/main/Header/components/Burger';

const HeaderComponent: FC<IWithHeaderState> = ({
  isLoading,
  userInfo,
  fetchUser,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const fetchUserInfo = useCallback(() => {
    void fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    void fetchUserInfo();
  }, [fetchUserInfo]);

  const { t } = useTranslation();

  return (
    <SWrapper>
      <SLogoWrapper>
        <LinkComponent size='lg' to={StatefulPage.DEBTS} active={true}>
          <Logo />
        </LinkComponent>
      </SLogoWrapper>
      <STabsWrapper>
        <LinkComponent
          size='lg'
          to={StatefulPage.DEBTS}
          active={location.pathname === StatefulPage.DEBTS}
        >
          {t('header.pages.myDebts')}
        </LinkComponent>
        <LinkComponent
          size='lg'
          to={Page.FAQ}
          active={location.pathname === Page.FAQ}
        >
          {t('header.pages.faq')}
        </LinkComponent>
      </STabsWrapper>
      <SUserWrapper>
        <SLngSwitchWrapper>
          <LngSwitch />
        </SLngSwitchWrapper>
        <DropdownView
          switcher={({ toggle }) => (
            <SDropdownWrapper>
              <Button
                onClick={toggle}
                color='ghost'
                size='lg'
                icon={{ name: IconName.USER, size: 'sm' }}
              >
                {isLoading ? (
                  <Loader size='sm' />
                ) : userInfo ? (
                  getInitials(userInfo.firstName, userInfo.lastName)
                ) : (
                  t('error.tryAgain')
                )}
              </Button>
            </SDropdownWrapper>
          )}
        >
          {() => (
            <OptionsPopup>
              <LinkComponent to={StatefulPage.PROFILE}>
                <SItem>{t('editProfile')}</SItem>
              </LinkComponent>
              <SItem
                onClick={() => {
                  navigate(`/${Page.AUTH}`);
                  sessionStorage.removeItem('accessToken');
                }}
              >
                {t('header.popup.logout')}
              </SItem>
            </OptionsPopup>
          )}
        </DropdownView>
      </SUserWrapper>
      <Burger />
    </SWrapper>
  );
};

const SDropdownWrapper = styled.div`
  @media (max-width: 768px) {
    margin-right: 30px;
  }
`;
const SLngSwitchWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const SItem = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 5px;
  overflow: hidden;

  ${(p) => p.theme.font.body.small};
  color: ${(p) => p.theme.colors.base.GRAY};

  &:hover {
    background-color: ${(p) => p.theme.colors.base.LIGHT};
    color: ${(p) => p.theme.colors.base.DARK};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.base.LIGHT};
  }
`;
const SUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const STabsWrapper = styled.div`
  display: flex;
  column-gap: 48px;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SLogoWrapper = styled.div``;
const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1% 4%;
  position: sticky;
  top: 0;
  z-index: ${ZIndex.HEADER};
  width: 100%;
  background-color: ${(p) => p.theme.colors.base.WHITE};
  ${(p) => p.theme.shadows.common};
  min-height: 60px;
  max-height: 90px;
`;

export const Header = withHeaderState(HeaderComponent);
