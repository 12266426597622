import {
  Action,
  configureStore,
  Dispatch,
  MiddlewareAPI,
  StateFromReducersMapObject,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { commonSlice } from '@common/duck/slice';
import { authSlice } from '@pages/Auth/duck/slice';
import { debtDetailsSlice } from '@pages/DebtDetails/duck/slice';
import { Feature } from '@common/enums/Feature';
import { StatefulPage, StatelessPage } from '@common/enums/Page';
import { profileSlice } from '@pages/Profile/duck/slice';
import { debtsSlice } from '@pages/DebtCases/duck/slice';
import { supportSlice } from '@components/main/Support/duck/slice';
import { headerSlice } from '@components/main/Header/duck/slice';
import { createPaymentSlice } from '@pages/CreatePayment/duck/slice';
import { lostPasswordSlice } from '@components/main/LostPassword/duck/slice';

export const APPLICATION_STATE = 'applicationState';

const reducer = {
  [commonSlice.name]: commonSlice.reducer,
  [Feature.DEBTS]: debtsSlice.reducer,
  [StatelessPage.AUTH]: authSlice.reducer,
  [Feature.DEBT_DETAILS]: debtDetailsSlice.reducer,
  [Feature.PROFILE]: profileSlice.reducer,
  [Feature.SUPPORT]: supportSlice.reducer,
  [Feature.HEADER]: headerSlice.reducer,
  [Feature.CREATE_PAYMENT]: createPaymentSlice.reducer,
  [Feature.LOST_PASSWORD]: lostPasswordSlice.reducer,
};

export const setStates = {
  [StatefulPage.DEBTS]: debtsSlice.reducer,
  [StatefulPage.DEBT_DETAILS]: debtDetailsSlice.reducer,
  [StatefulPage.PROFILE]: profileSlice.reducer,
  [Feature.HEADER]: headerSlice.reducer,
  [StatefulPage.CREATE_PAYMENT]: createPaymentSlice.reducer,
};

export type IRootState = StateFromReducersMapObject<typeof reducer>;

//TODO  Preloaded State??

// const localStorageMiddleware =
//   ({ getState }: MiddlewareAPI) =>
//   (next: Dispatch<Action>): Dispatch<Action> =>
//   (action) => {
//     const result = next(action);
//
//     sessionStorage.setItem(APPLICATION_STATE, JSON.stringify(getState()));
//
//     return result;
//   };
// const rehydrateStore = (): IRootState | undefined => {
//   if (sessionStorage.getItem(APPLICATION_STATE) !== null) {
//     return JSON.parse(
//       <string>sessionStorage.getItem(APPLICATION_STATE),
//     ) as IRootState;
//   }
// };

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: reducer,
  // preloadedState: rehydrateStore(),
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false }),
  // .concat(
  //     localStorageMiddleware,
  // )
});

export { store };
