import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SPayWrapper } from '@pages/CreatePayment/components/CreatePay/CreatePay';
import { Header } from '@pages/CreatePayment/components/Header';
import { BodyWrapper } from '@pages/CreatePayment/components/BodyWrapper';
import {
  IPromiseProps,
  Selector,
} from '@pages/CreatePayment/components/PromisePay/components/Selector/Selector';

export const PromisePay: FC<IPromiseProps> = (props) => {
  const { t } = useTranslation();

  return (
    <SPayWrapper>
      <Header
        title={t('createPayment.promisePay.title')}
        subtitle={t('createPayment.promisePay.text')}
      />
      <BodyWrapper>
        <Selector {...props} />
      </BodyWrapper>
      {/*TODO: Uncomment when card payments supported */}
      {/*<Footer />*/}
    </SPayWrapper>
  );
};
