import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router';

import { ThemeProvider } from '@app/ThemeProvider/ThemeProvider';
import Background from '@components/app/AppWrapper/img/Background.png';
import BackgroundInner from '@components/app/AppWrapper/img/Littered-items.png';
import { Page } from '@common/enums/Page';

export const AppWrapper: FC = ({ children }) => {
  const route = useLocation();

  return (
    <ThemeProvider>
      <SMain background={route.pathname.includes(Page.AUTH)}>
        <SInnerWrapper>{children}</SInnerWrapper>
      </SMain>
    </ThemeProvider>
  );
};

const SInnerWrapper = styled.div`
  background-image: url(${BackgroundInner});
  background-repeat: repeat;

  height: 100%;
  width: 100%;
  min-width: 320px;
  overflow-y: hidden;
  position: absolute;
`;
const SMain = styled.div<{ background: boolean }>`
  ${({ background }) =>
    !background &&
    css`
      background-image: url(${Background});
      background-repeat: no-repeat;
      background-size: 100% 20%;
      background-position: bottom;
      background-color: ${(p) => p.theme.colors.base.LIGHT};
      height: 100%;
      width: 100%;
    `}
`;
