import { FC } from 'react';
import styled, { css } from 'styled-components';

import { noop } from '@common/utils/functions/noop';

interface ITabProps {
  active: boolean;
  onClick(): void;
  shallow?: boolean;
}

export const Tab: FC<ITabProps> = ({
  active = false,
  shallow = false,
  onClick = noop,
  children,
}) => (
  <SWrapper active={active} onClick={onClick} shallow={shallow}>
    {children}
  </SWrapper>
);

const SWrapper = styled.div<{ active: boolean; shallow: boolean }>`
  cursor: pointer;
  ${(p) => p.theme.transition};
  border-radius: 3px;
  padding: 0 15px;
  height: 44px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.text.GRAY};
  ${(p) => p.theme.font.body.small}
  border-radius: 3px;
  border-left: 3px solid transparent;

  transition-property: font-size;
  transition: 0.3s ease-in-out;

  &:hover {
    color: ${(p) => p.theme.colors.text.BODY};
  }

  ${({ theme, active, shallow }) => css`
    ${active &&
    css`
      color: ${(p) => p.theme.colors.text.BODY};
      font-weight: 500;
      text-decoration: underline;
      ${(p) => p.theme.font.body.body}

      ${!shallow &&
      active &&
      css`
        background: ${theme.colors.base.WHITE};
        border-left: 3px solid ${theme.colors.base.PRIMARY};
        ${theme.shadows.common};
      `}
    `}
  `}
`;
