import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import { isAuthorizedSelector } from '@app/PageWrapper/duck/selectors';
import { authAsyncActions } from '@pages/Auth/duck/authAsyncActions';

export const withPageWrapperState = connect(
  (state: IRootState) => ({
    isAuthorized: isAuthorizedSelector(state),
  }),
  {
    checkAuth: authAsyncActions.checkAuth,
  },
);

export type IWithPageWrapperState = ConnectedProps<typeof withPageWrapperState>;
