import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';
import { commonSelector } from '@common/duck/selectors';

const profileSelector = getFeatureSelector(Feature.PROFILE);

export const isProfileLoading = createSelector(
  commonSelector,
  ({ loading }) => loading,
);

export const errorSelector = createSelector(
  profileSelector,
  ({ serverError }) => serverError,
);

export const profileData = createSelector(
  profileSelector,
  ({ userInfo }) => userInfo,
);
