import { connect, ConnectedProps } from 'react-redux';
import { Location } from 'history';

import { IRootState } from '@common/redux/store';
import {
  debtItemsSelector,
  isDebtsLoadingSelector,
} from '@pages/DebtCases/duck/selectors';
import { IDispatch } from '@common/redux/types/IDispatch';
import { asyncActions } from '@app/PageWrapper/duck/asyncActions';

const mapDispatchToProps = (dispatch: IDispatch) =>
  ({
    onRefresh: (location: Location) =>
      dispatch(asyncActions.initPage(location)),
  } as const);

export const withDebtsFeedState = connect(
  (state: IRootState) => ({
    isLoading: isDebtsLoadingSelector(state),
    debtItems: debtItemsSelector(state),
  }),
  mapDispatchToProps,
);

export type IWithDebtsFeedState = ConnectedProps<typeof withDebtsFeedState>;
