import styled from 'styled-components';
import { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import { HandleThunkActionCreator } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { SupportWrapper } from '@components/main/Support/components/SupportWrapper';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { CallBack } from '@components/main/Support/dto/CallBack';
import { Button } from '@components/ui/Button/Button';
import { FinalFormDatePicker } from '@app/FinalForm/components/FinalFormDatePicker';
import { FinalFormSelect } from '@app/FinalForm/components/FinalFormSelect';
import { supportAsyncActions } from '@components/main/Support/duck/supportAsyncActions';
import { ICallBackResponseDto } from '@common/api/dto/features/CallBack/response/ICallBackResponseDto';
import { formatDate } from '@components/ui/Input/utils/formatDate';
import { ISupportForm } from '@components/main/Support/types/ISupportForm';

interface IProps extends ISupportForm {
  onSubmit: HandleThunkActionCreator<
    typeof supportAsyncActions.submitCallSupport
  >;
  getDatesForCall?: HandleThunkActionCreator<
    typeof supportAsyncActions.getDatesForCall
  >;
  datesForCall?: ICallBackResponseDto[];
}

export const SupportCall: FC<IProps> = ({
  isLoading,
  setIsSent,
  userInfo,
  prefill,
  serverError,
  getDatesForCall,
  datesForCall,
  onSubmit,
  onClose,
}) => {
  useEffect(() => {
    void prefill();
    if (getDatesForCall) {
      void getDatesForCall();
    }
  }, [prefill, getDatesForCall]);

  const { validate, fields } = useForm(CallBack);
  const { t } = useTranslation();

  return (
    <SupportWrapper close={onClose} title={t('buttons.callBack')}>
      <Form<CallBack>
        onSubmit={(values) => {
          const date = new Date(values.date);

          date.setDate(date.getDate() + 1);

          void onSubmit({
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            call_dt: `${formatDate(new Date(date))}T${values.time}`,
          });

          setIsSent(true);
        }}
        validate={validate}
        initialValues={userInfo}
        render={({
          handleSubmit,
          submitting,
          touched,
          submitFailed,
          values,
        }) => {
          const filteredDates =
            datesForCall?.filter((el) => {
              const date = new Date(values.date);

              date.setTime(
                date.getTime() - new Date().getTimezoneOffset() * 60 * 1_000,
              );

              return !!values.date && el.date === formatDate(new Date(date));
            }) || [];

          return (
            <SForm onSubmit={handleSubmit}>
              <SInputContainer>
                <SInputNameWrapper>
                  <FinalFormInput
                    id='name'
                    label='Name'
                    name={fields.firstName}
                    placeholder={t('placeholder.firstName')}
                    inProgress={isLoading}
                  />
                </SInputNameWrapper>
                <SInputNameWrapper>
                  <FinalFormInput
                    id='last-name'
                    label={t('form.surname')}
                    name={fields.lastName}
                    placeholder={t('form.surname')}
                    inProgress={isLoading}
                  />
                </SInputNameWrapper>
              </SInputContainer>
              <SElementWrapper>
                <FinalFormInput
                  id='number'
                  type='tel'
                  onKeyUp={(e) => {
                    const target = e.target as HTMLInputElement;

                    target.value = target.value.replace(/[^\d-|()+]/g, '');
                  }}
                  label={t('form.phoneNum')}
                  name={fields.phone}
                  placeholder='+420123123123'
                  inProgress={isLoading}
                />
              </SElementWrapper>
              <SElementWrapper>
                <FinalFormDatePicker
                  id='date-picker'
                  name={fields.date}
                  placeholderText={t('placeholder.date')}
                  label={t('form.dateCall')}
                  // disabled={
                  //   isLoading || !datesForCall || datesForCall.length === 0
                  // }
                  availableDates={datesForCall?.map((el) => el.date)}
                />
              </SElementWrapper>
              <SElementWrapper>
                <FinalFormSelect
                  id='time'
                  name={fields.time}
                  placeholder={t('form.selectDateCall')}
                  label={t('form.timeCall')}
                  disabled={isLoading || !values.date}
                  options={filteredDates[0]?.time.map((el) => el.slice(0, -3))}
                />
              </SElementWrapper>
              {serverError && (
                <SElementWrapper>
                  <ActionStatus>{serverError}</ActionStatus>
                </SElementWrapper>
              )}
              <SButtonWrapper>
                <Button
                  w100={true}
                  size='lg'
                  type='submit'
                  color={submitFailed ? 'error' : 'primary'}
                  disabled={!touched || submitting || isLoading}
                  isLoading={isLoading}
                >
                  {t('buttons.send')}
                </Button>
              </SButtonWrapper>
            </SForm>
          );
        }}
      />
    </SupportWrapper>
  );
};

const SButtonWrapper = styled.div`
  width: 100%;
`;
const SElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const SInputContainer = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 16px;
`;
const SInputNameWrapper = styled.div``;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
