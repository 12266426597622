import { FC } from 'react';
import styled, { css } from 'styled-components';

import { DebtCaseDetailed } from '@components/main/DebtCases/DebtCaseDetailed/DebtCaseDetailed';
import {
  IWithDebtDetailsState,
  withDebtDetailsState,
} from '@pages/DebtDetails/hocs/withDebtDetailsState';
import { LoaderSkeleton } from '@components/main/LoaderSkeleton/LoaderSkeleton';
import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { Button } from '@components/ui/Button/Button';
import { Footer } from '@components/main/Footer';

export const DebtDetailsComponent: FC<IWithDebtDetailsState> = ({
  debtItem,
  isLoading,
  getQrCode,
  paymentPlan,
  getPaymentPlan,
  getPaymentPlanPdf,
  serverError,
}) => (
  <SWrapper isCentered={!!serverError}>
    {isLoading ? (
      <SLoaderWrapper>
        <LoaderSkeleton />
      </SLoaderWrapper>
    ) : debtItem ? (
      <DebtCaseDetailed
        debtItem={debtItem}
        getQrCode={getQrCode}
        getPaymentPlan={getPaymentPlan}
        paymentPlan={paymentPlan}
        getPaymentPlanPdf={getPaymentPlanPdf}
      />
    ) : (
      <SErrorWrapper>
        <ActionStatus>Error</ActionStatus>
        <Button
          buttonType='outlined'
          color='error'
          w100={false}
          size='sm'
          onClick={() => location.reload()}
        >
          Click to update
        </Button>
      </SErrorWrapper>
    )}
    <Footer />
  </SWrapper>
);

const SErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12%;
  row-gap: 20px;
`;
const SLoaderWrapper = styled.div`
  padding: 12%;
`;
const SWrapper = styled.div<{ isCentered: boolean }>`
  display: flex;
  height: calc(100% - 80px);
  overflow-y: scroll;
  flex-direction: column;
  row-gap: 32px;

  ${({ isCentered }) =>
    isCentered &&
    css`
      justify-content: center;
    `};

  @media (max-width: 850px) {
    height: calc(100% - 60px);
  }
`;

export const DebtDetails = withDebtDetailsState(DebtDetailsComponent);
