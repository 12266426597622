import { compile } from 'path-to-regexp';

export const generatePath = (
  path: string,
  params: Record<string, string> | undefined,
): string => {
  if (!params) {
    return path;
  }

  return compile(path)(params);
};
