import { connect, ConnectedProps } from 'react-redux';

import { commonSlice } from '@common/duck/slice';

export const withSupportWidgetState = connect(null, {
  onOpen: commonSlice.actions.openContactUs,
});

export type IWithContactUsWidgetState = ConnectedProps<
  typeof withSupportWidgetState
>;
