import { FC } from 'react';
import { styled as StyledMui } from '@mui/styles';
import { Switch } from '@mui/material';

import { noop } from '@common/utils/functions/noop';
import Cz from '@components/ui/Icon/img/cz.svg';
import Eng from '@components/ui/Icon/img/eng.svg';

interface IProps {
  checked?: boolean;
  onChange?(): void;
}

export const Switcher: FC<IProps> = ({ checked = false, onChange = noop }) => {
  const SwitchCustom = StyledMui(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url(${Cz})`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#8796A5',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#003892',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${Eng})`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#8796A5',
      borderRadius: 20 / 2,
    },
  }));

  return <SwitchCustom checked={checked} onChange={onChange} />;
};
