import { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip, { Place } from 'react-tooltip';
import { createPortal } from 'react-dom';

import { usePortal } from '@common/utils/hooks/usePortal';
import { ZIndex } from '@common/enums/Zindex';

interface ITooltipProps {
  id: string;
  children: ReactNode;
  place?: Place;
}

export const Tooltip: FC<ITooltipProps> = ({ id, ...props }) => {
  const tooltipPortal = usePortal();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props.children]);

  if (!tooltipPortal) {
    return null;
  }

  return createPortal(
    <STooltip>
      <ReactTooltip id={id} {...props} />
    </STooltip>,
    tooltipPortal,
  );
};

const STooltip = styled.div`
  .__react_component_tooltip {
    padding: 12px 14px;
    border-radius: 6px;
    max-width: 325px;
    transition-duration: ${(p) => p.theme.transition};
    background: rgba(24, 24, 24, 0.9);
    z-index: ${ZIndex.TOOLTIP};

    &.show {
      opacity: 1;
    }
  }
`;
