import { Dispatch, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { Button } from '@components/ui/Button/Button';
import { IContact } from '@common/api/dto/features/AuthUser/response/IVerifyUserResponseDto';
import { getContactType } from '@components/main/AuthForm/utils/getContactType';
import { ISelectedContact } from '@components/main/AuthForm/types/ISelectedContact';
import { noop } from '@common/utils/functions/noop';
import { hideMiddleText } from '@common/utils/functions/text/hideMiddleText';
import { SCaptionWrapper } from '@app/BaseFormCard/BaseFormCard';

export interface IVerificationOptionProps {
  verifyOptions?: IContact[] | null;
  selectedContact?: ISelectedContact | null;
  isLoading: boolean;
}

interface IProps extends IVerificationOptionProps {
  onSubmitCode(): void;
  onSelect?: Dispatch<ISelectedContact | null>;
  serverError?: null | string;
}

export const VerificationOption: FC<IProps> = ({
  selectedContact,
  onSelect = noop,
  onSubmitCode,
  verifyOptions,
  serverError,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SOptionsWrapper>
        {verifyOptions?.map((option) => (
          <Button
            key={option.contact_id}
            buttonType='outlined'
            color='secondary'
            w100={true}
            active={option.contact === selectedContact?.contact}
            onClick={() => {
              localStorage.setItem('selected-contact', option.contact);

              onSelect({
                contact: option.contact,
                contactId: option.contact_id,
                contactType: getContactType(option.contact),
              });
            }}
            disabled={isLoading}
          >
            {hideMiddleText(option.contact)}
          </Button>
        ))}
      </SOptionsWrapper>
      <SCaptionWrapper style={{ marginBottom: '0' }}>
        {t('verification.options.hint')}
      </SCaptionWrapper>
      <SElementWrapper>
        {serverError && <ActionStatus>{serverError}</ActionStatus>}
      </SElementWrapper>
      <Button
        w100={true}
        size='lg'
        onClick={onSubmitCode}
        disabled={!selectedContact || isLoading}
        isLoading={isLoading}
      >
        {t('buttons.sendCode')}
      </Button>
    </SWrapper>
  );
};

const SElementWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
const SOptionsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 31px;
  width: 100%;
`;
const SWrapper = styled.div`
  width: 100%;
`;
