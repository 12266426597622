import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { HandleThunkActionCreator } from 'react-redux';

import { useForm } from '@app/FinalForm/hooks/useForm';
import { AuthCreateAccount } from '@components/main/AuthForm/dto/AuthCreateAccount';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { SubmitBtn } from '@components/main/AuthForm/components/CardVariants/components/SubmitBtn';
import { BaseFormCard } from '@app/BaseFormCard/BaseFormCard';
import { lostPasswordAsyncActions } from '@components/main/LostPassword/duck/lostPasswordAsyncActions';
import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';

interface IProps {
  login: string;
  code: string;
  isLoading: boolean;
  onSubmit: HandleThunkActionCreator<
    typeof lostPasswordAsyncActions.changePassword
  >;
  serverError: string | null;
}

export const PasswordSet: FC<IProps> = ({
  isLoading,
  login,
  code,
  onSubmit,
  serverError,
}) => {
  const { validate, fields } = useForm(AuthCreateAccount);
  const { t } = useTranslation();

  return (
    <BaseFormCard
      title={t('lostPassword.resetPassword')}
      caption={t('lostPassword.resetPasswordSubtitle')}
    >
      <Form<AuthCreateAccount>
        onSubmit={async (values) => {
          await onSubmit({
            login,
            code,
            password: values.password,
            password_confirmation: values.passwordConfirmation,
          });
        }}
        validate={validate}
        render={({ handleSubmit, submitting, touched, submitFailed }) => (
          <SForm onSubmit={handleSubmit}>
            <SContent>
              <SElementWrapper>
                <FinalFormInput
                  id='password'
                  label={t('form.password')}
                  name={fields.password}
                  disabled={isLoading}
                  variant='password'
                  placeholder={t('placeholder.typePassword')}
                />
              </SElementWrapper>
              <SElementWrapper>
                <FinalFormInput
                  id='password-again'
                  label={t('form.passwordAgain')}
                  name={fields.passwordConfirmation}
                  disabled={isLoading}
                  variant='password'
                  placeholder={t('placeholder.typePasswordAgain')}
                />
              </SElementWrapper>
              <SElementWrapper>
                {serverError && <ActionStatus>{serverError}</ActionStatus>}
              </SElementWrapper>
            </SContent>
            <SubmitBtn
              error={submitFailed}
              disabled={!touched || submitting || isLoading}
              isLoading={isLoading}
            >
              Reset Password
            </SubmitBtn>
          </SForm>
        )}
      />
    </BaseFormCard>
  );
};

const SContent = styled.div`
  margin-bottom: 28px;
`;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
const SElementWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
