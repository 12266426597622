import { IBasePayload } from '@common/api/types/IBasePayload';
import { ApiResponse } from '@common/api/ApiResponse';

export class ApiErrorDto<Payload extends IBasePayload> extends Error {
  public response?: ApiResponse<Payload>;

  public errorPageType?: string;

  public constructor(response?: ApiResponse<Payload>, message?: string) {
    super(message);

    this.response = response;
  }
}
