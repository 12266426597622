import { RefObject, useEffect, useRef, useState, KeyboardEvent } from 'react';
import keyboardKey from 'keyboard-key';

import { ICodeValidationRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeValidationRequestDto';

interface IParams {
  onSubmit(value: ICodeValidationRequestDto): void;
}

interface IResult {
  inputs: unknown[];
  otp: string[];
  nextInputIndex: number;
  ref: RefObject<HTMLInputElement>;
  onChange(value: string | undefined, idx: number): void;
  onKeyPress(event: KeyboardEvent<HTMLInputElement>, idx: number): void;
}

export const useVerifyCode = ({ onSubmit }: IParams): IResult => {
  const ref = useRef<HTMLInputElement>(null);

  const inputs = Array.from({ length: 4 }).fill('');
  let newInputIndex = 0;
  const [nextInputIndex, setNextInputIndex] = useState(0);

  const [otp, setOtp] = useState(['', '', '', '']);

  const onKeyPress = (
    e: KeyboardEvent<HTMLInputElement>,
    index: number,
  ): void => {
    const code = keyboardKey.getCode(e.key);

    if (code === keyboardKey.Backspace) {
      newInputIndex = index === 0 ? 0 : index - 1;
      setNextInputIndex(newInputIndex);
    }
  };

  const onChange = (text: string, index: number): void => {
    if (!!Number(text) && text.length === 4) {
      setOtp([...text]);

      ref.current?.blur();
      onSubmit(text);

      return;
    }

    if (Number.isNaN(Number(text))) {
      return;
    }

    const newOtp = [...otp];

    newOtp[index] = text.length > 1 ? text[0] : text;
    setOtp(newOtp);
    if (!text) {
      newInputIndex = index === 0 ? 0 : index - 1;
      setNextInputIndex(newInputIndex);
    } else {
      newInputIndex =
        index === inputs.length - 1 ? inputs.length - 1 : index + 1;
      setNextInputIndex(newInputIndex);
    }
  };

  useEffect(() => {
    ref.current?.focus();
  }, [nextInputIndex]);

  return {
    inputs,
    otp,
    nextInputIndex,
    ref,
    onChange,
    onKeyPress,
  };
};
