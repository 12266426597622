import { FC } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ActionStatus } from '@components/ui/ActionStatus/ActionStatus';
import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { useForm } from '@app/FinalForm/hooks/useForm';
import { AuthVerification } from '@components/main/AuthForm/dto/AuthVerification';
import { SubmitBtn } from '@components/main/AuthForm/components/CardVariants/components/SubmitBtn';
import { IVerifyUserRequestDto } from '@common/api/dto/features/AuthUser/request/IVerifyUserRequestDto';
import { noop } from '@common/utils/functions/noop';

export interface IVerificationProps {
  isLoading: boolean;
  onVerify?(value: IVerifyUserRequestDto): void;
}

interface IProps extends IVerificationProps {
  serverError?: null | string;
}

const CLIENT_ZONE_URL = 'https://clientzone.app/';

export const Verification: FC<IProps> = ({
  isLoading,
  onVerify = noop,
  serverError,
}) => {
  const { validate, fields } = useForm(AuthVerification);
  const { t } = useTranslation();

  return (
    <Form<AuthVerification>
      onSubmit={({ firstName, lastName, number }) =>
        onVerify({
          first_name: firstName,
          last_name: lastName,
          personal_number: number,
        })
      }
      validate={validate}
      render={({ handleSubmit, submitting, touched, submitFailed }) => (
        <SForm onSubmit={handleSubmit}>
          <SContent>
            <SElementWrapper>
              <FinalFormInput
                id='name'
                label={t('form.firstName')}
                name={fields.firstName}
                type='text'
                placeholder={t('placeholder.typeName')}
                disabled={isLoading}
              />
            </SElementWrapper>
            <SElementWrapper>
              <FinalFormInput
                id='surname'
                label={t('form.surname')}
                name={fields.lastName}
                disabled={isLoading}
                type='text'
                placeholder={t('placeholder.typeSurname')}
              />
            </SElementWrapper>
            <SElementWrapper>
              <FinalFormInput
                id='number'
                label={t('form.personalNum')}
                name={fields.number}
                disabled={isLoading}
                type='number'
                placeholder={t('form.personalNum.placeholder')}
              />
            </SElementWrapper>
            <SElementWrapper>
              {serverError && <ActionStatus>{serverError}</ActionStatus>}
            </SElementWrapper>
          </SContent>
          <SubmitBtn
            error={submitFailed || !!serverError}
            disabled={!touched || submitting || isLoading}
            isLoading={isLoading}
          >
            {t('buttons.verify')}
          </SubmitBtn>
          <SLinkWrapper>
            <SLink href={CLIENT_ZONE_URL} target='_blank'>
              {t('ClientZone.link')}
            </SLink>
          </SLinkWrapper>
        </SForm>
      )}
    />
  );
};

const SLinkWrapper = styled.div`
  margin-top: 25px;
  text-align: center;
`;
const SLink = styled.a`
  color: ${(p) => p.theme.colors.text.GRAY};
  ${(p) => p.theme.font.header.h6};
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-position: from-font;
`;
const SContent = styled.div`
  margin-bottom: 28px;
`;
const SForm = styled.form`
  padding: 0;
  margin: 0;
  width: 100%;
`;
const SElementWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;
