import { useMemo } from 'react';

import { CaseStatus } from '@components/main/DebtCases/enums/DebtPaymentState';

export const useHasRequisites = (debtPaymentState: CaseStatus): boolean =>
  useMemo(() => {
    switch (debtPaymentState) {
      case CaseStatus.REPAID:
      case CaseStatus.CLOSED:
        return false;
      default:
        return true;
    }
  }, [debtPaymentState]);
