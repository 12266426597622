import { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import TermsFile from '@components/main/TermsPopup/TermsFile.docx';
import TermsFileHeader from '@components/main/TermsPopup/TermsFileHeader.docx';
import TermsFileBottom from '@components/main/TermsPopup/TermsFileBottom.docx';
import { PopupView } from '@app/PopupView/PopupView';
import { Button } from '@components/ui/Button/Button';
import { IconName } from '@components/ui/Icon/enums/IconName';

interface IProps {
  isOpen: boolean;
  toggle(): void;
}

const TermsPopup: FC<IProps> = ({ isOpen, toggle }) => {
  const { t } = useTranslation();

  const refHeader = useRef<HTMLDivElement | null>(null);
  const refContent = useRef<HTMLDivElement | null>(null);
  const refBottom = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!refHeader.current || !refContent.current || !refBottom.current) {
      return;
    }

    refHeader.current.innerHTML = TermsFileHeader;
    refContent.current.innerHTML = TermsFile;
    refBottom.current.innerHTML = TermsFileBottom;
  }, [refHeader.current, refContent.current, refBottom.current]);

  return (
    <PopupView isOpen={isOpen} close={toggle}>
      <SWrapper>
        <SCloseBtn>
          <Button
            buttonType='clickable-icon'
            icon={{ name: IconName.CLOSE }}
            onClick={toggle}
          />
        </SCloseBtn>
        <SContent ref={refHeader} center='center' />
        <SContent ref={refContent} />
        <SContent ref={refBottom} center='right' />
        <Button onClick={toggle}>{t('agree')}</Button>
      </SWrapper>
    </PopupView>
  );
};

export default TermsPopup;

const SCloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 5%;
  overflow-y: overlay;
  position: relative;
  width: 70vw;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.base.WHITE};
`;
const SContent = styled.div<{ center?: 'left' | 'center' | 'right' }>`
  ${(p) => p.theme.font.body.body};
  word-break: break-word;
  color: ${(p) => p.theme.colors.text.BODY};

  ${({ center = 'left' }) =>
    css`
      text-align: ${center};
    `}
`;
