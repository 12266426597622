import { FC, SVGProps } from 'react';
import styled, { css } from 'styled-components';

import { IconName } from '@components/ui/Icon/enums/IconName';
import { TIconSize } from '@components/ui/Icon/types/TIconSize';
import { getIconByName } from '@components/ui/Icon/utils/getIconByName';

export interface IIconProps extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  name: IconName;
  size?: TIconSize;
  color?: string;
}

export const Icon: FC<IIconProps> = ({
  name,
  size = 'md',
  color,
  className,
}) => (
  <SStyledIcon
    as={getIconByName(name)}
    color={color}
    size={size}
    className={className}
  />
);

const SStyledIcon = styled.svg<{ color?: string; size: TIconSize }>`
  flex-shrink: 0;

  ${(p) =>
    p.color &&
    css`
      color: ${p.color};
    `};

  ${({ size }) => {
    switch (size) {
      default:
      case 'sm':
        return css`
          height: 20px;
          width: 20px;
        `;
      case 'md':
        return css`
          height: 24px;
          width: 24px;
        `;
      case 'lg':
        return css`
          height: 35px;
          width: 35px;
        `;
    }
  }};
`;
