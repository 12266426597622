import { FC } from 'react';
import styled from 'styled-components';

export const OptionsPopup: FC = ({ children }) => (
  <SWrapper>{children}</SWrapper>
);

const SWrapper = styled.div`
  overflow: hidden;
  width: 220px;
  border: 1px solid ${(p) => p.theme.colors.base.LIGHT};
  background: ${(p) => p.theme.colors.base.WHITE};
  border-radius: 8px;
  ${(p) => p.theme.shadows.common};
`;
