import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/ui/Button/Button';
import { IPaymentPlan } from '@components/main/DebtCases/types/IDebtCaseDetailed';

interface IProps {
  plan: IPaymentPlan;
  onClick(): void;
}

export const Element: FC<IProps> = ({ plan, onClick }) => {
  const { t } = useTranslation();

  return (
    <SWrapper onClick={onClick}>
      <SCol>
        <SElement minWidth={true}>{plan.installmentId}</SElement>
        <SElement>{plan.dueDate.split('-').reverse().join('.')}</SElement>
      </SCol>
      <SCol>
        <SElement>{plan.monthlyPayment} Kč</SElement>
        <SButton buttonType='text' underline={true}>
          {t('buttons.payNow')}
        </SButton>
      </SCol>
    </SWrapper>
  );
};

const SButton = styled(Button)`
  color: ${(p) => p.theme.colors.text.BODY};
`;
const SCol = styled.div`
  display: flex;
  column-gap: 34px;
`;
const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(p) => p.theme.colors.base.DARK_WHITE};
`;
const SElement = styled.div<{ minWidth?: boolean }>`
  padding: 16px 12px;
  color: ${(p) => p.theme.colors.text.BODY};

  ${({ minWidth = false }) =>
    minWidth &&
    css`
      min-width: 75px;
    `}
`;
