import { IBasePayload } from '@common/api/types/IBasePayload';

export interface IData<Payload extends IBasePayload> {
  status: number;
  data: Payload;
  statusText: string;
}

export class ApiResponse<Payload extends IBasePayload> {
  public status: number;

  public data: Payload;

  public statusText: string;

  public constructor({ status, data, statusText }: IData<Payload>) {
    this.status = status;
    this.data = data;
    this.statusText = statusText;
  }
}
