import { useTranslation } from 'react-i18next';

const hasValue = (str: string): boolean => str?.includes('{{');
const getValue = (str: string): { value: string; withoutValue: string } => {
  const value = str.slice(str.indexOf('{{')).slice(2, -2);
  const withoutValue = str.slice(0, str.indexOf('{{'));

  return { value, withoutValue };
};

export const useWithTranslation = (
  error: string | null,
): string | undefined => {
  const { t, i18n } = useTranslation();

  if (!error) {
    return;
  }

  const value = hasValue(error)
    ? t(getValue(error).withoutValue, {
        value: getValue(t(error)).value,
      })
    : error;

  return i18n.exists(error) ? t(error) : value;
};
