import { IRootState } from '@common/redux/store';
import { Feature } from '@common/enums/Feature';
import { StatelessPage } from '@common/enums/Page';

export const getFeatureSelector =
  <
    FeatureName extends
      | Exclude<Feature, Feature.PAGE>
      | StatelessPage
      | 'common',
  >(
    feature: FeatureName,
  ) =>
  (state: IRootState): IRootState[FeatureName] =>
    state[feature];
