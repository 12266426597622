import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { getApiPageRoute } from '@common/utils/routing/getApiPageRoute';
import { TPageRouteInfo } from '@app/PageWrapper/types/TPageRouteInfo';
import { IPageResponseDto } from '@common/api/dto/features/Page/response/IPageResponse';

class ApiPage extends ApiServiceBase {
  public constructor() {
    super();
  }

  public init(
    pageRouteName: TPageRouteInfo,
  ): Promise<ApiResponse<IPageResponseDto>> {
    return this.get<IPageResponseDto>(getApiPageRoute(pageRouteName));
  }
}

export const apiPage = new ApiPage();
