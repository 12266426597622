import { FC, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { Switcher } from '@components/ui/Switcher/Switcher';

export const LngSwitch: FC = () => {
  const [switched, setSwitched] = useState(
    window.localStorage.getItem('lng') === 'cz',
  );

  const handleChange = async (): Promise<void> => {
    window.localStorage.setItem('lng', switched ? 'en' : 'cz');
    await (switched ? i18n.changeLanguage('en') : i18n.changeLanguage('cz'));
    setSwitched(!switched);
  };

  const { t } = useTranslation();
  const curLang = navigator.language;

  useEffect(() => {
    document.title = t('main.page.title');

    if (!window.localStorage.getItem('lng')) {
      i18n.on('initialized', () => {
        // Language specific options

        if (curLang.includes('en')) {
          setSwitched(false);
        }
        if (curLang === 'cs') {
          setSwitched(true);
        }
      });
    }
  }, [t, curLang]);

  return <Switcher checked={switched} onChange={handleChange} />;
};
