// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined } from 'class-validator';

export class CreatePayDto {
  @IsDefined({
    message: 'errorMessage.isOptionDefined',
  })
  public option!: string;

  @IsDefined({
    message: 'errorMessage.firstDueDate',
  })
  public date!: Date;
}
