import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';

class QrCode extends ApiServiceBase {
  public constructor() {
    super();
  }

  public fetch(id: number): Promise<ApiResponse<IBasePayload>> {
    return this.get(`/api/v1/cases/qr/${id}`);
  }
}

export const qrCode = new QrCode();
