import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { AuthForm, IAuthProps } from '@components/main/AuthForm/AuthForm';
import { AuthFormVariant } from '@components/main/AuthForm/enums/AuthFormVariant';
import { TAuthStep } from '@pages/Auth/types/TAuthStep';
import { hideMiddleText } from '@common/utils/functions/text/hideMiddleText';
import { Page } from '@common/enums/Page';
import { ICodeSubmitRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeSubmitRequestDto';
import { ICodeValidationRequestDto } from '@common/api/dto/features/AuthUser/request/ICodeValidationRequestDto';
import { ICreateAccountRequestDto } from '@common/api/dto/features/AuthUser/request/ICreateAccountRequestDto';
import { ISelectedContact } from '@components/main/AuthForm/types/ISelectedContact';

interface IRegistrationProps extends IAuthProps {
  authStep: TAuthStep;
  stepBack(value?: TAuthStep): void;
  onSendCode(value: ICodeSubmitRequestDto): void;
  onValidateCode(value: ICodeValidationRequestDto): void;
  onCreateAccount(value: ICreateAccountRequestDto): void;
}

export const Registration: FC<IRegistrationProps> = ({
  authStep,
  stepBack,
  verifyOptions,
  isLoading,
  ...props
}) => {
  const navigate = useNavigate();

  const [selectedContact, setSelectedContact] =
    useState<ISelectedContact | null>(null);

  const contact = localStorage.getItem('selected-contact');

  const { t } = useTranslation();

  return (
    <>
      {authStep === AuthFormVariant.VERIFICATION && (
        <AuthForm
          title={t('registration.stepOne.title')}
          caption={t('registration.stepOne.subtitle')}
          variant={AuthFormVariant.VERIFICATION}
          stepBack={() => navigate(`/${Page.AUTH}`)}
          isLoading={isLoading}
          {...props}
        />
      )}
      {authStep === AuthFormVariant.VERIFICATION_OPTION && (
        <AuthForm
          title={t('registration.stepTwo.title')}
          caption={t('registration.stepTwo.caption')}
          subtitle={t('registration.stepTwo.subtitle')}
          variant={AuthFormVariant.VERIFICATION_OPTION}
          verifyOptions={verifyOptions}
          isLoading={isLoading}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          stepBack={() => stepBack(AuthFormVariant.VERIFICATION_OPTION)}
          {...props}
        />
      )}
      {authStep === AuthFormVariant.CODE_VALIDATION && (
        <AuthForm
          title={t('registration.stepTwo.title')}
          caption={t('registration.stepThree.caption', {
            value: hideMiddleText(
              `${selectedContact?.contact || contact || 'undefined'}`,
            ),
          })}
          subtitle={t('registration.stepFour.subtitle')}
          variant={AuthFormVariant.CODE_VALIDATION}
          isLoading={isLoading}
          selectedContact={selectedContact}
          stepBack={() => stepBack(AuthFormVariant.CODE_VALIDATION)}
          {...props}
        />
      )}
      {authStep === AuthFormVariant.CREATE_ACCOUNT && (
        <AuthForm
          title={t('registration.stepFour.title')}
          caption={t('registration.stepFour.caption')}
          variant={AuthFormVariant.CREATE_ACCOUNT}
          isLoading={isLoading}
          stepBack={() => stepBack(AuthFormVariant.CREATE_ACCOUNT)}
          {...props}
        />
      )}
    </>
  );
};
