import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import {
  isHeaderLoadingSelector,
  userInfoHeaderSelector,
} from '@components/main/Header/duck/selectors';
import { headerSlice } from '@components/main/Header/duck/slice';
import { headerAsyncActions } from '@components/main/Header/duck/headerAsyncActions';

export const withHeaderState = connect(
  (state: IRootState) => ({
    isLoading: isHeaderLoadingSelector(state),
    userInfo: userInfoHeaderSelector(state),
  }),
  {
    ...headerSlice.actions,
    ...headerAsyncActions,
  },
);

export type IWithHeaderState = ConnectedProps<typeof withHeaderState>;
