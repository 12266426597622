import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HandleThunkActionCreator } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { useHasRequisites } from '@components/main/DebtCases/DebtCaseDetailed/components/MainInfo/components/PayInfo/hooks/useHasRequisites';
import { InfoText } from '@components/main/DebtCases/DebtCaseDetailed/components/MainInfo/components/PayInfo/components/InfoText';
import { ActionButtons } from '@components/main/DebtCases/components/ActionButtons/ActionButtons';
import { PayInfo } from '@components/main/DebtCases/components/PayInfo/PayInfo';
import { IDebtCaseDetailed } from '@components/main/DebtCases/types/IDebtCaseDetailed';
import { ICheckCreatePlan } from '@pages/DebtCases/duck/debtCasesAsyncActions';
import { debtDetailsAsyncActions } from '@pages/DebtDetails/duck/debtDetailsAsyncActions';
import { StatefulPage } from '@common/enums/Page';

interface IProps {
  debtItem: IDebtCaseDetailed;
  createPlanData: ICheckCreatePlan | boolean;
  getQrCode: HandleThunkActionCreator<typeof debtDetailsAsyncActions.getQr>;
}

export const PaymentDetails: FC<IProps> = ({
  debtItem,
  createPlanData,
  getQrCode,
}) => {
  //TODO finish up with requisites
  const hasRequisites = useHasRequisites(debtItem.caseStatus);
  const { t } = useTranslation();

  const [revisited, setRevisited] = useState(false);

  const location = useLocation();

  const locationState = location.state as string | null;

  useEffect(() => {
    if (locationState?.includes(StatefulPage.DEBT_DETAILS)) {
      setRevisited(true);
    }

    const a = setTimeout(() => {
      location.state = null;
      setRevisited(false);
    }, 2_000);

    return () => {
      clearTimeout(a);
    };
  }, [revisited, locationState, location]);

  useEffect(() => {
    void getQrCode(debtItem.caseData.id);
  }, [getQrCode, debtItem.caseData.id]);

  if (hasRequisites) {
    return (
      <>
        <SContainerMain datat-tip={true} data-for='payment-info'>
          <SWrapper isColumn={hasRequisites}>
            <InfoText
              title={t('details.payInfo')}
              subtitle={t('payInfo.text')}
            />
            <SBorder hasPadding={false} />
            <Tooltip
              id='payment-info'
              title={<STooltipTitle>{t('tooltip.payinfoBelow')}</STooltipTitle>}
              arrow={true}
              open={revisited}
              placement='top'
            >
              <SContainer>
                <PayInfo
                  size='lg'
                  variant={debtItem.caseStatus}
                  paymentInfo={debtItem.caseData}
                  symbol={debtItem.creditor.variableSymbol}
                  bankAccount={debtItem.creditor.account}
                />
              </SContainer>
            </Tooltip>
          </SWrapper>
          {debtItem.creditor.qrCode && (
            <SImg alt='qr-code' src={debtItem.creditor.qrCode} />
          )}
        </SContainerMain>
        <SActionsWrapper>
          <ActionButtons
            isPlanAllowed={!!createPlanData}
            debtId={debtItem.caseData.id}
            variant={debtItem.caseStatus}
          />
        </SActionsWrapper>
      </>
    );
  }

  return (
    <>
      <SWrapper isColumn={hasRequisites}>
        <InfoText
          title={`${t('details.servicedBy')} Fincollect`}
          subtitle={t('details.noRequisites.text')}
        />
        <SContainer>
          <PayInfo
            size='lg'
            paymentInfo={debtItem.caseData}
            symbol={debtItem.caseData.externalId}
            variant={debtItem.caseStatus}
          />
        </SContainer>
      </SWrapper>
      <SBorder hasPadding={true} />
      <SActionsWrapper>
        <ActionButtons
          isPlanAllowed={!!createPlanData}
          variant={debtItem.caseStatus}
          debtId={debtItem.caseData.id}
        />
      </SActionsWrapper>
    </>
  );
};

const STooltipTitle = styled.div`
  ${(p) => p.theme.font.header.h4};
`;
const SContainerMain = styled.div`
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
  row-gap: 20px;
`;

const SContainer = styled.div`
  display: flex;
  column-gap: 5rem;
  flex-wrap: wrap;
  row-gap: 20px;
`;
const SActionsWrapper = styled.div`
  padding-top: 30px;
`;
const SBorder = styled.div<{ hasPadding: boolean }>`
  ${({ hasPadding }) =>
    hasPadding &&
    css`
      padding-top: 24px;
    `}
  border-bottom: 1px solid ${(p) => p.theme.colors.base.LIGHT};
  height: 1px;
  width: 90%;
`;
const SWrapper = styled.div<{ isColumn: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isColumn }) =>
    isColumn &&
    css`
      align-items: stretch;
      flex-direction: column;
    `}
  flex-wrap: wrap;
  row-gap: 20px;
  flex-grow: 1;
`;
const SImg = styled.img`
  width: 145px;
  height: 145px;
`;
