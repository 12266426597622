import { IClass } from '@common/types/object/IClass';

export type IKeys<Dto extends IClass> = {
  [key in keyof Dto['prototype']]: key;
};
export const getClassFields = <TDto extends IClass>(Dto: TDto): IKeys<TDto> => {
  const fields = {};

  Object.keys(new Dto()).forEach((key) => {
    fields[key] = key;
  });

  return fields as IKeys<TDto>;
};
