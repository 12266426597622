import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import { openedSupportSelector } from '@common/duck/selectors';
import { commonSlice } from '@common/duck/slice';
import { supportAsyncActions } from '@components/main/Support/duck/supportAsyncActions';
import {
  isLoadingSelector,
  serverErrorSelector,
  userInfoSelector,
  datesForCallSelector,
} from '@components/main/Support/duck/selectors';
import { isAuthorizedSelector } from '@pages/Auth/duck/selectors';
import { supportSlice } from '@components/main/Support/duck/slice';

export const withSupportState = connect(
  (state: IRootState) => ({
    openedSupportState: openedSupportSelector(state),
    isLoading: isLoadingSelector(state),
    userInfo: userInfoSelector(state),
    serverError: serverErrorSelector(state),
    isAuthorized: isAuthorizedSelector(state),
    datesForCall: datesForCallSelector(state),
  }),
  {
    onClose: commonSlice.actions.closeContactUs,
    onRestoreError: supportSlice.actions.restoreError,
    ...supportAsyncActions,
  },
);

export type IWithSupportState = ConnectedProps<typeof withSupportState>;
