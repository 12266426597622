import { connect, ConnectedProps } from 'react-redux';

import { IRootState } from '@common/redux/store';
import {
  errorSelector,
  isProfileLoading,
  profileData,
} from '@pages/Profile/duck/selectors';
import { profileAsyncActions } from '@pages/Profile/duck/profileAsyncActions';

export const withProfileState = connect(
  (state: IRootState) => ({
    isLoading: isProfileLoading(state),
    userInfo: profileData(state),
    serverError: errorSelector(state),
  }),
  {
    ...profileAsyncActions,
  },
);

export type IWithProfileState = ConnectedProps<typeof withProfileState>;
