import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FinalFormInput } from '@app/FinalForm/components/FinalFormInput';
import { IKeys } from '@common/utils/class/getClassFields';
import { ProfileInfoDto } from '@pages/Profile/dto/ProfileInfoDto';

interface IContactDetailsProps {
  fields: IKeys<typeof ProfileInfoDto>;
  isLoading: boolean;
  isMailDisabled: boolean;
  isPhoneDisabled: boolean;
}

export const ContactDetails: FC<IContactDetailsProps> = ({
  fields,
  isLoading,
  isPhoneDisabled,
  isMailDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SLabel>{t('profile.personalContactTitle')}</SLabel>
      <SInputsContainer>
        <SInputWrapper>
          <FinalFormInput
            id='first-name'
            name={fields.firstName}
            inProgress={isLoading}
            placeholder={t('form.firstName')}
            label={t('form.firstName')}
            disabled={true}
          />
        </SInputWrapper>
        <SInputWrapper>
          <FinalFormInput
            id='last-name'
            name={fields.lastName}
            inProgress={isLoading}
            placeholder={t('form.surname')}
            label={t('form.surname')}
            disabled={true}
          />
        </SInputWrapper>
      </SInputsContainer>
      <SInputsContainer>
        <SInputWrapper>
          <FinalFormInput
            id='phone'
            name={fields.phone}
            inProgress={isLoading}
            // disabled={isPhoneDisabled}
            placeholder='+420123456789'
            label={t('form.phoneNum')}
            disabled={true}
          />
        </SInputWrapper>
        <SInputWrapper>
          <FinalFormInput
            id='email'
            name={fields.email}
            // disabled={isMailDisabled}
            inProgress={isLoading}
            placeholder={t('form.email')}
            label='Email'
            disabled={true}
          />
        </SInputWrapper>
      </SInputsContainer>
    </SWrapper>
  );
};

const SInputWrapper = styled.div`
  width: 100%;
`;
const SInputsContainer = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 16px;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    row-gap: 16px;
  }
`;
const SLabel = styled.div`
  ${(p) => p.theme.font.body.body};
  margin-bottom: 16px;
`;
const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.base.DARK_WHITE};
`;
