import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';

const lostPasswordSelector = getFeatureSelector(Feature.LOST_PASSWORD);

export const isLostPasswordLoadingOpenSelector = createSelector(
  lostPasswordSelector,
  ({ isLoading }) => isLoading,
);

export const isLostPasswordServerErrorSelector = createSelector(
  lostPasswordSelector,
  ({ serverError }) => serverError,
);

export const isLostPasswordOpenSelector = createSelector(
  lostPasswordSelector,
  ({ isLostPasswordOpen }) => isLostPasswordOpen,
);

export const lostPasswordVariantSelector = createSelector(
  lostPasswordSelector,
  ({ currentVariant }) => currentVariant,
);
