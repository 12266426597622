// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsDefined } from 'class-validator';

import { SupportForm } from '@components/main/Support/dto/SupportForm';

export class CallBack extends SupportForm {
  @IsDefined({
    message: 'errorMessage.isDateDefined',
  })
  public date!: Date;

  @IsDefined({
    message: 'errorMessage.isOptionDefined',
  })
  public time!: string;
}
