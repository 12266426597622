import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';

const support = getFeatureSelector(Feature.SUPPORT);

export const isLoadingSelector = createSelector(
  support,
  ({ isLoading }) => isLoading,
);

export const userInfoSelector = createSelector(
  support,
  ({ userInfo }) => userInfo,
);

export const serverErrorSelector = createSelector(
  support,
  ({ serverError }) => serverError,
);

export const datesForCallSelector = createSelector(
  support,
  ({ datesForCall }) => datesForCall,
);
