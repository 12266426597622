import { ICreatePromiseRequestDto } from '@common/api/dto/features/CreatePayment/request/ICreatePromiseRequestDto';
import { ApiServiceBase } from '@common/api/ApiServiceBase';
import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { ICreatePaymentPlanRequestDto } from '@common/api/dto/features/CreatePayment/request/ICreatePaymentPlanRequestDto';

class CreatePayment extends ApiServiceBase {
  public constructor() {
    super();
  }

  public createPromise(
    dto: ICreatePromiseRequestDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/cases/ptp', dto);
  }

  public createPaymentPlan(
    dto: ICreatePaymentPlanRequestDto,
  ): Promise<ApiResponse<IBasePayload>> {
    return this.post('/api/v1/cases/payment_plan', dto);
  }
}

export const createPayment = new CreatePayment();
