import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Feature } from '@common/enums/Feature';
import { IDebtDetailsResponseDto } from '@common/api/dto/features/DebtDetails/response/IDebtDetailsResponseDto';
import {
  IDebtCaseDetailed,
  IPaymentPlan,
} from '@components/main/DebtCases/types/IDebtCaseDetailed';
import { debtDetailsAsyncActions } from '@pages/DebtDetails/duck/debtDetailsAsyncActions';
import { IPaymentPlanDto } from '@common/api/dto/features/PaymentPlan/response/IPaymentPlanDto';

interface IState {
  debtItem: IDebtCaseDetailed | null;
  paymentPlan: IPaymentPlan[] | null;
}

export const debtDetailsSlice = createSlice({
  name: Feature.DEBT_DETAILS,
  initialState: {
    debtItem: null,
    paymentPlan: null,
  } as IState,
  reducers: {
    setState: (draft, { payload }: PayloadAction<IDebtDetailsResponseDto>) => {
      const {
        case_status,
        case_data,
        creditor,
        debtor,
        payment_history,
        pp_is_allowed,
      } = payload;

      draft.debtItem = {
        caseStatus: case_status,
        caseData: {
          id: case_data.id,
          externalId: case_data.external_id,
          dueDate: case_data.due_date,
          totalDebt: case_data.total_debt,
          payment: case_data.payment,
        },
        debtor: {
          firstName: debtor.first_name,
          lastName: debtor.last_name,
        },
        creditor: {
          name: creditor.business_partner_name,
          logoUrl: creditor.logo_url,
          address: creditor.address,
          email: creditor.email,
          phones: creditor.phones,
          variableSymbol: creditor.variable_symbol,
          account: creditor.account,
          qrCode: null,
        },
        paymentHistory: payment_history,
        ppIsAllowed: pp_is_allowed,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      debtDetailsAsyncActions.getQr.fulfilled.type,
      (draft, { payload }: PayloadAction<string>) => {
        if (draft.debtItem) {
          draft.debtItem.creditor.qrCode = payload;
        }
      },
    );
    builder.addCase(
      debtDetailsAsyncActions.getPaymentPlan.fulfilled.type,
      (draft, { payload }: PayloadAction<IPaymentPlanDto[]>) => {
        draft.paymentPlan = payload.map((item) => ({
          installmentId: item.installment_id,
          dueDate: item.due_date,
          monthlyPayment: item.monthly_payment,
        }));
      },
    );
  },
});
