import { createSelector } from '@reduxjs/toolkit';

import { getFeatureSelector } from '@common/redux/selectors/getFeatureSelector';
import { Feature } from '@common/enums/Feature';

const createPaymentSelector = getFeatureSelector(Feature.CREATE_PAYMENT);

export const isLoadingSelector = createSelector(
  createPaymentSelector,
  ({ isLoading }) => isLoading,
);

export const serverErrorSelector = createSelector(
  createPaymentSelector,
  ({ serverError }) => serverError,
);

export const createPlanOptionsSelector = createSelector(
  createPaymentSelector,
  ({ createPlanOptions }) => createPlanOptions,
);

export const promiseOptionsSelector = createSelector(
  createPaymentSelector,
  ({ promiseOptions }) => promiseOptions,
);

export const submitSuccessSelector = createSelector(
  createPaymentSelector,
  ({ submitSuccess }) => submitSuccess,
);
