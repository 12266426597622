import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { AnyObject } from 'immer/dist/types/types-internal';

import { ApiResponse } from '@common/api/ApiResponse';
import { IBasePayload } from '@common/api/types/IBasePayload';
import { isAxiosError } from '@common/api/typeGuards/isAxiosError';
import { ApiErrorDto } from '@common/api/ApiErrorDto';
import { ApiPageError } from '@common/api/ApiPageError';
import { ErrorPageType } from '@common/api/enums/ErrorPageType';
import { HttpStatus } from '@common/api/enums/HttpStatus';

const SERVER_API = process.env.REACT_APP_BASE_URL;

export const apiAxiosInstance = Axios.create({
  withCredentials: true,
  baseURL: SERVER_API,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': window.localStorage.getItem('lng') || 'eng',
  },
});

export abstract class ApiServiceBase {
  private readonly axios: AxiosInstance = apiAxiosInstance;

  protected constructor() {
    this.axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers && sessionStorage.getItem('accessToken')) {
        // eslint-disable-next-line no-param-reassign,@typescript-eslint/restrict-template-expressions
        config.headers.Authorization = `Bearer ${sessionStorage.getItem(
          'accessToken',
        )}`;
      }

      return config;
    });

    this.axios.interceptors.response.use(
      undefined,
      (error: AxiosError<ApiResponse<AnyObject>>) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!error.response) {
          throw new ApiPageError(ErrorPageType.ACCESS_DENIED);
        }

        const errorCode = error.response?.status;

        if (errorCode === HttpStatus.FORBIDDEN) {
          throw new ApiPageError(ErrorPageType.ACCESS_DENIED, error.response);
        }
        if (errorCode === HttpStatus.NOT_FOUND) {
          throw new ApiPageError(ErrorPageType.NOT_FOUND, error.response);
        }

        throw new ApiPageError(ErrorPageType.ERROR, error.response);
      },
    );

    // this.axios.interceptors.response.use(
    //   (config) => config,
    //   async (error: AxiosError) => {
    //     const originalRequest = error.config;
    //
    //     if (
    //       error.response?.status === HttpStatus.UNAUTHORIZED &&
    //       !error.config
    //     ) {
    //       try {
    //         const response = await Axios.get<ApiResponse<ILogInResponseDto>>(
    //           '/refresh/',
    //           {
    //             withCredentials: true,
    //           },
    //         );
    //
    //         localStorage.setItem(
    //           'accessToken',
    //           response.data.payload.accessToken,
    //         );
    //
    //         return await this.axios.request(originalRequest);
    //       } catch (error_) {
    //         console.log(error_);
    //       }
    //     }
    //   },
    // );
  }

  protected get<Payload extends IBasePayload>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<Payload>> {
    return ApiServiceBase.response<Payload>(
      this.axios.get(url, {
        ...config,
        headers: {
          'Accept-Language': window.localStorage.getItem('lng') || 'eng',
        },
      }),
    );
  }

  protected post<
    Request extends AnyObject | string,
    Payload extends IBasePayload,
  >(
    url: string,
    data?: Request,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<Payload>> {
    return ApiServiceBase.response<Payload>(
      this.axios.post(url, data, {
        ...config,
        headers: {
          'Accept-Language': window.localStorage.getItem('lng') || 'eng',
        },
      }),
    );
  }

  private static async response<Response extends AnyObject>(
    promise: AxiosPromise,
  ): Promise<ApiResponse<Response>> {
    return ApiServiceBase.createResponseFromAxios<Response>(promise);
  }

  private static isApiResponseType<Payload extends IBasePayload>(
    response: AnyObject,
  ): response is ApiResponse<Payload> {
    return (
      (response && Number.isInteger(response.status)) ||
      (typeof response.statusText === 'string' &&
        (typeof response.data === 'object' ||
          Array.isArray(response.data) ||
          typeof response.data === 'string'))
    );
  }

  private static transformResponse<Payload extends IBasePayload>(
    response: AxiosResponse,
  ): ApiResponse<Payload> | never {
    if (ApiServiceBase.isApiResponseType<Payload>(response)) {
      return new ApiResponse<Payload>(response);
    }

    throw new TypeError(
      `There is wrong response format: ${JSON.stringify(response)}`,
    );
  }

  private static async createResponseFromAxios<Payload extends IBasePayload>(
    promise: AxiosPromise,
  ): Promise<ApiResponse<Payload>> {
    try {
      const response = await promise;

      return ApiServiceBase.transformResponse(response);
    } catch (error) {
      if (isAxiosError(error)) {
        const { response } = error;

        if (!response) {
          throw new TypeError(
            `There is no response in axios error: ${JSON.stringify(error)}`,
          );
        }

        throw new ApiErrorDto(ApiServiceBase.transformResponse(response));
      }

      throw error;
    }
  }
}
