import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DebtDetailedWrapper } from '@components/main/DebtCases/DebtCaseDetailed/components/DebtDetailedWrapper';
import { InfoColumn } from '@components/main/DebtCases/components/InfoColumn/InfoColumn';
import { AnchorTabs } from '@components/main/DebtCases/DebtCaseDetailed/components/TabAnchors/enums/AnchorTabs';
import { IDebtCaseDetailed } from '@components/main/DebtCases/types/IDebtCaseDetailed';

interface IProps {
  debtItem: IDebtCaseDetailed;
}

export const DebtInfo: FC<IProps> = ({ debtItem }) => {
  const { t } = useTranslation();

  return (
    <DebtDetailedWrapper>
      <STitle>{t('details.tabs.debtInfo')}</STitle>
      <SIndicatorsWrapper>
        <InfoColumn
          title={t('debtsFeedCase.totalDebt')}
          value={`${debtItem.caseData.totalDebt} Kč`}
        />
        <InfoColumn
          title={t('form.name')}
          value={`${debtItem.debtor.firstName} ${debtItem.debtor.lastName}`}
        />
        <InfoColumn
          title={t('details.id')}
          value={debtItem.caseData.externalId}
        />
        <InfoColumn
          title={t('details.creditor')}
          value={debtItem.creditor.name}
        />
      </SIndicatorsWrapper>
      <SLabel id={AnchorTabs.DEBT_INFO}>{t('details.servicedBy')}</SLabel>
      <SContactInfoWrapper>
        <SCompanyName>
          Fincollect SE <SUrl>www.fincollect.cz</SUrl>
        </SCompanyName>
        <InfoColumn
          title={t('details.servicedBy')}
          value='Fincollect SE'
          size='sm'
        />
        <InfoColumn
          title={`${t('details.contactPhone')}:`}
          value='info@fincollect.cz'
          size='sm'
        />
        <InfoColumn
          title={`${t('details.contactPhone')}:`}
          value='+420295562180'
          size='sm'
        />
        <InfoColumn
          title={t('details.address')}
          value='Londýnská 730/59, Praha 2 - Vinohrady, 12000 '
          size='sm'
        />
      </SContactInfoWrapper>
    </DebtDetailedWrapper>
  );
};

const SUrl = styled.div`
  ${(p) => p.theme.font.header.h6};
  padding-top: 12px;
`;
const SCompanyName = styled.div`
  ${(p) => p.theme.font.header.h4};
`;
const SContactInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
  row-gap: 25px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;
const SLabel = styled.div`
  ${(p) => p.theme.font.header.h4};
  padding: 5% 0;
`;
const SIndicatorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 36px;
`;
const STitle = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.base.LIGHT};
  padding-bottom: 32px;
  ${(p) => p.theme.font.header.h3}
`;
