import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IconName } from '@components/ui/Icon/enums/IconName';
import { Icon } from '@components/ui/Icon/Icon';

export const ErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SIcon name={IconName.ALERT} />
      <STitle>{t('error.pageCrash')}</STitle>
    </SWrapper>
  );
};

const SIcon = styled(Icon)`
  width: 64px;
  height: 64px;
`;
const SWrapper = styled.div`
  height: 100vh;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const STitle = styled.div`
  ${(props) => props.theme.font.header.h1};
  text-align: center;
`;
