import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Auth } from '@pages/Auth/index.page';
import { Page, StatefulPage } from '@common/enums/Page';
import { DebtsFeed } from '@pages/DebtCases/index.page';
import { Profile } from '@pages/Profile/index.page';
import {
  IWithPageWrapperState,
  withPageWrapperState,
} from '@app/PageWrapper/hocs/withPageWrapperState';
import { Page404 } from '@pages/404/index.page';
import { FAQ } from '@pages/FAQ/index.page';
import { DebtDetails } from '@pages/DebtDetails/index.page';
import { CurrentPage } from '@app/CurrentPage/CurrentPage';
import { RequireAuth } from '@app/PageWrapper/components/RequireAuth';
import { Payment } from '@pages/CreatePayment/index.page';

const PageWrapperComponent: FC<IWithPageWrapperState> = ({
  isAuthorized,
  checkAuth,
}) => (
  <Routes>
    <Route
      element={
        <RequireAuth isAuthorized={isAuthorized} checkAuth={checkAuth} />
      }
    >
      <Route path='/' element={<CurrentPage />}>
        <Route path={StatefulPage.DEBTS} element={<DebtsFeed />} />
        <Route
          path={`/${StatefulPage.DEBTS}/${StatefulPage.DEBT_DETAILS}/:debtId`}
          element={<DebtDetails />}
        />
        <Route path={StatefulPage.PROFILE} element={<Profile />} />
        <Route path={Page.FAQ} element={<FAQ />} />
        <Route path='/' element={<Navigate to={StatefulPage.DEBTS} />} />
        <Route path={`/${Page.CREATE_PAY}/:debtId`} element={<Payment />} />
        <Route path={`/${Page.PROMISE_PAY}/:debtId`} element={<Payment />} />
        <Route path={`/${Page.INSTANT_PAY}/:debtId`} element={<Payment />} />
      </Route>
    </Route>
    <Route path={`/${Page.AUTH}/*`} element={<Auth />} />
    <Route
      path={Page.PAGE_404}
      element={<Page404 to={isAuthorized ? StatefulPage.DEBTS : Page.AUTH} />}
    />
    <Route path='*' element={<Navigate to={Page.PAGE_404} />} />
  </Routes>
);

export const PageWrapper = withPageWrapperState(PageWrapperComponent);
