import { DefaultTheme } from 'styled-components';

interface IButtonParam {
  padding: string;
  font: string;
}

export const getButtonTextParamsBySize = (
  size: string,
  { font }: DefaultTheme,
): IButtonParam => {
  switch (size) {
    default:
    case 'md':
      return {
        padding: '11px 16px',
        font: font.body.body,
      };
    case 'sm':
      return {
        padding: '7px 12px',
        font: font.special.regular,
      };
  }
};
