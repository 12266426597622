import { Dispatch, FC } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@components/ui/Icon/Icon';
import { IconName } from '@components/ui/Icon/enums/IconName';
import { Tooltip } from '@components/ui/Tooltip/Tooltip';

interface IRightProps {
  id?: string;
  error?: string | boolean;
  iconRight?: any;
  showPassword: boolean;
  onShowPassword?: Dispatch<boolean>;
}

export const Right: FC<IRightProps> = ({
  id,
  error,
  iconRight,
  showPassword,
  onShowPassword,
}) => {
  const errorTooltipId = id ? `error-${id}` : undefined;

  return (
    <SRight>
      {error && (
        <>
          <SErrorWrapper data-tip={true} data-for={errorTooltipId}>
            <SErrorIcon
              data-tip={true}
              data-for={errorTooltipId}
              name={IconName.ALERT}
              size='sm'
            />
          </SErrorWrapper>
          {typeof error === 'string' && errorTooltipId && (
            <Tooltip id={errorTooltipId}>{error}</Tooltip>
          )}
        </>
      )}
      {iconRight && !onShowPassword && (
        <SRightIcon {...iconRight} error={!!error} />
      )}
      {onShowPassword && (
        <SShowIconWrapper
          onClick={() => onShowPassword && onShowPassword(!showPassword)}
        >
          <SShowPasswordIcon
            name={showPassword ? IconName.OPENED_EYE : IconName.CLOSED_EYE}
            active={showPassword}
          />
        </SShowIconWrapper>
      )}
    </SRight>
  );
};

const SShowIconWrapper = styled.div`
  display: flex;
`;
const SShowPasswordIcon = styled(Icon)<{ active: boolean }>`
  z-index: 10;
  margin-left: 5px;
`;
const SRightIcon = styled(Icon)<{ error: boolean }>`
  color: ${(p) => p.theme.colors.text.GRAY};
  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.base.ERROR};
      margin-left: 5px;
    `}
`;
const SErrorIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.base.ERROR};
  z-index: 10;
`;
const SErrorWrapper = styled.div`
  display: flex;
`;
const SRight = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  background: ${(p) => p.theme.colors.base.LIGHT};
`;
