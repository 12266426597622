import { useEffect } from 'react';

export const useRestoreError = (onRestore: () => void): void => {
  useEffect(() => {
    const handleRestore = (): void => onRestore();

    window.addEventListener('click', handleRestore);

    return () => {
      onRestore();
      window.removeEventListener('click', handleRestore);
    };
  }, [onRestore]);
};
